import { ThemeIcon, createStyles } from "@mantine/core";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

const useStyles = createStyles((_theme, _params) => ({
  controls: {
    cursor: "pointer",
    backgroundColor: _theme.colors.gray[2],
    color: _theme.colors.gray[6],
    "&:hover": {
      opacity: 0.8,
    },
  },

  carrouselButton: {
    border: "none",
    backgroundColor: "transparent",
  },
}));

type CarouselArrowProps = {
  handleClick: React.MouseEventHandler<HTMLDivElement>;
  orientation: "left" | "right";
};

export const CarouselArrow = ({
  handleClick,
  orientation,
}: CarouselArrowProps) => {
  const { classes } = useStyles();

  return (
    <button className={classes.carrouselButton}>
      <ThemeIcon radius="xl" className={classes.controls} onClick={handleClick}>
        {orientation === "right" ? <AiOutlineRight /> : <AiOutlineLeft />}
      </ThemeIcon>
    </button>
  );
};
