import { Tabs, TabsProps } from "@mantine/core";

export const EducacionTabs = (props: TabsProps) => {
  return (
    <Tabs
      unstyled
      styles={(theme) => ({
        tab: {
          ...theme.fn.focusStyles(),
          backgroundColor: "#d4d4d4",
          color: theme.colors.gray[9],
          border: `1px solid ${theme.colors.gray[4]}`,
          padding: `${theme.spacing.xs}px ${theme.spacing.lg}px`,
          cursor: "pointer",
          fontSize: theme.fontSizes.sm,
          display: "flex",
          alignItems: "center",
          margin: "0px 10px",
          borderRadius: theme.radius.xl,
          fontWeight: 600,

          "&:disabled": {
            opacity: 0.5,
            cursor: "not-allowed",
          },

          "&:first-of-type": {
            marginLeft: 0,
          },

          "&:not(:first-of-type)": {
            borderLeft: 0,
          },

          "&[data-active]": {
            backgroundColor: "#8b8989",
            borderColor: "#8b8989",
            color: theme.white,
          },
        },

        tabIcon: {
          marginRight: theme.spacing.xs,
          display: "flex",
          alignItems: "center",
        },

        tabsList: {
          display: "flex",
        },
      })}
      {...props}
    />
  );
};
