import { Card, Flex, Image, createStyles } from "@mantine/core";
import { CustomLink } from "../../CustomLink";
import { useNavigate } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  textContainer: {
    height: "50px",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
  },
  card: {
    cursor: "pointer",
  },
}));

type Props = {
  image: string;
  title: string;
  type: "purchased" | "available" | "inactive";
  id: number;
};

export const CursoCard = ({ image, title, type, id }: Props) => {
  const navigate = useNavigate();
  const { classes } = useStyles();

  const handleCardClick = () => {
    if (type === "purchased") {
      navigate(`/aula-virtual/cursos-activos/${id}`);
    } else if (type === "available") {
      navigate(`/aula-virtual/cursos-disponibles/${id}`);
    } else {
      navigate(`/aula-virtual/cursos-anteriores/${id}`);
    }
  };

  return (
    <Card
      withBorder
      p="sm"
      radius="md"
      onClick={handleCardClick}
      className={classes.card}
    >
      <Card.Section>
        <Image src={image} alt={title} height={175} />
      </Card.Section>
      <Flex mt="sm" justify="center">
        <CustomLink to="" fontSize={16}>
          <div className={classes.textContainer}>{title}</div>
        </CustomLink>
      </Flex>
    </Card>
  );
};
