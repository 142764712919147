import { PrevalenceByRecognition } from "../../types";
import { BarChart } from "../Charts";

type Props = {
  data: { [key: string]: PrevalenceByRecognition };
  title: string;
  total: number;
};

export const BarChartPrevalenciaPerfilLaboral = ({
  data,
  title,
  total,
}: Props) => {
  return (
    <BarChart
      title={title}
      seriesData={[
        [total, ...Object.keys(data).map((key) => data[key].not_recognized)],
      ]}
      categories={["Total", ...Object.keys(data)]}
      max={100}
      width={400}
    />
  );
};
