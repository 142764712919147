import { Button, Center } from "@mantine/core";
import { useAuth } from "../../../context";

type Props = {
  onClick: () => void;
  disabled?: boolean;
};

export const AddRowButton = ({ onClick, disabled }: Props) => {
  const { loading } = useAuth();
  return (
    <Center>
      <Button
        color="green"
        variant="outline"
        onClick={onClick}
        disabled={loading || disabled}
      >
        +
      </Button>
    </Center>
  );
};
