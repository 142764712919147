import { Flex, createStyles, Text } from "@mantine/core";

type Props = {
  description: string;
  percentage: number;
  decimalPlaces?: number;
  color: string;
  direction?: "row" | "column";
};

const useStyles = createStyles((theme) => ({
  card: {
    marginTop: 20,
    padding: "10px 30px",
    maxWidth: 500,
    color: "#fff",
    borderRadius: 10,
  },
}));

export const PorcentajeCardSmall = ({
  description,
  percentage,
  decimalPlaces = 0,
  color,
  direction = "row",
}: Props) => {
  const { classes } = useStyles();

  return (
    <Flex
      direction={{ base: "column", sm: direction }}
      align="center"
      gap="xs"
      className={classes.card}
      style={{ backgroundColor: color }}
    >
      <Text fw={600} fz={44}>
        {(percentage * 100).toFixed(decimalPlaces)}%
      </Text>
      <Text align="center">{description}</Text>
    </Flex>
  );
};
