import { Button } from "@mantine/core";
import { BiSave } from "react-icons/bi";
import { useAuth } from "../../../context";

type GuardarButtonProps = {
  onClick: () => void;
  disabled?: boolean;
};

export const GuardarButton = ({ onClick, disabled }: GuardarButtonProps) => {
  const { loading } = useAuth();
  return (
    <Button
      compact
      size="xs"
      color="green"
      onClick={onClick}
      variant="outline"
      disabled={loading || disabled}
    >
      <BiSave size={20} />
    </Button>
  );
};
