// INICIAL
export const colorAmarillo1 = "#f0b74c";
export const colorAmarillo2 = "#d08d11";
export const colorAmarillo3 = "#845a0b";

export const colorVerdeAgua0 = "#a7d8d6";
export const colorVerdeAgua1 = "#50b2ad";
export const colorVerdeAgua2 = "#32716e";
export const colorVerdeAgua3 = "#193937";

export const colorCeleste1 = "#71baf1";
export const colorCeleste2 = "#1688df";
export const colorCeleste3 = "#1166a7";

export const colorMorado1 = "#8460A2";
export const colorMorado2 = "#5e4474";
export const colorMorado3 = "#3f2d4d";

export const colorGris1 = "#82909b";
export const colorGris2 = "#363e44";
export const colorGris3 = "#121517";

export const colorAzul1 = "#7b8dca";
export const colorAzul2 = "#465eaf";
export const colorAzul3 = "#2f3f75";

export const colorRojoClaro1 = "#e25457";
export const colorRojoClaro2 = "#d12326";
export const colorRojoClaro3 = "#8c181a";

export const colorVerdeOlivo = "#A0B57D";
