import { Stack, Text, Title } from "@mantine/core";

export const ExplicacionPlanDeAccion = () => (
  <Stack spacing="md" my="sm">
    <Title order={5}>¿Qué es el plan de acción?</Title>
    <Text>
      Es un conjunto de quince recomendaciones personalizadas que te brindamos a
      partir de los resultados de tu medición ELSA anual. Estas recomendaciones
      deben implementarse en un periodo aproximado de 12 meses. Es decir, antes
      de tu siguiente medición ELSA anual.
    </Text>

    <Title order={5}>¿Qué tipo de recomendaciones son?</Title>
    <Text>
      En esta sección podrás encontrar una serie de acciones recomendadas para
      reforzar tu trabajo de prevención del acoso sexual laboral. Dichas
      acciones se encuentran categorizadas según los subíndices del Índice ELSA,
      por lo que implementarlas satisfactoriamente te ayudará a obtener mejores
      resultados como organización en tu próxima medición.
    </Text>

    <Title order={5}>
      ¿Cómo implemento este plan de acción en mi organización?
    </Title>
    <Text>
      Las recomendaciones incluidas en tu plan son independientes, es decir,
      puedes implementarlas en el orden de tu preferencia. Te recomendamos
      alinearlas a tu estrategia general de Diversidad en Inclusión (DEI). Cada
      una de las recomendaciones cuenta con todos los recursos necesarios para
      que la implementes
    </Text>

    <Text my="sm">
      Si ya revisaste los resultados de tu medición a detalle, ¡está todo listo
      para generar tu plan de acción!
    </Text>
  </Stack>
);
