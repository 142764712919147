import {
  Flex,
  Group,
  UnstyledButton,
  useMantineTheme,
  Text,
} from "@mantine/core";
import { BsFillBookmarkStarFill } from "react-icons/bs";

export function MiPlanButton({ action }: { action: () => void }) {
  const theme = useMantineTheme();
  return (
    <UnstyledButton
      onClick={action}
      sx={(theme) => ({
        display: "block",
        width: "100%",
        padding: theme.spacing.sm,
        borderRadius: theme.radius.sm,
        color: theme.black,

        "&:hover": {
          backgroundColor: theme.colors.gray[0],
        },
      })}
    >
      <Group position="apart">
        <Flex align="center" gap={8}>
          <BsFillBookmarkStarFill
            size={20}
            color={theme.colors["genderlab-red"][5]}
          />
          <Text size="md" fw={400}>
            Mi plan
          </Text>
        </Flex>
      </Group>
    </UnstyledButton>
  );
}
