import { TextWithInfoTooltip } from "../Miscellaneous";

type Props = {
  title: string;
};

export const TasaDeParticipacionTooltip = ({ title }: Props) => {
  return (
    <TextWithInfoTooltip
      text={title}
      tooltipText="Porcentaje de personas encuestadas en relación al total de personas reportadas en tu encuesta organizacional."
    />
  );
};
