import { Divider, Flex, Text, Title } from "@mantine/core";

type Props = {
  title: string;
  description: string;
};

export const SectionTitle = ({ title, description }: Props) => {
  return (
    <Flex direction="column" gap="xs">
      <Title order={2}>{title}</Title>
      <Text color="dimmed">{description}</Text>
      <Divider />
    </Flex>
  );
};
