import { Flex } from "@mantine/core";
import { CarouselArrow } from "./_arrows";

type Props = {
  children: React.ReactNode;
  handlePrev: React.MouseEventHandler<HTMLDivElement>;
  handleNext: React.MouseEventHandler<HTMLDivElement>;
  showButtons: boolean;
};

export const GenderLabCarouselWrapper = ({
  children,
  handleNext,
  handlePrev,
  showButtons,
}: Props) => {
  return (
    <Flex align="space-evenly" gap="xs">
      {showButtons && (
        <CarouselArrow orientation="left" handleClick={handlePrev} />
      )}
      {children}
      {showButtons && (
        <CarouselArrow orientation="right" handleClick={handleNext} />
      )}
    </Flex>
  );
};
