import { useEffect, useState } from "react";
import {
  CustomLink,
  GenderLabAppShell,
  GenderLabLoader,
} from "../../components";
import { useAuth } from "../../context";
import { Modal, Overlay, Title } from "@mantine/core";
import { contactURL } from "../../utils";

export const RedElsa = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpened, setModalOpened] = useState(true);

  const { user } = useAuth();

  useEffect(() => {
    const iframe = document.getElementById("red-elsa");

    const handleLoad = () => {
      setIsLoading(false);
    };

    iframe?.addEventListener("load", handleLoad);

    return () => {
      iframe?.removeEventListener("load", handleLoad);
    };
  }, []);

  return (
    <GenderLabAppShell fullScreen>
      {(isLoading || !user) && <GenderLabLoader />}
      <div style={{ position: "relative" }}>
        <iframe
          id="red-elsa"
          title="red-elsa"
          src="https://www.notion.elsa.la/Red-ELSA-a91cc2a496024fdf9692f8dce31f1f25?pvs=4"
          style={{
            width: "100%",
            height: "90vh",
            border: "none",
            visibility: isLoading ? "hidden" : "visible",
          }}
        />
        {user && !user.hasELSANetwork && (
          <Overlay color="#f8f9fa" opacity={0.05} blur={4} />
        )}
        {user && !user.hasELSANetwork && (
          <Modal
            title={<Title order={5}>Suscripción vencida</Title>}
            opened={modalOpened}
            onClose={() => setModalOpened(false)}
          >
            Lamentablemente no cuentas con una suscripción ELSA activa. Si
            quieres renovar y acceder a herramientas y material útil y exclusivo
            para la Red ELSA,{" "}
            <CustomLink to={contactURL} target="_blank" fontSize="16px">
              contáctanos
            </CustomLink>
            .
          </Modal>
        )}
      </div>
    </GenderLabAppShell>
  );
};
