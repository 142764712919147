import {
  Flex,
  createStyles,
  Image,
  Title,
  Text,
  Button,
  Badge,
  Divider,
  Stack,
} from "@mantine/core";
import { useState } from "react";
import { PopupModal, useCalendlyEventListener } from "react-calendly";
import { useNavigate } from "react-router-dom";
import { openModal } from "@mantine/modals";
import { FaRegCalendar } from "react-icons/fa";

import "./claseCard.css";
import { useTallyUrl } from "../../../hooks/useTallyUrl";
import { Session } from "../../../types";
import { formatUTCDate } from "../../../utils";
import { useAuth } from "../../../context";
import { tokenService, webinarService } from "../../../api";
import { registerLinkService } from "../../../api/services";

const cardHeight = 325;

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: "#f4f2f2",
    height: cardHeight,
    borderRadius: theme.radius.xl,
  },

  button: {
    color: "white",
    borderRadius: theme.radius.xl,
    transition: "all 0.2s ease-in-out",
  },

  syllabusButton: {
    backgroundColor: "#967cb6",
    "&:hover": {
      backgroundColor: "#a698b8",
    },
  },

  conoceMasButton: {
    backgroundColor: "#c54839",
    "&:hover": {
      backgroundColor: "#c9796f",
    },
  },

  agendarButton: {
    backgroundColor: "#4d9f60",
    "&:hover": {
      backgroundColor: "#72a37e",
    },
  },

  badge: {
    position: "absolute",
    top: "10px",
    left: "10px",
    zIndex: 1,
  },

  calendar: {
    cursor: "pointer",
  },
}));

type Props = {
  title: string;
  year: string;
  description: string;
  img: string;
  syllabus: string;
  active?: boolean;
  aulaVirtual?: boolean;
  type?: "purchased" | "available" | "inactive";
  id?: number;
  sessions?: Session[];
  duration?: string;
  material?: string;
  enrollmentId?: string;
  courseId?: number;
};

export const ClaseCard = ({
  title,
  year,
  description,
  img,
  syllabus,
  aulaVirtual = false,
  type,
  id,
  sessions,
  duration,
  material,
  enrollmentId,
  courseId,
}: Props) => {
  const { classes } = useStyles();
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);
  const [calendlyModalIsOpen, setCalendlyModalIsOpen] = useState(false);
  const [selectedSessionId, setSelectedSessionId] = useState<string>("");
  const [hasScheduledEvent, setHasScheduledEvent] = useState(false);

  const onAgendarClick = (sessionId: string) => {
    setCalendlyModalIsOpen(true);
    setSelectedSessionId(sessionId);
  };

  const tallyUrl = useTallyUrl();

  const navigate = useNavigate();

  const handleCardClick = () => {
    if (aulaVirtual && id) {
      if (type === "purchased") {
        navigate(`/aula-virtual/cursos-activos/${id}`);
      } else if (type === "available") {
        navigate(`/aula-virtual/cursos-disponibles/${id}`);
      } else {
        navigate(`/aula-virtual/cursos-anteriores/${id}`);
      }
    } else {
      if (sessions) {
        openModal({
          title: <Title order={3}>Agendar</Title>,
          size: "lg",
          children: (
            <Flex direction="column" gap="md">
              <Stack spacing={0}>
                <Text>
                  Tienes {sessions.length} sesion(es) disponibles de {title}.
                </Text>
                <Text
                  fs="xs"
                  color="dimmed"
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                  onClick={() =>
                    openModal({
                      title: <Title order={3}>¿Cómo agendar sesiones?</Title>,
                      size: "xl",
                      children: (
                        <div
                          style={{
                            position: "relative",
                            paddingBottom: "49.0625%",
                            height: 0,
                          }}
                        >
                          <iframe
                            title="Loom Video"
                            src="https://www.loom.com/embed/77f5b068c471495b9e3b2a013adff96c?sid=3c2f4ae0-374a-4fff-8e25-36f8523a4a1c"
                            frameBorder="0"
                            allowFullScreen
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                            }}
                          ></iframe>
                        </div>
                      ),
                    })
                  }
                >
                  ¿Cómo agendar sesiones?
                </Text>
              </Stack>
              <Divider />
              {sessions.map((session, idx) => (
                <Flex justify="space-between" align="center" key={idx}>
                  <Text>{`Sesión ${idx + 1}`}</Text>
                  {session.date ? (
                    <Badge size="xs" color="green">
                      agendada
                    </Badge>
                  ) : (
                    <Badge size="xs" color="red">
                      no agendada
                    </Badge>
                  )}
                  <Text size="xs" color="dimmed">
                    {session.date ? formatUTCDate(session.date) : "Por definir"}
                  </Text>
                  <Button
                    size="xs"
                    compact
                    variant="white"
                    color="green"
                    onClick={() =>
                      navigator.clipboard.writeText(session.session_url || "")
                    }
                    disabled={!session.session_url}
                  >
                    copiar enlace
                  </Button>
                  <Button
                    variant="outline"
                    onClick={() => onAgendarClick(session.id)}
                    disabled={session.date !== null}
                    compact
                  >
                    <FaRegCalendar size={14} />
                  </Button>
                </Flex>
              ))}
            </Flex>
          ),
        });
      } else {
        window.open(tallyUrl, "_blank");
      }
    }
  };

  const onUniqueLinkClick = async () => {
    if (courseId) {
      setLoading(true);
      const response = await registerLinkService.getUniqueURLToken(courseId);
      const { token } = response.data;
      window.open(`${window.location.origin}/link-registro/${token}`, "_blank");
      setLoading(false);
    }
  };

  useCalendlyEventListener({
    onEventScheduled: async (e) => {
      if (!setHasScheduledEvent || !selectedSessionId) return;
      const meetingId = e.data.payload.event.uri;
      setHasScheduledEvent(true);
      const response = await webinarService.getCalendlyData(meetingId);

      const location = response.data.resource.location.join_url;
      const startTime = response.data.resource.start_time;

      await webinarService.updateSessionData(selectedSessionId, {
        date: startTime,
        session_url: location,
      });
    },
  });

  return (
    <Flex
      className={classes.card}
      gap="xl"
      justify="space-between"
      pr="xl"
      sx={{ position: "relative" }}
    >
      <Badge
        className={classes.badge}
        color={
          type === "purchased"
            ? "blue"
            : type === "available"
            ? "green"
            : "green"
        }
        size="lg"
      >
        {type === "purchased"
          ? "Activo"
          : type === "available"
          ? "Disponible"
          : "Completado"}
      </Badge>
      <Flex>
        <Image
          src={img}
          alt="aula"
          height={cardHeight}
          width={cardHeight}
          radius="md"
        />
      </Flex>
      <Flex direction="column" justify="center" w={500} gap="xs">
        <Title order={3} className="title">
          {title}
        </Title>
        <Text mt="xl">
          <b>Año de lanzamiento</b>: {year.split("-")[0]}{" "}
          {duration && (
            <>
              | <b>Duración</b>: {parseInt(duration.split(":")[0], 10)}h
            </>
          )}
        </Text>
        <div className="text">{description}</div>
      </Flex>
      <Flex direction="column" justify="center" gap="xl" align="center">
        <Flex>
          <Button
            className={`${classes.button} ${classes.syllabusButton}`}
            component="a"
            target="_blank"
            href={syllabus}
          >
            {aulaVirtual ? "Ver sílabo" : "Ver ficha"}
          </Button>
        </Flex>
        {type !== "inactive" && (
          <>
            <Button
              className={`${classes.button} ${
                aulaVirtual ? classes.conoceMasButton : classes.agendarButton
              }`}
              onClick={handleCardClick}
            >
              {aulaVirtual
                ? type === "purchased"
                  ? "Ir al curso"
                  : "Conoce más"
                : type === "purchased"
                ? "Agendar"
                : "Contáctanos"}
            </Button>
          </>
        )}
        {type === "purchased" && courseId && (
          <>
            <Button
              className={`${classes.button}`}
              onClick={() =>
                window.open(
                  `${window.location.origin}/certificado-aula/${courseId}`,
                  "_blank"
                )
              }
            >
              Ver certificado
            </Button>
            <Button
              className={`${classes.button}`}
              style={{
                backgroundColor: "#4d9f60",
              }}
              onClick={onUniqueLinkClick}
              disabled={loading}
            >
              Link único de registro
            </Button>
          </>
        )}
        {type === "inactive" && material && (
          <Button
            component="a"
            className={`${classes.button} ${
              aulaVirtual ? classes.conoceMasButton : classes.agendarButton
            }`}
            href={material}
            target="_blank"
          >
            Descargar material
          </Button>
        )}
        {type === "inactive" && (
          <Button
            className={`${classes.button} ${
              aulaVirtual ? classes.conoceMasButton : classes.agendarButton
            }`}
            onClick={() =>
              window.open(
                `${window.location.origin}/certificado-webinar/${enrollmentId}`,
                "_blank"
              )
            }
          >
            Ver certificado
          </Button>
        )}
      </Flex>
      {user && (
        <PopupModal
          url="https://calendly.com/saulo-elsa/1hour"
          // url="https://calendly.com/carlos-1sa/30min"
          onModalClose={() => {
            setCalendlyModalIsOpen(false);
            if (hasScheduledEvent) navigate(0);
          }}
          open={calendlyModalIsOpen}
          rootElement={document.getElementById("root") as HTMLElement}
          prefill={{
            firstName: user.contactName,
            lastName: user.contactLastName,
            email: user.contactEmail,
            customAnswers: {
              a1: tokenService.getTokenFromLC("access"),
              a2: selectedSessionId,
            },
          }}
        />
      )}
    </Flex>
  );
};
