import { TCountDistribution } from "../../types";
import { PieChart } from "../Charts";

type Props = {
  distribution: TCountDistribution[];
};

export const PieChartPerfilPersonal = ({ distribution }: Props) => {
  return (
    <PieChart
      labels={[
        "Prefiero no responder.",
        ...distribution
          .filter((dis) => dis.description !== "Prefiero no responder.")
          .map((age_group) => age_group.description),
      ]}
      series={[
        distribution.find((dis) => dis.description === "Prefiero no responder.")
          ?.count || 0,
        ...distribution
          .filter((dis) => dis.description !== "Prefiero no responder.")
          .map((age_group) => age_group.count),
      ]}
      height={325}
    />
  );
};
