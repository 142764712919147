import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../context";
import { Text, Button } from "@mantine/core";
import { closeAllModals, openModal } from "@mantine/modals";

type Props = {
  children: React.ReactNode;
};

export const ProtectedRoute = ({ children }: Props) => {
  const { tryToPersistSession, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!user) tryToPersistSession();
    if (user && !user.completedProfile && location.pathname !== "/mi-cuenta") {
      openModal({
        title: "Atención",
        children: (
          <>
            <Text size="sm">
              Debe completar su cuenta antes de navegar por la aplicación.
            </Text>
            <Button
              fullWidth
              onClick={() => {
                navigate("/mi-cuenta");
                closeAllModals();
              }}
              mt="md"
            >
              Aceptar
            </Button>
          </>
        ),
        onClose() {
          navigate("/mi-cuenta");
        },
      });
    }
  }, [tryToPersistSession, navigate, location.pathname, user]);

  return <>{children}</>;
};
