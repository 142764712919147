import { Flex, Grid, Text } from "@mantine/core";
import { TTolerance, TToleranceCategory } from "../../types";
import {
  ToleranciaHeatmap,
  ResultadosCard,
  SectionTitle,
  TarjetaTolerancia,
  GenderLabLoader,
  SelectCortesData,
  ConceptoHeatmap,
} from "../../components";
import { useEffect, useState } from "react";

type Props = {
  tolerance: TTolerance | null;
};

export const ToleranciaResultados = ({ tolerance }: Props) => {
  const [toleranceHeatmapData, setToleranceHeatmapData] = useState<{
    [key: string]: TToleranceCategory;
  } | null>(null);
  const [conceptHeatmapData, setConceptHeatmapData] = useState<{
    [key: string]: TToleranceCategory;
  } | null>(null);

  useEffect(() => {
    if (tolerance) {
      setToleranceHeatmapData(tolerance.gender);
      setConceptHeatmapData(tolerance.gender);
    }
  }, [tolerance]);

  const toleranciaGrid = () => (
    <Grid gutter="md">
      <Grid.Col span={4}>
        <Text color="dimmed" size="xs">
          T1: Hacer comentarios o bromas de contenido sexual o sexista.
        </Text>
      </Grid.Col>
      <Grid.Col span={4}>
        <Text color="dimmed" size="xs">
          T2: Hacer comentarios o bromas sobre la orientación sexual o identidad
          de género de una persona.
        </Text>
      </Grid.Col>
      <Grid.Col span={4}>
        <Text color="dimmed" size="xs">
          T3: Hacer comentarios sobre la apariencia física o cuerpo de una
          persona que generan incomodidad, incluyendo también gestos, silbidos o
          sonidos.
        </Text>
      </Grid.Col>
      <Grid.Col span={4}>
        <Text color="dimmed" size="xs">
          T4: Invitar reiteradamente a salir a una persona a pesar de no recibir
          respuesta o no recibir respuesta positiva.
        </Text>
      </Grid.Col>
      <Grid.Col span={4}>
        <Text color="dimmed" size="xs">
          T5: Contactar reiteradamente a través de mensajes electrónicos, chats,
          WhatsApp, Facebook, entre otros, con fines no laborales que generan
          incomodidad.
        </Text>
      </Grid.Col>
      <Grid.Col span={4}>
        <Text color="dimmed" size="xs">
          T6: Solicitar o enviar sin consentimiento fotos o videos de material
          sexual explícito o de connotación sexual.
        </Text>
      </Grid.Col>
      <Grid.Col span={4}>
        <Text color="dimmed" size="xs">
          T7: Acercarse demasiado físicamente o tocar (rozar, sobar, masajear,
          etc.) sin necesidad a una persona en alguna parte del cuerpo como
          hombros, espalda baja, rodilla u otras partes.
        </Text>
      </Grid.Col>
      <Grid.Col span={4}>
        <Text color="dimmed" size="xs">
          T8: Tocar o rozar a una persona en zonas privadas del cuerpo como los
          senos, genitales, etc.
        </Text>
      </Grid.Col>
      <Grid.Col span={4}>
        <Text color="dimmed" size="xs">
          T9: Mirar persistentemente con connotación sexual a una persona.
        </Text>
      </Grid.Col>
      <Grid.Col span={4}>
        <Text color="dimmed" size="xs">
          T10: Que un jefe/a se insinúe y/o realice invitaciones a salir a pesar
          que de alguna manera se le mostró incomodidad.
        </Text>
      </Grid.Col>
      <Grid.Col span={4}>
        <Text color="dimmed" size="xs">
          T11: Insinuar o proponer mejoras en la situación laboral de una
          persona a cambio de aceptar invitaciones, acercamientos o encuentros
          sexuales.
        </Text>
      </Grid.Col>
      <Grid.Col span={4}>
        <Text color="dimmed" size="xs">
          T12: Amenazar o insinuar con despedir a una persona, retirarle los
          beneficios laborales o tomar alguna represalia por no aceptar
          invitaciones, acercamientos, o encuentros sexuales.
        </Text>
      </Grid.Col>
    </Grid>
  );

  const onSelectChange = (
    value: string | null,
    setData: React.Dispatch<
      React.SetStateAction<{
        [key: string]: TToleranceCategory;
      } | null>
    >
  ) => {
    if (tolerance) {
      switch (value) {
        case "genero":
          setData(tolerance.gender);
          break;
        case "edad":
          setData(tolerance.age);
          break;
        case "orientacion-sexual":
          setData(tolerance.sexual_orientation);
          break;
        case "grupo-etnico-racial":
          setData(tolerance.ethnicity);
          break;
        case "puesto-de-trabajo":
          setData(tolerance.job);
          break;
        case "area":
          setData(tolerance.area);
          break;
        case "centro-de-trabajo":
          setData(tolerance.workplace);
          break;
        case "turno":
          setData(tolerance.work_shift);
          break;
      }
    }
  };

  const select = (
    setData: React.Dispatch<
      React.SetStateAction<{
        [key: string]: TToleranceCategory;
      } | null>
    >
  ) => (
    <SelectCortesData
      onSelectChange={(value) => onSelectChange(value, setData)}
      genderDisabled={!tolerance?.gender}
      ageDisabled={!tolerance?.age}
      sexualOrientationDisabled={!tolerance?.sexual_orientation}
      ethnicityDisabled={!tolerance?.ethnicity}
      jobDisabled={!tolerance?.job}
      areaDisabled={!tolerance?.area}
      workplaceDisabled={!tolerance?.workplace}
      workShiftDisabled={!tolerance?.work_shift}
    />
  );

  if (!tolerance || !toleranceHeatmapData || !conceptHeatmapData) {
    return <GenderLabLoader />;
  } else {
    return (
      <Flex direction="column" gap="md">
        <SectionTitle
          title="Tolerancia"
          description="Percepciones de las personas encuestadas acerca de situaciones de hostigamiento o acoso sexual laboral."
        />
        <Flex gap="md" direction={{ base: "column", md: "row" }}>
          <ResultadosCard title="Hostigamiento o acoso de género">
            Comportamientos verbales y no verbales que transmiten hostilidad,
            objetivación, exclusión o estatus de inferioridad sobre miembros de
            un género. Es el tipo más común de acoso sexual y el más
            naturalizado por la sociedad.
          </ResultadosCard>
          <ResultadosCard title="Atención sexual no deseada">
            Avances sexuales verbales o físicos no deseados como expresiones de
            interés romántico o sexual que son incómodas u ofensivas para la
            persona que las recibe o conductas relacionadas con la intimidad de
            una persona (independientemente del deseo sexual).
          </ResultadosCard>
          <ResultadosCard title="Coerción sexual">
            Tratamientos laborales favorables o desfavorables que están
            condicionados a la actividad sexual. Es la forma más explícita de
            hostigamiento o acoso sexual laboral.
          </ResultadosCard>
        </Flex>
        <TarjetaTolerancia tolerance={tolerance.overview} />

        <ResultadosCard
          title="Tolerancia frente al hostimagiento o acoso laboral"
          description="Porcentaje que no le ven nada de malo a estas situaciones o que le parecen inapropiadas para el trabajo pero no las identifican como acoso sexual."
          titleChildren={select(setToleranceHeatmapData)}
        >
          <ToleranciaHeatmap
            data={toleranceHeatmapData}
            total={tolerance.situations}
          />
          {toleranciaGrid()}
        </ResultadosCard>

        <ResultadosCard
          title="Concepto de hostigamiento o acoso sexual laboral"
          description="Porcentaje que cree que estas características son necesarias para que haya acoso sexual."
          titleChildren={select(setConceptHeatmapData)}
        >
          <ConceptoHeatmap
            data={conceptHeatmapData}
            total={tolerance.sexual_harassment_knowledge}
          />
        </ResultadosCard>
      </Flex>
    );
  }
};
