import { Flex } from "@mantine/core";
import { ResultadosCard } from "./ResultadosCard";
import { PuntajeBonusPenalidad, Tacometro } from "./Indice";

type Props = {
  title: string;
  description: string | React.ReactNode;
  children: React.ReactNode;
  isTitle?: boolean;
  value: number;
  isBonus?: boolean;
  isPenalty?: boolean;
};

export const SubindiceCard = ({
  title,
  description,
  children,
  isTitle,
  value,
  isBonus,
  isPenalty,
}: Props) => {
  return (
    <ResultadosCard
      title={title}
      description={description}
      titleOrder={isTitle ? 3 : 4}
      titleChildren={
        !isBonus && !isPenalty ? (
          <Tacometro value={value} />
        ) : (
          <Flex>
            <PuntajeBonusPenalidad
              value={value}
              isBonus={isBonus}
              isPenalty={isPenalty}
            />
          </Flex>
        )
      }
    >
      {children}
    </ResultadosCard>
  );
};
