import {
  Text,
  Card,
  Divider,
  Flex,
  Space,
  Title,
  Sx,
  TitleOrder,
} from "@mantine/core";

type ResultadosPageProps = {
  children?: React.ReactNode;
  title?: string;
  alignSelf?: string;
  description?: string | React.ReactNode;
  sx?: Sx | (Sx | undefined)[] | undefined;
  id?: string;
  w?: string | number;
  titleOrder?: TitleOrder;
  titleChildren?: React.ReactNode;
};

export const ResultadosCard = ({
  title,
  children,
  alignSelf,
  description,
  sx,
  id,
  w = "100%",
  titleOrder = 4,
  titleChildren,
}: ResultadosPageProps) => {
  return (
    <Flex
      sx={{ alignSelf: alignSelf || "stretch", width: "100%", ...sx }}
      id={id}
      w={w}
    >
      <Card
        withBorder
        p="lg"
        radius="md"
        style={{
          height: "100%",
          width: "100%",
          overflow: "visible",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          {title && (
            <>
              <Flex w="100%" justify="space-between" align="center">
                <Flex direction="column">
                  <Title order={titleOrder}>{title}</Title>
                  {description && <Text color="dimmed">{description}</Text>}
                </Flex>
                {titleChildren}
              </Flex>
              <Card.Section mt="lg">
                <Divider />
              </Card.Section>
              <Space h="xl" />
            </>
          )}
          <div style={{ flex: "0 1 auto" }}>{children}</div>
        </div>
      </Card>
    </Flex>
  );
};
