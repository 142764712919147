import { useEffect, useState } from "react";
import { useAuth, useMetrics } from "../../context";
import { colorAmarillo1, colorCeleste1 } from "../../utils";
import { BarChart } from "../Charts";

type Props = {
  currentTechHarrassment: number;
  currentDeclaredHarrassment: number;
};

export const PrevalenceMetricsChart = ({
  currentTechHarrassment,
  currentDeclaredHarrassment,
}: Props) => {
  const { user } = useAuth();
  const [seriesData, setSeriesData] = useState<number[][]>([
    [currentTechHarrassment],
  ]);
  const [categories, setCategories] = useState<string[]>(["Tus resultados"]);

  const { regionalBenchmark, countryBenchmark, previousMetrics } = useMetrics();

  useEffect(() => {
    const tmpSeries = [[currentTechHarrassment], [currentDeclaredHarrassment]];
    const tmpCategories = ["Tus resultados"];

    if (user) {
      if (user.hasRegionalBenchmark && regionalBenchmark) {
        tmpSeries[1].push(regionalBenchmark.benchmarks.RB_0039);
        tmpSeries[0].push(regionalBenchmark.benchmarks.RB_0040);
        tmpCategories.push("Benchmark Regional");
      }
      if (user.hasCountryBenchmark && countryBenchmark) {
        tmpSeries[1].push(countryBenchmark.benchmarks.CB_0039);
        tmpSeries[0].push(countryBenchmark.benchmarks.CB_0040);
        tmpCategories.push("Benchmark País");
      }
      if (user.hasEvolutionaryResults && previousMetrics) {
        tmpSeries[1].push(previousMetrics.metrics.PM_0001);
        tmpSeries[0].push(previousMetrics.metrics.PM_0002);
        tmpCategories.push("Medición anterior");
      }
    }

    setSeriesData(tmpSeries);
    setCategories(tmpCategories);
  }, [
    user,
    regionalBenchmark,
    countryBenchmark,
    previousMetrics,
    currentTechHarrassment,
    currentDeclaredHarrassment,
  ]);

  return (
    <BarChart
      seriesData={seriesData}
      categories={categories}
      seriesNames={["Acoso técnico", "Acoso declarado"]}
      height={400}
      width={500}
      colors={[colorAmarillo1, colorCeleste1]}
      max={100}
    />
  );
};
