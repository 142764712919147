import { Text } from "@mantine/core";
import { colorRojoClaro1, colorVerdeAgua1 } from "../../../utils";

type Props = {
  value: number;
  isBonus?: boolean;
  isPenalty?: boolean;
};

export const PuntajeBonusPenalidad = ({ value, isBonus, isPenalty }: Props) => {
  return (
    <div
      style={{
        borderRadius: "50%",
        backgroundColor: isBonus ? colorVerdeAgua1 : colorRojoClaro1,
        height: "60px",
        width: "60px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Text color="white" fw={600} fz="lg">
        {isBonus ? "+" : "-"}
        {value}
      </Text>
    </div>
  );
};
