import { Center, Tabs, Text } from "@mantine/core";
import { CertificadoShell, GenderLabLoader } from "../../components";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { aulaService } from "../../api";
import { TCourseCertificateData } from "../../types";

export const CertificadoAulaPage = () => {
  const { code } = useParams();

  const [loading, setLoading] = useState(true);
  const [studentsPdf, setStudentsPdf] = useState<string | undefined>(undefined);
  const [certificateData, setCertificateData] =
    useState<TCourseCertificateData | null>(null);

  useEffect(() => {
    const getCertificateData = async () => {
      if (code) {
        try {
          const certificateResponse = await aulaService.getCertificateData(
            code
          );
          setCertificateData(certificateResponse.data);
        } catch (error: any) {
          console.error(error);
        }
      }
    };

    const getStudentsPdf = async () => {
      if (code) {
        try {
          const studentsPdfResponse = await aulaService.getAulaStudentsPdf(
            code
          );
          const blob = new Blob([studentsPdfResponse.data], {
            type: "application/pdf",
          });
          setStudentsPdf(URL.createObjectURL(blob));
        } catch (error: any) {
          console.error(error);
        }
      }
    };

    const getData = async () => {
      setLoading(true);
      await getCertificateData();
      await getStudentsPdf();
      setLoading(false);
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <GenderLabLoader />;
  }

  return (
    <Tabs defaultValue="certificado">
      <Tabs.List>
        <Tabs.Tab value="certificado">Certificado</Tabs.Tab>
        <Tabs.Tab value="asistentes">Asistentes</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="certificado">
        {certificateData ? (
          <CertificadoShell
            nombreEmpresa={certificateData.client_trade_name}
            nombreCurso={certificateData.course_title}
          >
            Por la ejecución del entrenamiento{" "}
            <b>{certificateData.course_title}</b> en el Aula Virtual de ELSA
            que, a la fecha, cuenta con{" "}
            <b>{certificateData.completed_students}</b> personas que lo
            culminaron al 100%.
          </CertificadoShell>
        ) : (
          <Text my="md" align="center">
            No se pudo generar el certificado
          </Text>
        )}
      </Tabs.Panel>

      <Tabs.Panel value="asistentes">
        <Center my="md">
          {studentsPdf ? (
            <iframe
              title="Asistentes"
              src={studentsPdf}
              width="100%"
              height="700px"
            />
          ) : (
            <Text my="md">No se pudo generar el archivo de estudiantes</Text>
          )}
        </Center>
      </Tabs.Panel>
    </Tabs>
  );
};
