import { useEffect, useState } from "react";
import { clientService } from "../api";
import { Profile } from "../types";

export const useTallyUrl = () => {
  const [profileData, setProfileData] = useState<Profile>(null!);
  useEffect(() => {
    const getUserProfileData = async () => {
      const response = await clientService.profile.get();
      setProfileData(response.data);
    };

    getUserProfileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!profileData) return "";
  else
    return `https://tally.so/r/3Nlopl?email=${
      profileData.profile.contact_email
    }&first_name=${profileData.profile.contact_name}&last_name=${
      profileData.profile.contact_lastname
    }&business_name=${profileData.profile.business_name}&phone=${
      profileData.countries.filter(
        (country) => country.id === profileData.profile.contact_phone_country
      )[0].phone_prefix
    }${profileData.profile.contact_phone}
              &country=${
                profileData.countries.filter(
                  (country) => country.id === profileData.profile.country
                )[0].name
              }`;
};
