import { Flex, Text } from "@mantine/core";
import { ResultadosCard } from "./ResultadosCard";
import { TextWithInfoTooltip } from "../Miscellaneous";
import { PorcentajeCardSmall } from "./PorcentajeCardSmall";
import { colorAmarillo1, colorCeleste1 } from "../../utils";

type Props = {
  technicalHarassmentPrevalence: number;
  declaredHarassmentPrevalence: number;
};

export const AcosoTecnicoYDeclaradoPrevalencia = ({
  technicalHarassmentPrevalence,
  declaredHarassmentPrevalence,
}: Props) => {
  return (
    <ResultadosCard title="Prevalencia del hostigamiento o acoso sexual laboral">
      <Flex
        direction={{ base: "column", sm: "row" }}
        justify="space-evenly"
        gap="xs"
      >
        <Flex direction={{ base: "column" }} align="center">
          <TextWithInfoTooltip
            text="Hostigamiento o acoso sexual laboral técnico"
            tooltipText={
              <>
                <Text size="sm" color="dimmed">
                  Responde a la pregunta: ¿En los últimos 12 meses has sido
                  objeto de alguna de estas situaciones?
                </Text>
                <Text size="sm">
                  Son aquellas manifestaciones de acoso u hostigamiento sexual
                  laboral , que, independientemente de la denominación que le
                  otorguen las pesonas, calzan dentro de las manifestaciones
                  típicas del acoso u hostigamiento sexual laboral. Esto quiere
                  decir que esta cifra incluye tanto a aquellas personas que
                  fueron capaces de reconocer y denominar a sus experiencias
                  como “acoso u hostigamiento sexual laboral” como a aquellas
                  que sin categorizarlas como tal , experimentaron algunas de
                  estas manifestaciones . Esta cifra refleja de mejor manera lo
                  que realmente ocurre en las organizaciones.
                </Text>
              </>
            }
          />

          <PorcentajeCardSmall
            description="Pasó por alguna manifestación típica de hostigamiento o acoso
                  sexual laboral."
            percentage={technicalHarassmentPrevalence}
            decimalPlaces={1}
            color={colorAmarillo1}
          />
        </Flex>
        <Flex direction={{ base: "column" }} align="center">
          <TextWithInfoTooltip
            text="Hostigamiento o acoso sexual laboral declarado"
            tooltipText={
              <>
                <Text size="sm" color="dimmed">
                  Responde a la pregunta: ¿En los últimos 12 meses has sido
                  objeto de una situación de hostigamento o acoso sexual
                  laboral?
                </Text>
                <Text size="sm">
                  Son aquellas manifestaciones del acoso u hostigamiento sexual
                  laboral que son identificadas por las pesonas que las viven y
                  que tienen la capacidad de reconocerlas y denominar a esas
                  experiencias como “acoso u hostigamiento sexual laboral”. Esto
                  quiere decirque esta cifra incluye únicamente a aquellas
                  personas que categorizan su experiencia como tal, por lo que
                  ,debido al desconocimiento o falta de información, tiende a
                  reflejar una cifra bastante menor a lo que realmente ocurre en
                  las organizaciones.
                </Text>
              </>
            }
          />
          <PorcentajeCardSmall
            description="Denominó a esa experiencia hostigamiento o acoso sexual laboral."
            percentage={declaredHarassmentPrevalence}
            decimalPlaces={1}
            color={colorCeleste1}
          />
        </Flex>
      </Flex>
    </ResultadosCard>
  );
};
