import { Card, Title, Text } from "@mantine/core";

type TitleCardProps = {
  title: string;
  description?: string;
};

export const TitleCard = ({ title, description }: TitleCardProps) => {
  return (
    <Card
      // shadow="sm"
      withBorder
      p="lg"
      radius="md"
      style={{
        height: "100%",
        width: "100%",
        overflow: "visible",
      }}
    >
      <Title order={3}>{title}</Title>
      {description && <Text color="dimmed">{description}</Text>}
    </Card>
  );
};
