import { Flex, Text, createStyles, Container } from "@mantine/core";

type BarrerasGeneralesCardProps = {
  colors: [string, string, string];
  title: string;
  percentage: number;
  subtitles: string[];
  percentages: number[];
};

interface ColorsProps {
  colors: [string, string, string];
}

const useStyles = createStyles((theme, { colors }: ColorsProps) => ({
  parentContainer: {
    display: "flex",
    width: 550,
    maxWidth: 550,
    "@media screen and (max-width: 1440px)": {
      width: "100%",
    },
    flexDirection: "column",
  },
  leftContainer: {
    display: "flex",
    color: colors[2],
    backgroundColor: colors[0],
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: "15px 10px",
  },
  rightContainer: {
    display: "flex",
    backgroundColor: colors[1],
    minWidth: 175,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "15px 10px",
  },
  subtitleContainer: {
    borderTop: "1px solid #dadada",
  },
  barreraEspecificaContainer: {
    backgroundColor: "#fff",
    color: "#000",
    border: "1px solid #dadada",
    borderTop: "none",
  },
}));

export const BarrerasGeneralesCard = ({
  colors,
  title,
  percentage,
  subtitles,
  percentages,
}: BarrerasGeneralesCardProps) => {
  const { classes, cx } = useStyles({ colors });

  return (
    <div className={classes.parentContainer}>
      <Flex>
        <Container
          className={classes.leftContainer}
          sx={{ borderTopLeftRadius: 10 }}
        >
          <Text fw={600} align="center">
            {title.toUpperCase()}
          </Text>
        </Container>
        <Container
          className={classes.rightContainer}
          sx={{ borderTopRightRadius: 10 }}
        >
          <Text fw={600} fz={28} sx={{ color: "white" }}>
            {Math.round(percentage * 100)}%
          </Text>
        </Container>
      </Flex>
      {subtitles.map((subtitle, index) => (
        <Flex className={classes.subtitleContainer} key={index}>
          <Container
            className={cx(
              classes.leftContainer,
              classes.barreraEspecificaContainer
            )}
            sx={{
              borderBottomLeftRadius: index === subtitles.length - 1 ? 10 : 0,
            }}
          >
            <Text align="center" fz={14}>
              {subtitle}
            </Text>
          </Container>
          <Container
            className={cx(
              classes.rightContainer,
              classes.barreraEspecificaContainer
            )}
            sx={{
              borderBottomRightRadius: index === subtitles.length - 1 ? 10 : 0,
            }}
          >
            <Text fw={600} fz={20}>
              {Math.round(percentages[index] * 100)}%
            </Text>
          </Container>
        </Flex>
      ))}
    </div>
  );
};
