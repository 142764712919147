import {
  Button,
  Card,
  Center,
  Container,
  Flex,
  Grid,
  LoadingOverlay,
  PasswordInput,
  Stack,
  useMantineTheme,
} from "@mantine/core";

import { ReactComponent as LogoElsa } from "../../static/svg/logo-elsa-bold.svg";
import { GenderLabFooter } from "../../components";
import { useForm } from "@mantine/form";
import { useState } from "react";
import { clientService, passwordService, tokenService } from "../../api";
import { useNavigate, useParams } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import { TUserInfo } from "../../types";
import { User } from "../../classes";
import { useAuth } from "../../context";

export const ReestablecerContrasenaPage = () => {
  const theme = useMantineTheme();
  const { uidb64, token } = useParams();
  const navigate = useNavigate();

  const { setUser } = useAuth();
  const [loading, setLoading] = useState(false);

  const form = useForm({
    initialValues: {
      password1: "",
      password2: "",
    },

    validate: {
      password1: (value) => (value ? null : "Ingrese su contraseña"),
      password2: (value) => {
        if (!value) {
          return "Repita su contraseña";
        } else if (value !== form.values.password1) {
          return "Las contraseñas no coinciden";
        } else {
          return null;
        }
      },
    },
  });

  const resetPassword = async () => {
    try {
      if (form.isValid() && uidb64 && token) {
        setLoading(true);
        const response = await passwordService.resetPassword(
          form.values.password1,
          uidb64,
          token
        );
        tokenService.setTokensToLC(response.data);

        const responseClientData = await clientService.info.get();
        const newUser = new User(responseClientData.data as TUserInfo);
        setUser(newUser);
        navigate("/inicio");
      }
    } catch (error) {
      showNotification({
        title: "Error",
        message: "Ocurrió un error al reestablecer la contraseña",
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container fluid px={0}>
      <Container
        fluid
        sx={(theme) => ({
          backgroundColor:
            theme.colorScheme === "light"
              ? theme.colors["genderlab-red"][5]
              : theme.colors.gray[3],
          paddingTop: "150px",
          [theme.fn.smallerThan("sm")]: {
            paddingTop: "50px",
          },
        })}
        my={0}
      >
        <Grid py="xl" align="center">
          <Grid.Col span={12} sm={6}>
            <Container size={600}>
              <Flex justify="flex-start">
                <LogoElsa
                  fill={
                    theme.colorScheme === "dark"
                      ? theme.colors["genderlab-red"][4]
                      : "white"
                  }
                />
              </Flex>
            </Container>
          </Grid.Col>
          <Grid.Col span={12} sm={6}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                form.validate();
                resetPassword();
              }}
            >
              <Container size={350}>
                <Card
                  shadow="sm"
                  p="lg"
                  radius="md"
                  withBorder
                  style={{ position: "relative" }}
                >
                  <LoadingOverlay visible={loading} overlayBlur={2} />
                  <Stack spacing="xs">
                    <PasswordInput
                      label="Contraseña"
                      placeholder="Ingrese su contraseña"
                      {...form.getInputProps("password1")}
                    />
                    <PasswordInput
                      label="Confirmar contraseña"
                      placeholder="Repita su contraseña"
                      {...form.getInputProps("password2")}
                    />
                  </Stack>
                  <Center>
                    <Button
                      variant="outline"
                      mt="lg"
                      type="submit"
                      disabled={loading}
                    >
                      Reestablecer
                    </Button>
                  </Center>
                </Card>
              </Container>
            </form>
          </Grid.Col>
        </Grid>
      </Container>
      <GenderLabFooter />
    </Container>
  );
};
