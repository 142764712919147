import React, { CSSProperties, useState } from "react";
import {
  AppShell,
  Navbar,
  Header,
  MediaQuery,
  Burger,
  useMantineTheme,
  Image,
  Divider,
  Space,
  ScrollArea,
  Flex,
  Modal,
  Grid,
  Title,
  UnstyledButton,
  createStyles,
  ThemeIcon,
  List,
  Alert,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";
import { IconAlertCircle, IconCircleCheck } from "@tabler/icons";
import { AiFillLock } from "react-icons/ai";

import { User } from "./_user";
import { MiPlanButton } from "./_miPlanButton";
import { MainLinks } from "./_mainLinks";
import { useAuth } from "../../context";
import { CustomLink } from "../CustomLink";
import { contactURL } from "../../utils";

interface MyComponentStyles extends CSSProperties {
  fullScreen: boolean;
}

const useStyles = createStyles((theme, { fullScreen }: MyComponentStyles) => ({
  root: {
    // padding: fullScreen ? "0px 20px" : "40px 100px",
    padding: "0px 20px",
    maxWidth: fullScreen ? "2500px" : "1600px",
    margin: "0 auto",

    "@media (max-width: 1024px)": {
      padding: "10px 10px",
    },
  },
}));

type Props = {
  children: React.ReactNode;
  fullScreen?: boolean;
};

export const GenderLabAppShell: React.FC<Props> = ({
  children,
  fullScreen = true,
}) => {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const [openedModal, setOpenedModal] = useState(false);

  const { user } = useAuth();

  const { classes } = useStyles({ fullScreen });

  const navigate = useNavigate();

  const hideHeader = useMediaQuery("(min-width: 992px)", true);

  const checkedIcon = () => (
    <ThemeIcon color="teal" size={24} radius="xl">
      <IconCircleCheck size={16} />
    </ThemeIcon>
  );

  const lockedIcon = () => (
    <ThemeIcon color="red" size={24} radius="xl">
      <AiFillLock size={16} />
    </ThemeIcon>
  );

  const getIcon = (hasAccess: boolean) => {
    if (hasAccess) {
      return checkedIcon();
    } else {
      return lockedIcon();
    }
  };

  return (
    <AppShell
      padding="md"
      navbarOffsetBreakpoint="md"
      navbar={
        <Navbar
          p="md"
          hiddenBreakpoint="md"
          hidden={!opened}
          width={{ sm: "100%", md: 300 }}
        >
          <Flex
            align="center"
            gap="0"
            justify="center"
            mt="sm"
            mb="md"
            hidden={opened}
          >
            <UnstyledButton
              component="a"
              href="#"
              onClick={() => navigate("/inicio")}
            >
              <Image
                src="/img/logo elsa naranja bold.png"
                width={200}
                fit="contain"
                alt="Elsa Logo"
              />
            </UnstyledButton>
          </Flex>
          <Space h="md" />
          <Navbar.Section grow component={ScrollArea} mx="-xs" px="xs">
            <MainLinks />
            <Divider orientation="horizontal" my="sm" />
            <MiPlanButton action={() => setOpenedModal(true)} />
          </Navbar.Section>
          <Navbar.Section hidden={opened}>
            <Divider orientation="horizontal" />
            <Flex justify="center" mt="md">
              <User />
            </Flex>
          </Navbar.Section>
        </Navbar>
      }
      header={
        <>
          {hideHeader ? (
            <></>
          ) : (
            <Header height={{ base: 90 }} p="md">
              <Grid justify="space-between" align="center">
                <Grid.Col span="content">
                  <Flex align="center" gap="0" justify="flex-start">
                    <MediaQuery largerThan="md" styles={{ display: "none" }}>
                      <Burger
                        opened={opened}
                        onClick={() => setOpened((o) => !o)}
                        size="sm"
                        color={theme.colors.gray[6]}
                        mr="sm"
                      />
                    </MediaQuery>
                  </Flex>
                </Grid.Col>
                <Grid.Col span="content">
                  <Flex justify="center">
                    <UnstyledButton
                      component="a"
                      href="#"
                      onClick={() => navigate("/inicio")}
                    >
                      <Image
                        src="/img/elsa-light.png"
                        width={130}
                        style={{ marginLeft: "20px" }}
                      />
                    </UnstyledButton>
                  </Flex>
                </Grid.Col>
                <Grid.Col span="content">
                  <Flex justify="flex-end">
                    <User />
                  </Flex>
                </Grid.Col>
              </Grid>
            </Header>
          )}
        </>
      }
      styles={(theme) => ({
        main: {
          backgroundColor: theme.colors.gray[0],
        },
      })}
    >
      {user && (
        <Modal
          opened={openedModal}
          onClose={() => setOpenedModal(false)}
          title={<Title order={3}>Mi Plan</Title>}
          size="md"
          closeOnEscape={false}
          overlayOpacity={0.8}
        >
          <List spacing="md" size="md" center>
            <List.Item icon={getIcon(user.hasMeasurementProcess)}>
              Medición
            </List.Item>
            <List.Item icon={getIcon(user.hasRegionalBenchmark)}>
              Benchmark Regional
            </List.Item>
            <List.Item icon={getIcon(user.hasCountryBenchmark)}>
              Benchmark País
            </List.Item>
            <List.Item icon={getIcon(user.hasSectorBenchmark)}>
              Benchmark Sector
            </List.Item>
            <List.Item icon={getIcon(user.hasIndex)}>Índice ELSA</List.Item>
            <List.Item icon={getIcon(user.hasActionPlan)}>
              Plan de Acción
            </List.Item>
            <List.Item icon={getIcon(user.hasEvolutionaryResults)}>
              Dashboard de resultados evolutivos
            </List.Item>
            <List.Item icon={getIcon(user.hasELSANetwork)}>Red ELSA</List.Item>
            <List.Item icon={getIcon(user.hasAULA)}>Aula Virtual</List.Item>
          </List>
        </Modal>
      )}
      <div className={classes.root}>
        {user && user.hasExpiredMeasurementProcess && (
          <Alert
            icon={<IconAlertCircle size={16} />}
            title={<Title order={3}>¡Suscripción vencida!</Title>}
            color="red"
          >
            Para renovar,{" "}
            <CustomLink to={contactURL} target="_blank">
              contáctanos
            </CustomLink>
            .
          </Alert>
        )}
        {children}
      </div>
    </AppShell>
  );
};
