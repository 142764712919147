import { Loader } from "@mantine/core";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { TMedicionActualSetup } from "../../../types";

type Props = {
  loading: boolean;
  medicionActual: TMedicionActualSetup;
  section:
    | "completed_policy_section"
    | "completed_channels_section"
    | "completed_training_section"
    | "completed_good_practices_section";
};

export const OrganizationalSurverStatusIcon = ({
  loading,
  medicionActual,
  section,
}: Props) => {
  return loading || !medicionActual ? (
    <Loader size={12} />
  ) : medicionActual.organizational_survey[section] ? (
    <AiFillCheckCircle size={20} color="green" />
  ) : (
    <AiFillCloseCircle size={20} color="red" />
  );
};
