import {
  Container,
  PasswordInput,
  TextInput,
  Button,
  Center,
  Grid,
  Card,
  MantineTheme,
  useMantineTheme,
  Flex,
  LoadingOverlay,
  Stack,
  Text,
  Modal,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { useState } from "react";

import { GenderLabFooter } from "../../components";
import { ReactComponent as LogoElsa } from "../../static/svg/logo-elsa-bold.svg";
import { useAuth } from "../../context";
import { linkColor } from "../../utils";
import { passwordService } from "../../api";

export const LoginPage = () => {
  const [opened, setOpened] = useState(false);
  const [sending, setSending] = useState(false);

  const theme: MantineTheme = useMantineTheme();
  const { login, loading } = useAuth();

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
      forgotPassword: "",
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Ingrese su email"),
      password: (value) => (value ? null : "Ingrese su contraseña"),
      forgotPassword: (value) =>
        /^\S+@\S+$/.test(value) ? null : "Ingrese su email",
    },
  });

  const onSubmit = async () => {
    try {
      const email = form.validateField("email");
      const password = form.validateField("password");
      if (!email.hasError && !password.hasError)
        await login(form.values.email, form.values.password);
    } catch (error) {
      console.error(error);
    }
  };

  const forgotPassword = async () => {
    try {
      const email = form.validateField("forgotPassword");
      if (!email.hasError) {
        setSending(true);
        await passwordService.forgotPassword(form.values.forgotPassword);
        showNotification({
          title: "Email enviado",
          message: "Se ha enviado un email para reestablecer su contraseña.",
          color: "blue",
        });
        setOpened(false);
        form.reset();
      }
    } catch (error: any) {
      const errorMessage: string = error.response.data.error;
      showNotification({
        title: "Error",
        message: errorMessage,
        color: "red",
      });
      setOpened(false);
    } finally {
      setSending(false);
    }
  };

  return (
    <Container fluid px={0}>
      <Container
        fluid
        sx={(theme) => ({
          backgroundColor:
            theme.colorScheme === "light"
              ? theme.colors["genderlab-red"][5]
              : theme.colors.gray[3],
          paddingTop: "150px",
          [theme.fn.smallerThan("sm")]: {
            paddingTop: "50px",
          },
        })}
        my={0}
      >
        <Grid py="xl" align="center">
          <Grid.Col span={12} sm={6}>
            <Container size={600}>
              <Flex justify="flex-start">
                <LogoElsa
                  fill={
                    theme.colorScheme === "dark"
                      ? theme.colors["genderlab-red"][4]
                      : "white"
                  }
                />
              </Flex>
            </Container>
          </Grid.Col>
          <Grid.Col span={12} sm={6}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <Container size={350}>
                <Card
                  shadow="sm"
                  p="lg"
                  radius="md"
                  withBorder
                  style={{ position: "relative" }}
                >
                  <LoadingOverlay visible={loading} overlayBlur={2} />
                  <Stack spacing="xs">
                    <TextInput
                      type="email"
                      label="Email"
                      placeholder="Ingrese su email"
                      {...form.getInputProps("email")}
                    />
                    <PasswordInput
                      label="Contraseña"
                      placeholder="Ingrese su contraseña"
                      {...form.getInputProps("password")}
                    />
                  </Stack>
                  <Center>
                    <Stack spacing="xs">
                      <Button variant="outline" mt="lg" type="submit">
                        Ingresar
                      </Button>
                      <Text
                        sx={{
                          color: linkColor,
                          cursor: "pointer",
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                        size="xs"
                        onClick={() => {
                          setOpened(true);
                          form.setFieldValue(
                            "forgotPassword",
                            form.values.email
                          );
                        }}
                      >
                        ¿Olvidaste tu contraseña?
                      </Text>
                    </Stack>
                  </Center>
                </Card>
              </Container>
            </form>
          </Grid.Col>
        </Grid>
      </Container>
      <GenderLabFooter />

      <Modal
        opened={opened}
        title="Reestablecer contraseña"
        onClose={() => setOpened(false)}
        size="xs"
        withCloseButton={false}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            forgotPassword();
          }}
        >
          <Stack spacing="xs">
            <TextInput
              type="email"
              label="Email"
              placeholder="Ingrese su email"
              {...form.getInputProps("forgotPassword")}
            />
            <Button fullWidth type="submit" disabled={sending}>
              Enviar mail
            </Button>
          </Stack>
        </form>
      </Modal>
    </Container>
  );
};
