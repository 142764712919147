import { Flex, Grid, Text } from "@mantine/core";
import {
  Heatmap,
  ResultadosCard,
  SectionTitle,
  SelectCortesData,
  GenderLabLoader,
} from "../../components";
import { ConOptions, TOrganizationalEnvironment } from "../../types";
import { parsePercentage } from "../../utils";
import { useMetrics } from "../../context";
import { useEffect, useState } from "react";

type Props = {
  organizationalEnvironment: TOrganizationalEnvironment | null;
};

export const ClimaOrganizacionalResultados = ({
  organizationalEnvironment,
}: Props) => {
  const { regionalBenchmark, countryBenchmark, previousMetrics } = useMetrics();
  const [heatmapDataI, setHeatmapDataI] = useState<Array<{
    name: string;
    data: number[];
  }> | null>(null);
  const [heatmapDataA, setHeatmapDataA] = useState<Array<{
    name: string;
    data: number[];
  }> | null>(null);
  const [heatmapGeneralDataA, setHeatmapGeneralDataA] = useState<{
    name: string;
    data: number[];
  } | null>(null);
  const [heatmapGeneralDataI, setHeatmapGeneralDataI] = useState<{
    name: string;
    data: number[];
  } | null>(null);

  const conOptionsI: Array<keyof ConOptions> = [
    "con_001",
    "con_002",
    "con_003",
    "con_005",
  ];

  const conOptionsA: Array<keyof ConOptions> = ["con_006", "con_004"];

  const heatmapCategoriesI = ["I1", "I2", "I3", "I4"];
  const heatmapCategoriesA = ["A1", "A2"];

  const parseDataHeatmapData = (
    conOptions: Array<keyof ConOptions>,
    data: { [key: string]: ConOptions }
  ) =>
    Object.keys(data).map((key) => ({
      name: key,
      data: conOptions.map((option) => parsePercentage(data[key][option])),
    }));

  useEffect(() => {
    if (organizationalEnvironment) {
      setHeatmapDataI(
        parseDataHeatmapData(conOptionsI, organizationalEnvironment.gender)
      );
      setHeatmapDataA(
        parseDataHeatmapData(conOptionsA, organizationalEnvironment.gender)
      );
      setHeatmapGeneralDataA({
        name: "Total",
        data: conOptionsA.map((option) =>
          parsePercentage(organizationalEnvironment.general[option])
        ),
      });
      setHeatmapGeneralDataI({
        name: "Total",
        data: conOptionsI.map((option) =>
          parsePercentage(organizationalEnvironment.general[option])
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationalEnvironment]);

  if (
    !organizationalEnvironment ||
    !heatmapGeneralDataA ||
    !heatmapGeneralDataI ||
    !heatmapDataA ||
    !heatmapDataI
  ) {
    return <GenderLabLoader />;
  } else {
    const regionalBenchmarkDataI = {
      name: "Benchmark Regional",
      data: [
        parsePercentage(regionalBenchmark?.benchmarks.RB_0029 || 0),
        parsePercentage(regionalBenchmark?.benchmarks.RB_0030 || 0),
        parsePercentage(regionalBenchmark?.benchmarks.RB_0031 || 0),
        parsePercentage(regionalBenchmark?.benchmarks.RB_0032 || 0),
      ],
    };

    const regionalBenchmarkDataA = {
      name: "Benchmark Regional",
      data: [
        parsePercentage(regionalBenchmark?.benchmarks.RB_0033 || 0),
        parsePercentage(regionalBenchmark?.benchmarks.RB_0034 || 0),
      ],
    };

    const countryBenchmarkDataI = {
      name: "Benchmark País",
      data: [
        parsePercentage(countryBenchmark?.benchmarks.CB_0029 || 0),
        parsePercentage(countryBenchmark?.benchmarks.CB_0030 || 0),
        parsePercentage(countryBenchmark?.benchmarks.CB_0031 || 0),
        parsePercentage(countryBenchmark?.benchmarks.CB_0032 || 0),
      ],
    };

    const countryBenchmarkDataA = {
      name: "Benchmark País",
      data: [
        parsePercentage(countryBenchmark?.benchmarks.CB_0033 || 0),
        parsePercentage(countryBenchmark?.benchmarks.CB_0034 || 0),
      ],
    };

    const previousDataI = {
      name: "Medición anterior",
      data: [
        parsePercentage(previousMetrics?.metrics.CM_0001 || 0),
        parsePercentage(previousMetrics?.metrics.CM_0002 || 0),
        parsePercentage(previousMetrics?.metrics.CM_0003 || 0),
        parsePercentage(previousMetrics?.metrics.CM_0004 || 0),
      ],
    };

    const previousDataA = {
      name: "Medición anterior",
      data: [
        parsePercentage(previousMetrics?.metrics.CM_0005 || 0),
        parsePercentage(previousMetrics?.metrics.CM_0006 || 0),
      ],
    };

    const benchmarkAndPrevDataI = [
      ...(regionalBenchmark ? [regionalBenchmarkDataI] : []),
      ...(countryBenchmark ? [countryBenchmarkDataI] : []),
      ...(previousMetrics ? [previousDataI] : []),
    ];

    const benchmarkAndPrevDataA = [
      ...(regionalBenchmark ? [regionalBenchmarkDataA] : []),
      ...(countryBenchmark ? [countryBenchmarkDataA] : []),
      ...(previousMetrics ? [previousDataA] : []),
    ];

    const onSelectChange = (
      value: string | null,
      conOptions: (keyof ConOptions)[],
      setHeatmapData: (
        value: React.SetStateAction<
          | {
              name: string;
              data: number[];
            }[]
          | null
        >
      ) => void
    ) => {
      switch (value) {
        case "genero":
          setHeatmapData(
            parseDataHeatmapData(conOptions, organizationalEnvironment.gender)
          );
          break;
        case "edad":
          setHeatmapData(
            parseDataHeatmapData(conOptions, organizationalEnvironment.age)
          );
          break;
        case "orientacion-sexual":
          setHeatmapData(
            parseDataHeatmapData(
              conOptions,
              organizationalEnvironment.sexual_orientation
            )
          );
          break;
        case "grupo-etnico-racial":
          setHeatmapData(
            parseDataHeatmapData(
              conOptions,
              organizationalEnvironment.ethnicity
            )
          );
          break;
        case "puesto-de-trabajo":
          setHeatmapData(
            parseDataHeatmapData(conOptions, organizationalEnvironment.job)
          );
          break;
        case "area":
          setHeatmapData(
            parseDataHeatmapData(conOptions, organizationalEnvironment.area)
          );
          break;
        case "centro-de-trabajo":
          setHeatmapData(
            parseDataHeatmapData(
              conOptions,
              organizationalEnvironment.workplace
            )
          );
          break;
        case "turno":
          setHeatmapData(
            parseDataHeatmapData(
              conOptions,
              organizationalEnvironment.work_shift
            )
          );
          break;
      }
    };

    const select = (
      conOptions: (keyof ConOptions)[],
      setHeatmapData: (
        value: React.SetStateAction<
          | {
              name: string;
              data: number[];
            }[]
          | null
        >
      ) => void
    ) => (
      <SelectCortesData
        onSelectChange={(value) =>
          onSelectChange(value, conOptions, setHeatmapData)
        }
        genderDisabled={!organizationalEnvironment?.gender}
        jobDisabled={!organizationalEnvironment?.job}
        areaDisabled={!organizationalEnvironment?.area}
        workplaceDisabled={!organizationalEnvironment?.workplace}
        ageDisabled={!organizationalEnvironment?.age}
        sexualOrientationDisabled={
          !organizationalEnvironment?.sexual_orientation
        }
        ethnicityDisabled={!organizationalEnvironment?.ethnicity}
        workShiftDisabled={!organizationalEnvironment?.work_shift}
      />
    );

    return (
      <Flex direction="column" gap="md">
        <SectionTitle
          title="Clima organizacional"
          description="Percepciones de las personas encuestadas sobre el manejo de casos de hostigamiento o acoso sexual laboral en tu empresa."
        />
        <ResultadosCard
          title="Incertidumbres sobre el manejo de casos"
          description="Porcentaje que está totalmente de acuerdo o de acuerdo con las siguientes afirmaciones."
          titleChildren={select(conOptionsI, setHeatmapDataI)}
        >
          <Heatmap
            categories={heatmapCategoriesI}
            data={[
              ...benchmarkAndPrevDataI,
              heatmapGeneralDataI,
              ...heatmapDataI,
            ]}
          />

          <Grid gutter="md">
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                I1: Si denuncio un caso de hostigamiento o acoso sexual laboral,
                me podría generar consecuencias en mi trabajo.
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                I2: Un caso de hostigamiento o acoso sexual en el que se acusa a
                una persona que no pertenece a la plana gerencial no sería
                investigado adecuadamente en mi trabajo.{" "}
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                I3: Un caso de hostigamiento o acoso sexual en el que se acusa a
                una persona que pertenece a la plana gerencial no sería
                investigado adecuadamente en mi trabajo.
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Text color="dimmed" size="xs">
                I4: Las personas que hostigan o acosan a otras/os normalmente se
                salen con la suya (por ejemplo, no son sancionadas).
              </Text>
            </Grid.Col>
          </Grid>
        </ResultadosCard>

        <ResultadosCard
          title="Percepción positiva sobre el manejo de casos"
          description="Porcentaje que está totalmente de acuerdo o de acuerdo con las siguientes afirmaciones."
          titleChildren={select(conOptionsA, setHeatmapDataA)}
        >
          <Heatmap
            categories={heatmapCategoriesA}
            data={[
              ...benchmarkAndPrevDataA,
              heatmapGeneralDataA,
              ...heatmapDataA,
            ]}
          />

          <Grid gutter="md">
            <Grid.Col span={6}>
              <Text color="dimmed" size="xs">
                A1: Se están tomando acciones efectivas contra el hostigamiento
                o acoso sexual en mi trabajo.
              </Text>
            </Grid.Col>
            <Grid.Col span={6}>
              <Text color="dimmed" size="xs">
                A2: El hostigamiento o acoso sexual no es tolerado en mi
                ambiente laboral.
              </Text>
            </Grid.Col>
          </Grid>
        </ResultadosCard>
      </Flex>
    );
  }
};
