import { Text } from "@mantine/core";

type Props = {
  children: string;
};

export const TableTitleText = ({ children }: Props) => {
  return (
    <Text weight={600} size="sm" color="#495057">
      {children}
    </Text>
  );
};
