import { Flex, Paper, Text, Title } from "@mantine/core";
import { Triggers } from "../../types";
import { groupedBarChartColors } from "../../utils";
import { BarChart } from "../Charts";
import { BenchmarkVerticalChart } from "./BenchmarkVerticalChart";

type CasoProps = {
  description: string;
  caseResolution: number;
  regionalBenchmark?: number;
  countryBenchmark?: number;
  naturalHeterosexuality: Triggers;
  exacerbatedIntent: Triggers;
  victimResponsibility: Triggers;
  overreaction: Triggers;
  ulteriorMotives: Triggers;
};

export const TarjetaCaso = ({
  description,
  caseResolution,
  regionalBenchmark,
  countryBenchmark,
  naturalHeterosexuality,
  exacerbatedIntent,
  victimResponsibility,
  overreaction,
  ulteriorMotives,
}: CasoProps) => {
  return (
    <Flex direction="column" gap="sm">
      <Paper shadow="xs" p="md" radius="md" withBorder>
        <Text>{description}</Text>
      </Paper>
      <Flex
        direction={{ base: "column", md: "row" }}
        gap="sm"
        mt="xl"
        justify="space-around"
      >
        <Flex align="center" direction="column" gap="md">
          <Title order={5}>
            Correcta identificación del caso como Acoso Sexual Laboral
          </Title>
          <BenchmarkVerticalChart
            results={caseResolution}
            regionalBenchmark={regionalBenchmark}
            countryBenchmark={countryBenchmark}
            height={300}
            width={450}
          />
        </Flex>
        <Flex align="center" direction="column" gap="md">
          <Title order={5}>Presencia de mitos</Title>
          <BarChart
            minimumValue={0.15}
            max={100}
            height={350}
            width={500}
            colors={groupedBarChartColors}
            responsive={[
              {
                breakpoint: 720,
                options: {
                  chart: {
                    width: 250,
                  },
                },
              },
            ]}
            categories={[
              ["Responsabilidad", " de la víctima"],
              ["Heterosexualidad", " naturalizada"],
              "Exageración",
              "Motivos ulteriores",
              ["Intencionalidad", "exacerbada"],
            ]}
            seriesNames={["Con activador", "Sin activador"]}
            seriesData={[
              [
                victimResponsibility.trigger_myth_percentage,
                naturalHeterosexuality.trigger_myth_percentage,
                overreaction.trigger_myth_percentage,
                ulteriorMotives.trigger_myth_percentage,
                exacerbatedIntent.trigger_myth_percentage,
              ],
              [
                victimResponsibility.non_trigger_myth_percentage,
                naturalHeterosexuality.non_trigger_myth_percentage,
                overreaction.non_trigger_myth_percentage,
                ulteriorMotives.non_trigger_myth_percentage,
                exacerbatedIntent.non_trigger_myth_percentage,
              ],
            ]}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
