import { Skeleton } from "@mantine/core";
import { MedicionesCard } from "../../components";

export const MedicionesCardSkeleton = () => (
  <Skeleton>
    <MedicionesCard
      medicion={{
        id: 1,
        title: "",
        code: "",
        organizational_survey: { id: 1, is_completed: false },
        staff_survey: {
          id: 1,
          status: "",
          launched_at: "",
          ended_at: "",
          total_population_progress: 0,
          minimum_sample_progress: 0,
        },
      }}
    />
  </Skeleton>
);
