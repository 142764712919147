import { Text } from "@mantine/core";

type Props = {
  children: any;
};

export const LabelText = ({ children }: Props) => {
  return (
    <Text weight={400} size={14} color="#495057">
      {children}
    </Text>
  );
};
