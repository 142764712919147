import { Text } from "@mantine/core";

type Props = {
  children: string | string[];
};

export const FormErrorText = ({ children }: Props) => {
  return (
    <Text size={12} color="#fa5252">
      {children}
    </Text>
  );
};
