import { Flex, Text } from "@mantine/core";
import { InfoTooltip } from "../InfoTooltip";

type Props = {
  text: string;
  fz?: number;
  fw?: number;
  tooltipText: React.ReactNode;
  width?: number;
};

export const TextWithInfoTooltip = ({
  text,
  fz = 16,
  fw = 500,
  tooltipText,
  width = 300,
}: Props) => {
  return (
    <Flex align="center" justify="center">
      <Text align="center" fz={fz} fw={fw}>
        {text}
      </Text>
      <InfoTooltip tooltipText={tooltipText} width={width} />
    </Flex>
  );
};
