import { Title } from "@mantine/core";
import { GenderLabAppShell } from "../../components";

export const BuzonDeDenunciasPage = () => {
  return (
    <GenderLabAppShell>
      <Title order={3}>Buzón de denuncias</Title>
    </GenderLabAppShell>
  );
};
