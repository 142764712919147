import { Flex, Text, createStyles } from "@mantine/core";
import { colorAzul1 } from "../../utils";

const useStyles = createStyles((theme) => ({
  manifestacionMainDiv: {
    backgroundColor: "#f2f2f2",
    flexBasis: "100%",
    borderRadius: "30px",
    padding: "5px 20px 5px 5px",
  },
  number: {
    backgroundColor: colorAzul1,
    color: "white",
    borderRadius: "100px",
    padding: "5px 20px",
  },
  text: {
    marginLeft: "20px",
  },
}));

type Props = {
  text: string;
  number: number;
};

export const ManifestacionesCard = ({ text, number }: Props) => {
  const { classes } = useStyles();
  return (
    <Flex className={classes.manifestacionMainDiv} align="center">
      <div className={classes.number}>
        <Text fz={30} fw={700}>
          {number}
        </Text>
      </div>
      <div className={classes.text}>
        <Text size="sm">{text}</Text>
      </div>
    </Flex>
  );
};
