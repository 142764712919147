import { Text } from "@mantine/core";
import { AgendaUnaDemoButton } from "../../AgendaUnaDemoButton";

export const NoTienesMedicionesComponent = () => {
  return (
    <>
      <Text color="dimmed" fz="lg">
        Por el momento no tienes mediciones activas.
        <br />
        ¿Quieres conocer cómo está tu organización en prevención del acoso
        sexual laboral?
        <br />
        Realiza una medición y visualiza tus resultados.
      </Text>

      <AgendaUnaDemoButton />
    </>
  );
};
