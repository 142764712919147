import { Flex, RingProgress, Text } from "@mantine/core";
import { determineIndexColor } from "../../../utils";

type Props = {
  value: number;
  big?: boolean;
};

export const Tacometro = ({ value, big }: Props) => {
  return (
    <RingProgress
      size={big ? 210 : 75}
      thickness={big ? 15 : 6}
      sections={[
        {
          value: value,
          color: determineIndexColor(value).color,
        },
      ]}
      label={
        <Flex direction="column" align="center">
          <Text color="dimmed" fw={600} fz={big ? 30 : "lg"}>
            {value}
          </Text>
        </Flex>
      }
    />
  );
};
