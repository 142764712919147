import axios from "axios";
import { ApiWithToken, ApiWithoutToken } from "../axios-instance";

export const webinarService = {
  uri: "/api/webinars/",
  getActiveWebinars: async () =>
    ApiWithToken.get(`${webinarService.uri}active_enrollments/`),
  getAvailableWebinars: async () =>
    ApiWithToken.get(`${webinarService.uri}available_webinars/`),
  getPastWebinars: async () =>
    ApiWithToken.get(`${webinarService.uri}completed_enrollments/`),
  getCertificate: async (enrollmentId: string) =>
    ApiWithoutToken.get(
      `${webinarService.uri}enrollment_certificate/${enrollmentId}/`
    ),
  getCalendlyData: async (url: string) =>
    axios.get(url, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_CALENDLY_TOKEN}`,
      },
    }),
  updateSessionData: async (sessionId: string, data: any) =>
    ApiWithToken.put(`${webinarService.uri}update_session/${sessionId}/`, data),
  getAttendeesPDF: async (enrollmentId: string) =>
    ApiWithToken.get(`${webinarService.uri}attendees/${enrollmentId}/`, {
      responseType: "blob",
    }),
};
