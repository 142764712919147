export const formatUTCDate = (dateString: string) => {
  const dateObject = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };
  const formattedDate = dateObject.toLocaleDateString("es-ES", options);

  return formattedDate;
};
