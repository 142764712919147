import {
  Card,
  Flex,
  Title,
  Text,
  createStyles,
  Divider,
  Container,
  Skeleton,
  Center,
  Button,
  Table,
  Paper,
} from "@mantine/core";
import { TMonitoreaData } from "../../../types";

const useStyles = createStyles((theme) => ({
  lead: {
    fontWeight: 500,
    lineHeight: 1,
    fontSize: 24,
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
    color: "#1c7ed6",
    fontSize: 14,
    fontWeight: 400,
  },
}));

type Props = {
  monitoreaData: TMonitoreaData;
};

export const Monitorea = ({ monitoreaData }: Props) => {
  const { classes } = useStyles();

  const copyUrlToClipboard = () => {
    navigator.clipboard.writeText(monitoreaData?.url);
  };

  const dataTable = (
    name: string,
    property:
      | "job_answers_count"
      | "area_answers_count"
      | "workplace_answers_count"
      | "work_shift_answers_count"
  ) => {
    return (
      <Paper shadow="sm" p="lg" radius="md" withBorder>
        <Table>
          <thead>
            <tr>
              <th scope="col" style={{ width: "20%" }}>
                {name}
              </th>
              <th scope="col" style={{ width: "20%" }}>
                Hombres
              </th>
              <th scope="col" style={{ width: "20%" }}>
                Mujeres
              </th>
              <th scope="col" style={{ width: "20%" }}>
                No binario
              </th>
              <th scope="col" style={{ width: "20%" }}>
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(monitoreaData.staff_survey[property]).map(
              ([job, answers], index) => (
                <tr key={index}>
                  <td>{job}</td>
                  <td>{answers["men"]}</td>
                  <td>{answers["women"]}</td>
                  <td>{answers["non_binary"]}</td>
                  <td>
                    {answers["men"] + answers["women"] + answers["non_binary"]}
                  </td>
                </tr>
              )
            )}
          </tbody>
        </Table>
      </Paper>
    );
  };

  return (
    <>
      {monitoreaData ? (
        <Flex mt="xl" direction="column" gap="lg">
          <Title order={3}>Progreso de tu encuesta al personal</Title>
          <Card shadow="sm" p="lg" radius="md" withBorder>
            <Flex justify="space-evenly">
              <Flex direction="column">
                <Text className={classes.lead}>
                  {monitoreaData.staff_survey.launched_at || "no establecida"}
                </Text>
                <Text size="xs" color="dimmed">
                  fecha de inicio
                </Text>
              </Flex>
              <Flex direction="column">
                <Text className={classes.lead}>
                  {monitoreaData.staff_survey.ended_at || "no establecida"}
                </Text>
                <Text size="xs" color="dimmed">
                  fecha final
                </Text>
              </Flex>
            </Flex>
            <Flex justify="center" mt="lg">
              <Flex direction="column">
                <Flex align="center">
                  <a
                    className={classes.link}
                    href={monitoreaData.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {monitoreaData.url}
                  </a>
                  <Button
                    size="xs"
                    compact
                    variant="white"
                    color="green"
                    onClick={copyUrlToClipboard}
                  >
                    copiar
                  </Button>
                </Flex>
                <Center>
                  <Text size="xs" color="dimmed">
                    enlace a tu encuesta
                  </Text>
                </Center>
              </Flex>
            </Flex>
            <Card.Section>
              <Divider my="lg" />
            </Card.Section>
            <Flex justify="space-evenly">
              <Container>
                <Text fz="xs" color="dimmed">
                  Población total:
                </Text>
                <Text className={classes.lead}>
                  {monitoreaData.staff_survey.total_population}
                </Text>
                <Text fz="xs" color="dimmed">
                  personas
                </Text>
              </Container>
              <Container>
                <Text fz="xs" color="dimmed">
                  Muestra mínima:
                </Text>
                <Text className={classes.lead}>
                  {monitoreaData.staff_survey.minimum_sample_size}
                </Text>
                <Text fz="xs" color="dimmed">
                  personas
                </Text>
              </Container>
              <Container>
                <Text fz="xs" color="dimmed">
                  Respuestas alcanzadas:
                </Text>
                <Text className={classes.lead}>
                  {monitoreaData.staff_survey.answers_count}
                </Text>
                <Text fz="xs" color="dimmed">
                  respuestas
                </Text>
              </Container>
            </Flex>
          </Card>
          <Title order={4}>Puestos</Title>
          {dataTable("Puestos", "job_answers_count")}
          {Object.keys(monitoreaData.staff_survey.area_answers_count).length >
            0 && (
            <>
              <Title order={4}>Áreas</Title>
              {dataTable("Áreas", "area_answers_count")}
            </>
          )}
          {Object.keys(monitoreaData.staff_survey.workplace_answers_count)
            .length > 0 && (
            <>
              <Title order={4}>Centros de trabajo</Title>
              {dataTable("Centros de trabajo", "workplace_answers_count")}
            </>
          )}
          {Object.keys(monitoreaData.staff_survey.work_shift_answers_count)
            .length > 0 && (
            <>
              <Title order={4}>Turnos</Title>
              {dataTable("Turnos", "work_shift_answers_count")}
            </>
          )}
        </Flex>
      ) : (
        <Skeleton mt="xl" height={300} />
      )}
    </>
  );
};
