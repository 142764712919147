import {
  Button,
  Card,
  Flex,
  Overlay,
  RingProgress,
  Select,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { BsDownload } from "react-icons/bs";
import { PlanDeAccionCard } from "../PlanDeAccionCard";

export const PlanDeAccionBlurred = () => {
  const images = [
    "sistema-de-prevencion.png",
    "conocimiento.png",
    "tolerancia.png",
    "clima-organizacional.png",
    "prevalencia.png",
    "testigos.png",
  ];

  return (
    <div>
      <div style={{ position: "relative" }}>
        <Stack spacing="md" px="sm">
          <Flex gap="sm" pb="md" align="flex-end">
            <Select
              maw={200}
              label="Medición"
              data={[{ value: "Elsa 2024", label: "Elsa 2024" }]}
              value="Elsa 2024"
            />
            <Button color="blue" size="xs">
              <Flex gap="xs" align="center">
                <BsDownload /> Descargar plan
              </Flex>
            </Button>
          </Flex>
          <Text>
            En esta sección podrás encontrar una serie de acciones recomendadas
            para reforzar tu trabajo de prevención del acoso sexual laboral.
            Estas acciones son recomendadas en base a los resultados de tu
            organización en la medición ELSA y se encuentran etiquetadas según
            los subíndices del Índice ELSA. No olvides marcar como completadas
            las acciones que ya implementaste.
          </Text>
          <Card withBorder>
            <Title order={5}>Tu progreso</Title>
            <Flex justify="space-around" align="center">
              <RingProgress size={150} sections={[]} />
              <Text fw={500}>100% Pendiente</Text>
              <Text fw={500}>0% En ejecución</Text>
              <Text fw={500}>0% Completado</Text>
              <Stack spacing="xs" align="center">
                <Text fw={500}>Fecha estimada de finalización</Text>
                <Text color="gray">01/01/2030</Text>
              </Stack>
            </Flex>
          </Card>

          {images.map((image, index) => (
            <PlanDeAccionCard
              key={index}
              image={`/img/pda/${image}`}
              title="Lorem ipsum dolor sit amet"
              completedRecommendations={0}
              totalRecommendations={5}
              recommendations={[
                {
                  id: 1,
                  status: "En ejecución",
                  action: {
                    name: "Lorem ipsum dolor sit amet",
                    category: "Lorem ipsum dolor sit amet",
                    problem: "Lorem ipsum dolor sit amet",
                    solution: "Lorem ipsum dolor sit amet",
                  },
                },
              ]}
              onSelectChange={() => Promise.resolve()}
              defaultOpened={index === 0}
              code={""}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
              vestibulum, tortor ut ultricies tincidunt, nunc nisl ultricies
              nunc, nec tincidunt nisl nunc sit amet libero. Integer sit amet
              urna nec
            </PlanDeAccionCard>
          ))}
        </Stack>
        <Overlay color="#f8f9fa" opacity={0.05} blur={4} />
      </div>
    </div>
  );
};
