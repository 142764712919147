import { Button } from "@mantine/core";
import { contactURL } from "../../utils";

export const AgendaUnaDemoButton = () => (
  <Button
    my="md"
    component="a"
    target="_blank"
    rel="noopener noreferrer"
    href={contactURL}
  >
    Agenda una demo
  </Button>
);
