import { Button, Stack, Text } from "@mantine/core";
import { SectionTitle, GenderLabLoader } from "../../components";
import { IndiceContent } from "../../components/Resultados";
import { useEffect, useState } from "react";
import { TIndexScore, TIndexHistory } from "../../types";
import { clientService } from "../../api";
import { useAuth } from "../../context";
import { contactURL } from "../../utils";

type Props = {
  code: string;
};

export const Indice = ({ code }: Props) => {
  const { user } = useAuth();

  const [indexScore, setIndexScore] = useState<TIndexScore | null>(null);
  const [indexHistory, setIndexHistory] = useState<TIndexHistory | null>(null);

  useEffect(() => {
    const getIndex = async () => {
      if (code) {
        setIndexHistory((await clientService.index.history(code)).data);
        setIndexScore((await clientService.index.score(code)).data);
      }
    };

    getIndex();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const indiceTitle = () => (
    <SectionTitle
      title="Índice ELSA"
      description="Puntuación de la organización en el Índice ELSA en base a las respuestas en la encuesta organizacional y al personal. El Índice ELSA es una metodología que permite a las organizaciones ubicar su avance en la prevención del acoso sexual laboral. Está compuesto por cuatro subíndices así como por bonus y penalidades."
    />
  );

  if (!user) {
    return <GenderLabLoader />;
  }

  if (!user.hasIndex && !user.hasExpiredMeasurementProcess) {
    return (
      <>
        <Text color="dimmed" fz="lg">
          Esta funcionalidad no está disponible para tu Plan.
          <br />
          ¿Quieres acceder al Índice ELSA para ubicarte y mejorar tus
          resultados?
        </Text>

        <Button
          my="md"
          component="a"
          target="_blank"
          rel="noopener noreferrer"
          href={contactURL}
        >
          Contáctanos
        </Button>

        {indiceTitle()}

        <IndiceContent overlay />
      </>
    );
  } else if (indexHistory === null || indexScore === null) {
    return <GenderLabLoader />;
  } else
    return (
      <Stack>
        {indiceTitle()}
        <IndiceContent indexHistory={indexHistory} indexScore={indexScore} />
      </Stack>
    );
};
