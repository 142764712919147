import { useEffect, useState } from "react";
import { useAuth } from "../../context";
import { colorCeleste1, colorRojoClaro1, colorVerdeAgua2 } from "../../utils";
import { BarChart } from "../Charts";

type Props = {
  results: number;
  regionalBenchmark?: number;
  countryBenchmark?: number;
  height?: number;
  width?: number;
};

export const BenchmarkVerticalChart = ({
  results,
  regionalBenchmark,
  countryBenchmark,
  height,
  width,
}: Props) => {
  const { user } = useAuth();
  const [seriesData, setSeriesData] = useState<number[][]>([[results]]);
  const [categories, setCategories] = useState<string[]>(["Tus resultados"]);

  useEffect(() => {
    if (user) {
      const tmpSeriesData = [[results]];
      const tmpCategories = ["Tus resultados"];
      if (user.hasRegionalBenchmark && regionalBenchmark) {
        tmpSeriesData[0].push(regionalBenchmark);
        tmpCategories.push("Benchmark Regional");
      }
      if (user.hasCountryBenchmark && countryBenchmark) {
        tmpSeriesData[0].push(countryBenchmark);
        tmpCategories.push("Benchmark País");
      }
      setSeriesData(tmpSeriesData);
      setCategories(tmpCategories);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, regionalBenchmark, countryBenchmark]);

  return (
    <BarChart
      horizontal={false}
      categories={categories}
      seriesData={seriesData}
      height={height}
      width={width}
      colors={[colorCeleste1, colorRojoClaro1, colorVerdeAgua2]}
      distributed={true}
    />
  );
};
