import { Flex } from "@mantine/core";
import { ResultadosCard } from "./ResultadosCard";
import {
  colorVerdeAgua0,
  colorVerdeAgua1,
  colorVerdeAgua2,
  colorVerdeAgua3,
} from "../../utils/colors/colors";
import Chart from "react-apexcharts";
import { TToleranceOverview } from "../../types";
import { parsePercentage } from "../../utils";

type Props = {
  tolerance: TToleranceOverview;
};

export const TarjetaTolerancia = ({ tolerance }: Props) => {
  const seriesCategories: (
    | "No veo nada de malo con esa situación."
    | "No son formas de comportarse en el trabajo, pero no es acoso."
    | "Es hostigamiento o acoso, pero no es sexual."
    | "Es hostigamiento o acoso sexual en el trabajo."
  )[] = [
    "No veo nada de malo con esa situación.",
    "No son formas de comportarse en el trabajo, pero no es acoso.",
    "Es hostigamiento o acoso, pero no es sexual.",
    "Es hostigamiento o acoso sexual en el trabajo.",
  ];

  return (
    <ResultadosCard
      title="Percepciones sobre el hostigamiento o acoso sexual laboral"
      description="Porcentaje de personas encuestadas que tienen las siguientes percepciones acerca del acoso sexual."
    >
      <Flex align="center" direction="column">
        <Chart
          options={{
            colors: [
              colorVerdeAgua3,
              colorVerdeAgua2,
              colorVerdeAgua1,
              colorVerdeAgua0,
            ],
            chart: {
              type: "bar",
              stacked: true,
              stackType: "100%",
            },
            dataLabels: {
              enabled: false,
            },
            fill: {
              opacity: 1,
            },
            plotOptions: {
              bar: {
                horizontal: true,
              },
            },
            responsive: [
              {
                breakpoint: 992,
                options: {
                  chart: {
                    width: 350,
                  },
                },
              },
            ],
            xaxis: {
              categories: [
                "Acoso de género",
                ["Atención sexual", "no deseada"],
                "Coerción sexual",
              ],
            },
            legend: {
              position: "bottom",
              horizontalAlign: "center",
              offsetX: 0,
              offsetY: 0,
            },
          }}
          series={seriesCategories.map((name) => ({
            name,
            data: [
              parsePercentage(tolerance.gender_harassment[name]),
              parsePercentage(tolerance.undesired_sexual_attention[name]),
              parsePercentage(tolerance.sexual_coercion[name]),
            ],
          }))}
          type="bar"
          height={300}
          width={700}
        />
      </Flex>
    </ResultadosCard>
  );
};
