import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const PageTitleUpdater = () => {
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    if (path.startsWith("/medicion/")) {
      document.title = "ELSA - Medición";
    } else if (path.startsWith("/resultados/")) {
      document.title = "ELSA - Resultados";
    } else if (path.startsWith("/plan-de-accion/")) {
      document.title = "ELSA - Plan de Acción";
    } else {
      switch (path) {
        case "/inicio":
          document.title = "ELSA - Inicio";
          break;
        case "/mediciones":
          document.title = "ELSA - Mediciones";
          break;
        case "/resultados":
          document.title = "ELSA - Resultados";
          break;
        case "/red-elsa":
          document.title = "ELSA - Red";
          break;
        case "/plan-de-accion":
          document.title = "ELSA - Plan de Acción";
          break;
        case "/clases-en-vivo":
          document.title = "ELSA - Live";
          break;
        case "/aula-virtual":
          document.title = "ELSA - Aula";
          break;
        case "/mi-cuenta":
          document.title = "ELSA - Cuenta";
          break;
        default:
          document.title = "ELSA App";
      }
    }
  }, [location]);

  return null;
};
