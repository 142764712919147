import { useState } from "react";
import { Carousel, Embla } from "@mantine/carousel";
import { Flex, Text } from "@mantine/core";
import {
  CustomLink,
  GenderLabCarouselWrapper,
  MedicionesCard,
} from "../../components";
import { useAuth } from "../../context";
import { TMedicion } from "../../types";
import { useStyles } from "./index";
import { MedicionesCardSkeleton } from "./_medicionesCardSkeleton";
import { useTallyUrl } from "../../hooks/useTallyUrl";

type MedicionesPageCarrouselProps = {
  mediciones: TMedicion[];
};

export const MedicionesPageCarrousel = ({
  mediciones,
}: MedicionesPageCarrouselProps) => {
  const { loading } = useAuth();
  const { classes } = useStyles();

  const [embla, setEmbla] = useState<Embla | null>(null);

  const handleNext = () => embla?.scrollNext(false);
  const handlePrev = () => embla?.scrollPrev(false);

  const tallyUrl = useTallyUrl();

  return (
    <>
      {!mediciones || loading || !tallyUrl ? (
        <MedicionesCardSkeleton />
      ) : (
        <>
          {mediciones.length === 0 && (
            <Text size="md" color="dimmed">
              En este momento no cuentas con una medición activa. Para iniciar
              tu siguiente medición ELSA,{" "}
              <CustomLink to={tallyUrl} target="_blank" fontSize="14">
                contáctanos aquí
              </CustomLink>
              .
            </Text>
          )}
          {mediciones.length === 1 && (
            <Flex justify="start">
              <MedicionesCard medicion={mediciones[0]} />
            </Flex>
          )}
          {mediciones.length > 1 && (
            <GenderLabCarouselWrapper
              handleNext={handleNext}
              handlePrev={handlePrev}
              showButtons={mediciones.length > 0}
            >
              <Carousel
                slideSize="33.33%"
                breakpoints={[
                  {
                    maxWidth: 1500,
                    slideSize: "50%",
                    slideGap: 2,
                  },
                  {
                    maxWidth: 1200,
                    slideSize: "100%",
                    slideGap: 1,
                  },
                  { maxWidth: "sm", slideSize: "100%", slideGap: 1 },
                ]}
                slideGap="xl"
                align="start"
                draggable={false}
                classNames={classes}
                controlsOffset="xs"
                withControls={false}
                getEmblaApi={setEmbla}
                loop
              >
                {mediciones.map((medicion) => (
                  <Carousel.Slide key={medicion.id}>
                    <MedicionesCard medicion={medicion} />
                  </Carousel.Slide>
                ))}
              </Carousel>
            </GenderLabCarouselWrapper>
          )}
        </>
      )}
    </>
  );
};
