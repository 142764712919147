import Chart from "react-apexcharts";
import {
  colorAmarillo1,
  colorAzul1,
  colorCeleste1,
  colorMorado1,
  colorRojoClaro1,
  colorVerdeAgua1,
} from "../../utils";

type Props = {
  data: {
    name: string;
    data: number[];
  }[];
  categories: string[];
};

export const Heatmap = ({ data, categories }: Props) => {
  return (
    <Chart
      type="heatmap"
      height={350}
      options={{
        dataLabels: {
          enabled: true,
          style: {
            colors: ["#000"],
          },
          formatter: function (
            value: any,
            { seriesIndex, dataPointIndex, w }: any
          ) {
            if (value === -100) return "N.D.";
            return value + "%";
          },
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
            const value = series[seriesIndex][dataPointIndex];
            if (value === -100)
              return "No disponible. Esta manifestación no fue una de las priorizadas en el 2022.";
            return value + "%";
          },
        },
        chart: {
          type: "heatmap",
          toolbar: {
            show: true,
          },
        },
        colors: [
          colorRojoClaro1,
          colorVerdeAgua1,
          colorAzul1,
          colorAmarillo1,
          colorMorado1,
          colorCeleste1,
        ],
        xaxis: {
          categories: categories,
        },
      }}
      series={data}
    />
  );
};
