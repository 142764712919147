import { Flex } from "@mantine/core";
import { EliminarButton } from "../../../components";
import { EditarButton } from "./__editarButton";

type Props = {
  onEditarClick: () => void;
  onBorrarClick: () => void;
  disabled?: boolean;
};
export const EditarEliminarButtonsCombo = ({
  onEditarClick,
  onBorrarClick,
  disabled,
}: Props) => {
  return (
    <Flex gap="3px">
      <EditarButton onClick={onEditarClick} disabled={disabled} />
      <EliminarButton onClick={onBorrarClick} disabled={disabled} />
    </Flex>
  );
};
