import { TPreventionSystem } from "../../types";
import { Center, Flex, List, Tabs, createStyles } from "@mantine/core";
import Chart from "react-apexcharts";
import { useEffect, useState } from "react";

import {
  BarChart,
  FilaChecklistPrevencion,
  ResultadosCard,
  SectionTitle,
  GenderLabLoader,
} from "../../components";
import { colorAzul1, colorCeleste1 } from "../../utils";
import { useAuth, useMetrics } from "../../context";

const useStyles = createStyles(() => ({
  container: {
    maxWidth: 700,
  },

  conocimientoContainer: {
    maxWidth: 1400,
    margin: "0 auto",
  },
}));

type Props = {
  preventionSystem: TPreventionSystem | null;
};

export const SistemaDePrevencionResultados = ({ preventionSystem }: Props) => {
  const { classes } = useStyles();
  const [identifiedChannels, setIdentifiedChannels] = useState<{
    [key: string]: number;
  }>({});
  const { user } = useAuth();

  const { regionalBenchmark, countryBenchmark, previousMetrics } = useMetrics();

  const parseDistribution = (xAxis: string[], distribution: any) => {
    const resultTemp: any = {};
    distribution.forEach((item: any) => {
      resultTemp[item.answer] = Math.floor(item.percentage * 100);
    });

    const result: number[] = [];

    xAxis.forEach((x) => {
      if (resultTemp[x]) {
        result.push(resultTemp[x]);
      } else {
        result.push(0);
      }
    });

    return result;
  };

  const heatMapDataLabels = {
    enabled: true,
    style: {
      colors: ["#000"],
    },
    formatter: function (value: any, { seriesIndex, dataPointIndex, w }: any) {
      return value + "%";
    },
  };

  useEffect(() => {
    if (preventionSystem) {
      const identifiedChannels = preventionSystem.identified_channels;
      const sortedIdentifiedChannels: any = Object.entries(identifiedChannels)
        .sort(([, a], [, b]) => b - a)
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
      setIdentifiedChannels(sortedIdentifiedChannels);
    }
  }, [preventionSystem]);

  const [knowledgeGoals, setKnowledgeGoals] = useState<number[][]>([]);
  const [knowledgeGoalNames, setKnowledgeGoalNames] = useState<string[]>([]);

  useEffect(() => {
    if (user) {
      const goals: number[][] = [];
      const goalNames: string[] = [];
      if (user.hasRegionalBenchmark && regionalBenchmark) {
        goals.push([
          regionalBenchmark.benchmarks.RB_0001,
          regionalBenchmark.benchmarks.RB_0002,
          regionalBenchmark.benchmarks.RB_0003,
          regionalBenchmark.benchmarks.RB_0004,
        ]);
        goalNames.push("Benchmark Regional");
      }
      if (user.hasCountryBenchmark && countryBenchmark) {
        goals.push([
          countryBenchmark.benchmarks.CB_0001,
          countryBenchmark.benchmarks.CB_0002,
          countryBenchmark.benchmarks.CB_0003,
          countryBenchmark.benchmarks.CB_0004,
        ]);
        goalNames.push("Benchmark País");
      }
      if (user.hasEvolutionaryResults && previousMetrics) {
        goals.push([
          previousMetrics.metrics.COM_0001,
          previousMetrics.metrics.COM_0002,
          previousMetrics.metrics.COM_0003,
          previousMetrics.metrics.COM_0004,
        ]);
        goalNames.push("Medición anterior");
      }
      setKnowledgeGoals(goals);
      setKnowledgeGoalNames(goalNames);
    }
  }, [user, regionalBenchmark, countryBenchmark, previousMetrics]);

  return (
    <>
      {preventionSystem ? (
        <Flex direction="column" gap="md">
          <SectionTitle
            title="Sistema de prevención"
            description="Conocimiento por parte de las personas encuestadas sobre el sistema de prevención de tu empresa."
          />
          <Flex direction={{ base: "column", lg: "row" }} gap="xs">
            <ResultadosCard
              title="Checklist del sistema de prevención"
              description="Elementos marcados en tu encuesta organizacional."
            >
              <Center>
                <Flex direction="column" gap="xs">
                  <FilaChecklistPrevencion
                    title="Contar con una política de prevención del hostigamiento o acoso sexual laboral."
                    checked={preventionSystem.checklist.has_policy}
                  />
                  <FilaChecklistPrevencion
                    title="Contar con un canal de denuncias de hostigamiento o acoso sexual laboral."
                    checked={preventionSystem.checklist.has_channel}
                  />
                  <FilaChecklistPrevencion
                    title="Contar con un procedimiento de investigación aplicable a los casos de hostigamiento o acoso sexual laboral."
                    checked={preventionSystem.checklist.has_procedure}
                  />
                  <FilaChecklistPrevencion
                    title="Tener un Comité de Intervención, Comité de Convivencia u otra instancia especifica para investigar y resolver casos de hostigamiento o acoso sexual laboral."
                    checked={preventionSystem.checklist.has_committee}
                  />
                  <FilaChecklistPrevencion
                    title="Participar en el ecosistema de igualdad e inclusión."
                    checked={preventionSystem.checklist.belongs_to_environment}
                  />
                  <FilaChecklistPrevencion
                    title="Realizar capacitaciones a su personal en hostigamiento o acoso sexual laboral"
                    checked={preventionSystem.checklist.has_training}
                  />
                </Flex>
              </Center>
            </ResultadosCard>
            {/* <Flex> */}
            <Flex sx={{ flexBasis: "auto" }}>
              <ResultadosCard
                title="Capacitación por público"
                description="Porcentaje de capacitación según tu encuesta organizacional."
              >
                <Flex
                  align="center"
                  justify="center"
                  direction="column"
                  px="lg"
                >
                  <List mt="sm">
                    <List.Item>
                      Líderes de la organización:{" "}
                      {preventionSystem.checklist.training_by_public.leaders}
                    </List.Item>
                    <List.Item>
                      Recursos Humanos:{" "}
                      {preventionSystem.checklist.training_by_public.rrhh}
                    </List.Item>
                    <List.Item>
                      Comités:{" "}
                      {preventionSystem.checklist.training_by_public.committee}
                    </List.Item>
                    <List.Item>
                      Trabajadores/as:{" "}
                      {preventionSystem.checklist.training_by_public.employees}
                    </List.Item>
                    <List.Item>
                      Empresas contratistas:{" "}
                      {preventionSystem.checklist.training_by_public.others}
                    </List.Item>
                  </List>
                </Flex>
              </ResultadosCard>
            </Flex>
          </Flex>
          <ResultadosCard
            title="Conocimiento del sistema de prevención"
            description="Porcentaje que conoce los elementos del sistema de prevención."
          >
            <div className={classes.conocimientoContainer}>
              <Center>
                <BarChart
                  categories={[
                    ["Conoce y ha", "revisado la política"],
                    ["Recibió capacitación", "al menos una vez"],
                    ["Conoce y sabe cómo", "acceder al", "canal de denuncias"],
                    ["Conoce el procedimiento", "de investigación"],
                  ]}
                  seriesData={[
                    [
                      preventionSystem.overview.policy,
                      preventionSystem.overview.training,
                      preventionSystem.overview.channel,
                      preventionSystem.overview.procedure,
                    ],
                  ]}
                  seriesNames={["Tus resultados"]}
                  max={100}
                  height={500}
                  width={800}
                  colors={[colorCeleste1]}
                  goals={knowledgeGoals}
                  goalNames={knowledgeGoalNames}
                />
              </Center>
            </div>
          </ResultadosCard>
          <ResultadosCard
            title="Conocimiento según puesto de trabajo"
            description="Porcentaje que conoce los elementos del sistema de prevención."
          >
            <Tabs defaultValue="politica" orientation="horizontal" mt="sm">
              <Tabs.List grow>
                <Tabs.Tab value="politica">Política</Tabs.Tab>
                <Tabs.Tab value="capacitacion">Capacitación</Tabs.Tab>
                <Tabs.Tab value="canal-de-denuncia">Canal de denuncia</Tabs.Tab>
                <Tabs.Tab value="procedimiento">Procedimiento</Tabs.Tab>
              </Tabs.List>
              <Tabs.Panel value="politica">
                <Chart
                  series={preventionSystem.policy_knowledge.map((item) => ({
                    name: item.job,
                    data: parseDistribution(
                      [
                        "Sí, la he revisado.",
                        "Sé que existe pero no la he revisado.",
                        "No.",
                      ],
                      item.distribution
                    ),
                  }))}
                  options={{
                    chart: {
                      type: "heatmap",
                      toolbar: {
                        show: false,
                      },
                    },
                    dataLabels: heatMapDataLabels,
                    colors: [colorAzul1],
                    xaxis: {
                      categories: [
                        "Sí, la he revisado.",
                        ["Sé que existe pero", "no la he revisado."],
                        "No.",
                      ],
                    },
                  }}
                  type="heatmap"
                  height={450}
                />
              </Tabs.Panel>
              <Tabs.Panel value="capacitacion">
                <Chart
                  series={preventionSystem.training_knowledge.map((item) => ({
                    name: item.job,
                    data: parseDistribution(
                      ["Sí, más de una vez.", "Sí, una vez.", "No, nunca."],
                      item.distribution
                    ),
                  }))}
                  options={{
                    chart: {
                      type: "heatmap",
                      toolbar: {
                        show: false,
                      },
                    },
                    dataLabels: heatMapDataLabels,
                    colors: [colorAzul1],
                    xaxis: {
                      categories: [
                        "Sí, más de una vez.",
                        "Sí, una vez.",
                        "No, nunca.",
                      ],
                    },
                  }}
                  type="heatmap"
                  height={450}
                />
              </Tabs.Panel>
              <Tabs.Panel value="canal-de-denuncia">
                <Chart
                  series={preventionSystem.channel_knowledge.map((item) => ({
                    name: item.job,
                    data: parseDistribution(
                      [
                        "Sí, y sé cómo acceder a él.",
                        "Sé que existe pero no sé cómo acceder a él.",
                        "No.",
                      ],
                      item.distribution
                    ),
                  }))}
                  options={{
                    chart: {
                      type: "heatmap",
                      toolbar: {
                        show: false,
                      },
                    },
                    dataLabels: heatMapDataLabels,
                    colors: [colorAzul1],
                    xaxis: {
                      categories: [
                        "Sí, y sé cómo acceder a él.",
                        ["Sé que existe pero", "no sé cómo acceder a él."],
                        "No.",
                      ],
                    },
                  }}
                  type="heatmap"
                  height={450}
                />
              </Tabs.Panel>
              <Tabs.Panel value="procedimiento">
                <Chart
                  series={preventionSystem.procedure_knowledge.map((item) => ({
                    name: item.job,
                    data: parseDistribution(
                      ["Sí, lo he revisado.", "No."],
                      item.distribution
                    ),
                  }))}
                  options={{
                    chart: {
                      type: "heatmap",
                      toolbar: {
                        show: false,
                      },
                    },
                    dataLabels: heatMapDataLabels,
                    colors: [colorAzul1],
                    xaxis: {
                      categories: ["Sí, lo he revisado.", "No."],
                    },
                  }}
                  type="heatmap"
                  height={450}
                />
              </Tabs.Panel>
            </Tabs>
          </ResultadosCard>
          <Flex direction={{ base: "column", md: "row" }} gap="md">
            <ResultadosCard
              title="Canales de denuncia según el personal"
              description="Porcentaje que identifica estos canales de denuncia."
              // sx={{ width: "50%" }}
            >
              <Flex align="center" justify="space-evenly">
                <BarChart
                  max={100}
                  height={400}
                  // height={300}
                  width={800}
                  // width={500}
                  categories={Object.keys(identifiedChannels)}
                  seriesData={[Object.values(identifiedChannels)]}
                />
              </Flex>
            </ResultadosCard>
            <ResultadosCard
              title="Canales de denuncia según la empresa"
              description="Canales identificados en tu encuesta organizacional."
              alignSelf="flex-start"
              // alignSelf="center"
              // sx={{ width: "50%" }}
            >
              <Flex justify="center">
                <List>
                  {preventionSystem.actual_channels.map((item) => (
                    <List.Item key={item}>{item}</List.Item>
                  ))}
                </List>
              </Flex>
            </ResultadosCard>
          </Flex>
        </Flex>
      ) : (
        <GenderLabLoader />
      )}
    </>
  );
};
