import {
  Button,
  Checkbox,
  Divider,
  Flex,
  Grid,
  NumberInput,
  Paper,
  Radio,
  Table,
  Text,
  Tooltip,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import _ from "lodash";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { onChangeCleanFields, notificacionReviseLosCampos } from "../../utils";
import {
  FormErrorText,
  GenderLabAppShell,
  GenderLabBreadcrumbs,
  LabelText,
  RadioYesNoTable,
} from "../../components";
import { useAuth, useMedicionActual } from "../../context";
import { clientService } from "../../api";
import { notificacionErrorEncuesta } from "./_notificacionError";
import { encuestaOrganizacionalIsDisabled } from "./_encuestaOrganizacionalIsDisabled";

export const EncuestaCanalDeDenuncias = () => {
  const { medicionActualCookie } = useMedicionActual();
  const { code } = useParams();
  const navigate = useNavigate();
  const { loading, setLoading } = useAuth();
  const disabled = encuestaOrganizacionalIsDisabled(medicionActualCookie);

  useEffect(() => {
    const getData = async () => {
      const response = await clientService.organizationalSurvey.channels.get(
        code
      );
      const data = response.data;
      for (const key in data) {
        if (data[key] === null) {
          data[key] = "";
        } else if (data[key] === true) {
          data[key] = key;
        }
      }

      const temp: string[] = [];
      if (data?.cad_006) temp.push("cad_006");
      if (data?.cad_007) temp.push("cad_007");
      if (data?.cad_008) temp.push("cad_008");
      if (data?.cad_009) temp.push("cad_009");
      if (data?.cad_010) temp.push("cad_010");
      if (data?.cad_011) temp.push("cad_011");
      if (data?.cad_012) temp.push("cad_012");
      if (data?.cad_013) temp.push("cad_013");

      form.setValues({
        cad_001: data?.cad_001,
        cad_002: data?.cad_002,
        cad_003: data?.cad_003,
        cad_004: data?.cad_004,
        cad_005: data?.cad_005,
        cad_006_013: temp,
        cad_014: data?.cad_014,
        cad_015: data?.cad_015,
        cad_016: data?.cad_016,
        cad_017: data?.cad_017,
        cad_018: data?.cad_018,
        cad_019: data?.cad_019,
        cad_020: data?.cad_020,
        cad_021: data?.cad_021,
        cad_022: data?.cad_022,
        cad_023: data?.cad_023,
        cad_024: data?.cad_024,
        cad_025: data?.cad_025,
        cad_026: data?.cad_026,
        cad_027: data?.cad_027,
        cad_028: data?.cad_028,
      });
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const form = useForm({
    initialValues: {
      cad_001: "",
      cad_002: "",
      cad_003: "",
      cad_004: "",
      cad_005: "",
      cad_006_013: [""],
      cad_014: "",
      cad_015: "",
      cad_016: "",
      cad_017: "",
      cad_018: "",
      cad_019: "",
      cad_020: "",
      cad_021: "",
      cad_022: "",
      cad_023: "",
      cad_024: "",
      cad_025: "",
      cad_026: "",
      cad_027: "",
      cad_028: "",
    },
    validate: {
      cad_001: (value) => (value === "" ? "Campo requerido" : null),
      cad_002: () => validateFormIfCondition("cad_001", "cad_002"),
      cad_003: () => validateFormIfCondition("cad_001", "cad_003"),
      cad_004: (value) => (value === "" ? "Campo requerido" : null),
      cad_005: () => validateFormIfCondition("cad_004", "cad_005"),
      cad_014: (value) => (value === "" ? "Campo requerido" : null),
      cad_015: () => validateFormIfCondition("cad_014", "cad_015"),
      cad_016: () => validateFormIfCondition("cad_014", "cad_016"),
      cad_017: () => validateFormIfCondition("cad_014", "cad_017"),
      cad_018: () => validateFormIfCondition("cad_014", "cad_018"),
      cad_019: (value) => (value === "" ? "Campo requerido" : null),
      cad_020: () => validateFormIfCondition("cad_019", "cad_020"),
      cad_021: () => validateFormIfCondition("cad_019", "cad_021"),
      cad_022: () => validateFormIfCondition("cad_019", "cad_022"),
      cad_023: () => validateFormIfCondition("cad_019", "cad_023"),
      cad_024: () => validateFormIfCondition("cad_019", "cad_024"),
      cad_025: () => validateFormIfCondition("cad_019", "cad_025"),
      cad_026: () => validateFormIfCondition("cad_019", "cad_026"),
      cad_027: () => validateFormIfCondition("cad_019", "cad_027"),
      cad_028: () => validateFormIfCondition("cad_019", "cad_028"),
    },
  });

  const calculateTotalByCategory = (formIds: {
    hombres: string;
    mujeres: string;
    noBinario: string;
  }) => {
    return (
      parseInt(_.get(form.values, formIds.hombres) || "0") +
      parseInt(_.get(form.values, formIds.mujeres) || "0") +
      parseInt(_.get(form.values, formIds.noBinario) || "0")
    );
  };

  const totalIsGreaterThanOtherCategories = () => {
    return (
      calculateTotalByCategory({
        hombres: "cad_020",
        mujeres: "cad_021",
        noBinario: "cad_022",
      }) >=
      calculateTotalByCategory({
        hombres: "cad_023",
        mujeres: "cad_024",
        noBinario: "cad_025",
      }) +
        calculateTotalByCategory({
          hombres: "cad_026",
          mujeres: "cad_027",
          noBinario: "cad_028",
        })
    );
  };

  const validateFormIfCondition = (
    formValueCondition: string,
    formValueToCheck: string
  ): string | null => {
    if (_.get(form.values, formValueCondition) === "Sí.") {
      const value = _.get(form.values, formValueToCheck);
      return value === "" || value.length === 0 ? "Campo requerido" : null;
    } else return null;
  };

  const saveValues = async () => {
    if (!form.validate().hasErrors && totalIsGreaterThanOtherCategories()) {
      try {
        const toSubmit: any = {};
        toSubmit.cad_001 = form.values.cad_001;
        toSubmit.cad_002 = form.values.cad_002 || null;
        toSubmit.cad_003 = form.values.cad_003 || null;
        toSubmit.cad_004 = form.values.cad_004;
        toSubmit.cad_005 = form.values.cad_005 || null;

        const existeCanalDeDenuncias = form.values.cad_004 === "Sí.";
        const otrosCanales: string[] = form.values.cad_006_013;
        const determinarValorCheckbox = (
          questionCode: string,
          condition: boolean,
          array: string[]
        ) => {
          if (condition) {
            return array.includes(questionCode);
          } else {
            return null;
          }
        };
        toSubmit.cad_006 = determinarValorCheckbox(
          "cad_006",
          existeCanalDeDenuncias,
          otrosCanales
        );
        toSubmit.cad_007 = determinarValorCheckbox(
          "cad_007",
          existeCanalDeDenuncias,
          otrosCanales
        );
        toSubmit.cad_008 = determinarValorCheckbox(
          "cad_008",
          existeCanalDeDenuncias,
          otrosCanales
        );
        toSubmit.cad_009 = determinarValorCheckbox(
          "cad_009",
          existeCanalDeDenuncias,
          otrosCanales
        );
        toSubmit.cad_010 = determinarValorCheckbox(
          "cad_010",
          existeCanalDeDenuncias,
          otrosCanales
        );
        toSubmit.cad_011 = determinarValorCheckbox(
          "cad_011",
          existeCanalDeDenuncias,
          otrosCanales
        );
        toSubmit.cad_012 = determinarValorCheckbox(
          "cad_012",
          existeCanalDeDenuncias,
          otrosCanales
        );
        toSubmit.cad_013 = determinarValorCheckbox(
          "cad_013",
          existeCanalDeDenuncias,
          otrosCanales
        );

        toSubmit.cad_014 = form.values.cad_014;
        toSubmit.cad_015 = form.values.cad_015 || null;
        toSubmit.cad_016 = form.values.cad_016 || null;
        toSubmit.cad_017 = form.values.cad_017 || null;
        toSubmit.cad_018 = form.values.cad_018 || null;

        toSubmit.cad_019 = form.values.cad_019;

        if (form.values.cad_019 === "Sí.") {
          toSubmit.cad_020 = parseInt(form.values.cad_020);
          toSubmit.cad_021 = parseInt(form.values.cad_021);
          toSubmit.cad_022 = parseInt(form.values.cad_022);
          toSubmit.cad_023 = parseInt(form.values.cad_023);
          toSubmit.cad_024 = parseInt(form.values.cad_024);
          toSubmit.cad_025 = parseInt(form.values.cad_025);
          toSubmit.cad_026 = parseInt(form.values.cad_026);
          toSubmit.cad_027 = parseInt(form.values.cad_027);
          toSubmit.cad_028 = parseInt(form.values.cad_028);
        } else {
          toSubmit.cad_020 = null;
          toSubmit.cad_021 = null;
          toSubmit.cad_022 = null;
          toSubmit.cad_023 = null;
          toSubmit.cad_024 = null;
          toSubmit.cad_025 = null;
          toSubmit.cad_026 = null;
          toSubmit.cad_027 = null;
          toSubmit.cad_028 = null;
        }

        setLoading(true);
        await clientService.organizationalSurvey.channels.put(code, toSubmit);
        setLoading(false);
        navigate(`/medicion/${code}`, {
          state: {
            from: "eo",
          },
        });
      } catch (error) {
        console.error(error);
        setLoading(false);
        notificacionErrorEncuesta();
      }
    } else {
      notificacionReviseLosCampos();
    }
  };

  return (
    <GenderLabAppShell>
      <GenderLabBreadcrumbs
        breadcrumbs={[
          { link: "/mediciones", title: "Mediciones" },
          {
            link: "/medicion/" + medicionActualCookie?.code,
            title: medicionActualCookie?.title || "",
          },
          { link: "", title: "Canal de denuncias" },
        ]}
      />
      <Paper shadow="xs" p="md" mt="lg">
        <Text size={14}>
          Esta sección contiene preguntas relacionadas al canal de denuncias
          frente al hostigamiento sexual laboral de la organización y a las
          denuncias que han recibido en los últimos años. Selecciona una
          respuesta o llena cada una de las casillas.
        </Text>
      </Paper>
      <form>
        <Grid mt="xs" gutter="md">
          <Grid.Col span={12}>
            <Radio.Group
              label="¿Existe alguna instancia o comité (de intervención, ética, convivencia u otro) para investigar los casos que se puedan presentar sobre acoso u hostigamiento sexual en el trabajo?"
              {...form.getInputProps("cad_001")}
              onChange={(value) =>
                onChangeCleanFields(
                  value,
                  "cad_001",
                  ["cad_002", "cad_003"],
                  "No.",
                  form
                )
              }
            >
              <Radio disabled={disabled} value="Sí." label="Sí." />
              <Radio disabled={disabled} value="No." label="No." />
            </Radio.Group>
          </Grid.Col>
          {form.values.cad_001 === "Sí." && (
            <Grid.Col span={12}>
              <RadioYesNoTable
                disabled={disabled}
                title="El comité o la instancia sobre acoso u hostigamiento
                      sexual laboral:"
                questions={[
                  {
                    text: "Tiene una composición paritaria de género.",
                    formValue: "cad_002",
                  },
                  {
                    text: "Parte de sus miembros han sido elegidos por las/os trabajadores/as.",

                    formValue: "cad_003",
                  },
                ]}
                form={form}
              />
            </Grid.Col>
          )}
          <Grid.Col span={12}>
            <Divider />
          </Grid.Col>
          <Grid.Col span={12}>
            <Radio.Group
              label="¿Existe algún canal de denuncias de acoso y hostigamiento sexual laboral?"
              {...form.getInputProps("cad_004")}
              onChange={(value) =>
                onChangeCleanFields(
                  value,
                  "cad_004",
                  ["cad_005", "cad_006_013"],
                  "No.",
                  form
                )
              }
            >
              <Radio disabled={disabled} value="Sí." label="Sí." />
              <Radio disabled={disabled} value="No." label="No." />
            </Radio.Group>
          </Grid.Col>
          {form.values.cad_004 === "Sí." && (
            <>
              <Grid.Col span={12}>
                <Radio.Group
                  label="¿Este canal es manejado por un tercero independiente?"
                  {...form.getInputProps("cad_005")}
                >
                  <Radio disabled={disabled} value="Sí." label="Sí." />
                  <Radio disabled={disabled} value="No." label="No." />
                </Radio.Group>
              </Grid.Col>
              <Grid.Col span={12}>
                <Checkbox.Group
                  orientation="vertical"
                  label="Además del canal de denuncias, ¿hay alguna otra vía autorizada para recibir casos de acoso u hostigamiento sexual laboral?"
                  {...form.getInputProps("cad_006_013")}
                >
                  <Checkbox
                    disabled={disabled}
                    value="cad_006"
                    label="Recursos humanos / Gestión de talento."
                  />
                  <Checkbox
                    disabled={disabled}
                    value="cad_007"
                    label="Jefe/a inmediato."
                  />
                  <Checkbox
                    disabled={disabled}
                    value="cad_008"
                    label="Gerente/a de área."
                  />
                  <Checkbox
                    disabled={disabled}
                    value="cad_009"
                    label="Representante de trabajadores/as."
                  />
                  <Checkbox
                    disabled={disabled}
                    value="cad_010"
                    label="Plataforma web."
                  />
                  <Checkbox
                    disabled={disabled}
                    value="cad_011"
                    label="Línea ética."
                  />
                  <Checkbox
                    disabled={disabled}
                    value="cad_012"
                    label="Comité de Hostigamiento Sexual / Ética / Convivencia."
                  />
                  <Checkbox disabled={disabled} value="cad_013" label="Otro." />
                </Checkbox.Group>
              </Grid.Col>
            </>
          )}
          <Grid.Col span={12}>
            <Divider />
          </Grid.Col>
          <Grid.Col span={12}>
            <Radio.Group
              label="¿Tu organización tiene un procedimiento de investigación que resulte aplicable a los casos de acoso u hostigamiento sexual laboral?"
              {...form.getInputProps("cad_014")}
              onChange={(value) =>
                onChangeCleanFields(
                  value,
                  "cad_014",
                  ["cad_015", "cad_016", "cad_017", "cad_018"],
                  "No.",
                  form
                )
              }
            >
              <Radio disabled={disabled} value="Sí." label="Sí." />
              <Radio disabled={disabled} value="No." label="No." />
            </Radio.Group>
          </Grid.Col>
          {form.values.cad_014 === "Sí." && (
            <Grid.Col span={12}>
              <RadioYesNoTable
                disabled={disabled}
                title="El procedimiento de investigación ¿considera alguna de estos aspectos?"
                questions={[
                  {
                    text: "Informa a las personas que denuncian sobre el resultado de la investigación.",
                    formValue: "cad_015",
                  },
                  {
                    text: "Tiene un plazo máximo de duración.",
                    formValue: "cad_016",
                  },
                  {
                    text: "Es confidencial.",
                    formValue: "cad_017",
                  },
                  {
                    text: "Se toman medidas de protección para la persona denunciante mientras dure el procedimiento (por ejemplo, la rotación o suspensión de la persona denunciada).",
                    formValue: "cad_018",
                  },
                ]}
                form={form}
              />
            </Grid.Col>
          )}
          <Grid.Col span={12}>
            <Divider />
          </Grid.Col>
          <Grid.Col span={12}>
            <Radio.Group
              label="¿Llevan un registro de las denuncias de acoso u hostigamiento sexual recibidas?"
              {...form.getInputProps("cad_019")}
              onChange={(value) =>
                onChangeCleanFields(
                  value,
                  "cad_019",
                  [
                    "cad_020",
                    "cad_021",
                    "cad_022",
                    "cad_023",
                    "cad_024",
                    "cad_025",
                    "cad_026",
                    "cad_027",
                    "cad_028",
                  ],
                  "No.",
                  form
                )
              }
            >
              <Radio disabled={disabled} value="Sí." label="Sí." />
              <Radio disabled={disabled} value="No." label="No." />
            </Radio.Group>
          </Grid.Col>
          {form.values.cad_019 === "Sí." && (
            <Grid.Col>
              {!totalIsGreaterThanOtherCategories() && (
                <FormErrorText>
                  El total de denuncias debe ser mayor que el resto de
                  denuncias.
                </FormErrorText>
              )}
              <TablaDenuncias
                disabled={disabled}
                question="¿Cuántas denuncias de acoso u hostigamiento sexual laboral han recibido anualmente según el género de la persona denunciante?"
                form={form}
                formIds={{
                  hombres: "cad_020",
                  mujeres: "cad_021",
                  noBinario: "cad_022",
                }}
              />
              <TablaDenuncias
                disabled={disabled}
                question="Del total de denuncias recibidas, ¿en cuántas de ellas se encontró que sí había acoso u hostigamiento sexual laboral?"
                form={form}
                formIds={{
                  hombres: "cad_023",
                  mujeres: "cad_024",
                  noBinario: "cad_025",
                }}
              />
              <TablaDenuncias
                disabled={disabled}
                question="Del total de denuncias recibidas, ¿en cuántas de ellas se encontró que habían denuncias falsas?"
                tooltip="Se considera que una denuncia es falsa cuando los hechos son manifiestamente inventados y la denuncia se hace con ánimo de fraude. No es lo mismo que una denuncia que no pudo ser probada."
                form={form}
                formIds={{
                  hombres: "cad_026",
                  mujeres: "cad_027",
                  noBinario: "cad_028",
                }}
              />
            </Grid.Col>
          )}
          <Grid.Col span={12}>
            <Divider />
          </Grid.Col>
          <Grid.Col span={12}>
            <Button onClick={saveValues} disabled={loading || disabled}>
              Guardar
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </GenderLabAppShell>
  );
};

type TablaDenunciasProps = {
  question: string;
  tooltip?: string;
  form: any;
  formIds: {
    hombres: string;
    mujeres: string;
    noBinario: string;
  };
  disabled: boolean;
};

const TablaDenuncias = ({
  question,
  tooltip,
  form,
  formIds,
  disabled,
}: TablaDenunciasProps) => {
  return (
    <Paper p="md" my="md" radius="lg" shadow="xs">
      <Grid>
        <Grid.Col>
          {tooltip ? (
            <Flex>
              <LabelText>{question}</LabelText>
              <Tooltip label={tooltip} multiline width={250}>
                <Button variant="white" color="gray" size="xs" compact>
                  <AiOutlineInfoCircle size={16} />
                </Button>
              </Tooltip>
            </Flex>
          ) : (
            <LabelText>{question}</LabelText>
          )}
        </Grid.Col>
        <Grid.Col>
          <Table>
            <thead>
              <tr>
                <th>Hombres</th>
                <th>Mujeres</th>
                <th>No binario</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <NumberInput
                    disabled={disabled}
                    {...form.getInputProps(formIds.hombres)}
                    min={0}
                  />
                </td>
                <td>
                  <NumberInput
                    disabled={disabled}
                    {...form.getInputProps(formIds.mujeres)}
                    min={0}
                  />
                </td>
                <td>
                  <NumberInput
                    disabled={disabled}
                    {...form.getInputProps(formIds.noBinario)}
                    min={0}
                  />
                </td>
                <td>
                  {parseInt(_.get(form.values, formIds.hombres) || "0") +
                    parseInt(_.get(form.values, formIds.mujeres) || "0") +
                    parseInt(_.get(form.values, formIds.noBinario) || "0")}
                </td>
              </tr>
            </tbody>
          </Table>
        </Grid.Col>
      </Grid>
    </Paper>
  );
};
