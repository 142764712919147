import { ResultadosCard } from "./ResultadosCard";
import { Flex, Text } from "@mantine/core";
import { BarChart, PieChart } from "../Charts";
import { TasaDeParticipacionTooltip } from "./TasaDeParticipacionTooltip";

type DistrubucionPerfilLaboralProps = {
  title: string;
  barChartData: number[];
  categories: any;
  pieChartData: number[];
};

export const DistribucionPerfilLaboral = ({
  title,
  pieChartData,
  categories,
  barChartData,
}: DistrubucionPerfilLaboralProps) => {
  return (
    <ResultadosCard title={title}>
      <Flex justify="space-evenly" direction={{ base: "column", md: "row" }}>
        <Flex direction="column" gap="md">
          <Text align="center" size="sm" fz={16} fw={500}>
            Distribución de respuestas por {title.toLowerCase()}
          </Text>
          <PieChart labels={categories} series={pieChartData} height={400} />
        </Flex>
        <Flex direction="column" gap="md">
          <TasaDeParticipacionTooltip
            title={`Tasa de participación por ${title.toLowerCase()}`}
          />
          <BarChart
            width={400}
            height={300}
            categories={categories}
            max={150}
            seriesNames={["Personas (%)"]}
            seriesData={[barChartData]}
          />
        </Flex>
      </Flex>
    </ResultadosCard>
  );
};
