import { Route, Routes } from "react-router-dom";

import {
  LoginPage,
  InicioPage,
  EncuestaPoliticas,
  EncuestaCanalDeDenuncias,
  EncuestaCapacitaciones,
  EncuestaBuenasPracticas,
  MedicionesPage,
  PlanDeAccionPage,
  BuzonDeDenunciasPage,
  EducacionPage,
  MiCuentaPage,
  MedicionPage,
  DistribucionPage,
  CursoCompradoPage,
  NotFoundPage,
  CursoDisponiblePage,
  RedElsa,
  ClasesEnVivoPage,
  CertificadoWebinarPage,
  ResultadosBase,
  CertificadoAulaPage,
  CertificadoAlumnoAulaPage,
  ReestablecerContrasenaPage,
  LinkRegistroPage,
} from "./pages";
import { PersonalizacionPage } from "./pages/MedicionActualPages";
import { ProtectedRoute } from "./utils";

import "./styles/styles.css";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />}></Route>
      <Route
        path="inicio"
        element={<ProtectedRoute children={<InicioPage />} />}
      />
      <Route
        path="mi-cuenta"
        element={<ProtectedRoute children={<MiCuentaPage />} />}
      />
      <Route
        path="mediciones"
        element={<ProtectedRoute children={<MedicionesPage />} />}
      />
      <Route path="medicion">
        <Route path=":code">
          <Route
            index
            element={<ProtectedRoute children={<MedicionPage />} />}
          />
          <Route
            path="distribucion"
            element={<ProtectedRoute children={<DistribucionPage />} />}
          />
          <Route
            path="personalizacion"
            element={<ProtectedRoute children={<PersonalizacionPage />} />}
          />
          <Route path="encuesta-organizacional">
            <Route
              path="politicas"
              element={<ProtectedRoute children={<EncuestaPoliticas />} />}
            />
            <Route
              path="canal-de-denuncias"
              element={
                <ProtectedRoute children={<EncuestaCanalDeDenuncias />} />
              }
            />
            <Route
              path="capacitaciones"
              element={<ProtectedRoute children={<EncuestaCapacitaciones />} />}
            />
            <Route
              path="buenas-practicas"
              element={
                <ProtectedRoute children={<EncuestaBuenasPracticas />} />
              }
            />
          </Route>
        </Route>
      </Route>
      <Route path="resultados">
        <Route
          index
          element={<ProtectedRoute children={<ResultadosBase />} />}
        />
        <Route path=":code">
          <Route
            path=":tab"
            element={<ProtectedRoute children={<ResultadosBase />} />}
          />
        </Route>
      </Route>
      <Route path="plan-de-accion">
        <Route
          index
          element={<ProtectedRoute children={<PlanDeAccionPage />} />}
        />
        <Route path=":code">
          <Route
            index
            element={<ProtectedRoute children={<PlanDeAccionPage />} />}
          />
        </Route>
      </Route>
      <Route
        path="red-elsa"
        element={<ProtectedRoute children={<RedElsa />} />}
      />
      <Route path="aula-virtual">
        <Route
          index
          element={<ProtectedRoute children={<EducacionPage />} />}
        />
        <Route
          path="cursos-activos/:code"
          element={<ProtectedRoute children={<CursoCompradoPage />} />}
        />
        <Route
          path="cursos-disponibles/:code"
          element={<ProtectedRoute children={<CursoDisponiblePage />} />}
        />
        <Route
          path="cursos-anteriores/:code"
          element={<ProtectedRoute children={<CursoCompradoPage />} />}
        />
      </Route>
      <Route path="clases-en-vivo">
        <Route
          index
          element={<ProtectedRoute children={<ClasesEnVivoPage />} />}
        />
      </Route>
      <Route
        path="buzon-de-denuncias"
        element={<ProtectedRoute children={<BuzonDeDenunciasPage />} />}
      />
      <Route path="certificado-webinar">
        <Route path=":code">
          <Route index element={<CertificadoWebinarPage />} />
        </Route>
      </Route>
      <Route path="certificado-aula">
        <Route path=":code">
          <Route index element={<CertificadoAulaPage />} />
        </Route>
      </Route>
      <Route path="certificado-alumno-aula">
        <Route path=":estudiante/:curso">
          <Route index element={<CertificadoAlumnoAulaPage />} />
        </Route>
      </Route>
      <Route
        path="reestablecer-contrasena/:uidb64/:token"
        element={<ReestablecerContrasenaPage />}
      />
      <Route path="link-registro/:token" element={<LinkRegistroPage />} />
      <Route
        path="*"
        element={<ProtectedRoute children={<NotFoundPage />} />}
      />
    </Routes>
  );
}
