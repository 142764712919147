import { Flex, createStyles } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons";

type FilaChecklistProps = {
  title: string;
  checked: boolean;
};
const useStyles = createStyles(() => ({
  container: {
    maxWidth: 700,
  },
}));

export const FilaChecklistPrevencion = ({
  title,
  checked,
}: FilaChecklistProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <Flex gap="xs" align="center">
        <div style={{ flexShrink: 0 }}>
          {checked ? (
            <IconCheck size={20} color="green" />
          ) : (
            <IconX size={20} color="red" />
          )}
        </div>
        {title}
      </Flex>
    </div>
  );
};
