import { Flex, Grid, Tabs, Text } from "@mantine/core";
import { TLeadership } from "../../types";
import {
  BarChart,
  GenderLabLoader,
  PieChart,
  PorcentajeCardSmall,
  ResultadosCard,
  SectionTitle,
  TarjetaCaso,
  TitleCard,
} from "../../components";
import useResponsiveTwoColWidth from "../../hooks/useResponsiveTwoColWidth";
import { colorAzul1, colorCeleste1, parsePercentage } from "../../utils";
import { useMetrics } from "../../context";

type Props = {
  leadership: TLeadership | null;
};

export const LiderazgoResultados = ({ leadership }: Props) => {
  const twoColWidth = useResponsiveTwoColWidth("md");

  const { regionalBenchmark, countryBenchmark } = useMetrics();

  if (!leadership) {
    return <GenderLabLoader />;
  } else {
    return (
      <Flex direction="column" gap="md">
        <SectionTitle
          title="Liderazgo"
          description="Nivel de participación, confianza y percepción de las personas líderes de tu empresa frente al hostigamiento o acoso sexual laboral."
        />
        <ResultadosCard
          title="Tasa de participación de líderes"
          description="Porcentaje de líderes encuestados/as en relación al total de líderes reportados/as en la encuesta organizacional."
        >
          <Flex justify="center" align="center">
            <BarChart
              xaxisTitle="Participación (%)"
              max={100}
              categories={Object.keys(leadership.participation)}
              responsive={[
                {
                  breakpoint: 1200,
                  options: {
                    chart: {
                      width: 650,
                    },
                  },
                },
                {
                  breakpoint: 768,
                  options: {
                    chart: {
                      width: 250,
                    },
                  },
                },
              ]}
              seriesNames={["Participación (%)"]}
              seriesData={[
                Object.keys(leadership.participation).map(
                  (key) => leadership.participation[key].progress
                ),
              ]}
              width={700}
              height={400}
            />
          </Flex>
        </ResultadosCard>
        <Flex direction={{ base: "column", md: "row" }} gap="sm">
          <Flex sx={{ width: twoColWidth }}>
            <ResultadosCard
              title="Frecuencia del acoso sexual según líderes"
              description="Frecuencia con la que los y las líderes creen que suceden casos en su empresa."
            >
              <Flex
                direction="column"
                justify="center"
                align="center"
                mih={300}
                gap="sm"
              >
                <PieChart
                  labels={[
                    "Con muy baja frecuencia",
                    "Con poca frecuencia",
                    "Con algo de frecuencia",
                    "Con mucha frecuencia",
                  ]}
                  series={[
                    parsePercentage(leadership.perception.level_1),
                    parsePercentage(leadership.perception.level_2),
                    parsePercentage(leadership.perception.level_3),
                    parsePercentage(leadership.perception.level_4),
                  ]}
                  height={320}
                />
              </Flex>
            </ResultadosCard>
          </Flex>
          <Flex sx={{ width: twoColWidth }}>
            <ResultadosCard
              title="Confianza en jefe/as"
              description="Porcentaje de casos de hostigamiento o acoso sexual laboral reportados."
            >
              <Flex
                align="center"
                justify="center"
                mih={300}
                direction="column"
              >
                <PorcentajeCardSmall
                  percentage={leadership.confidence}
                  description="Habló con su jefe/a directo/a."
                  color={colorAzul1}
                />
                <PorcentajeCardSmall
                  percentage={1 - leadership.non_reporting_percentage}
                  description="Denunció la situación y siguió el proceso de investigación."
                  color={colorCeleste1}
                />
              </Flex>
            </ResultadosCard>
          </Flex>
        </Flex>
        <TitleCard title="Resolución de casos de hostigamiento o acoso sexual laboral" />
        <Flex
          direction={{ base: "column", md: "row" }}
          justify="space-evenly"
          gap="md"
        >
          <Flex>
            <ResultadosCard title="¿De qué trata esta sección?">
              <Text>
                La resolución de casos de hostigamiento sexual laboral nos
                permite identificar si las personas cuentan con las herramientas
                analíticas para resolverlos. En esta sección encontramos 4 casos
                de situaciones típicas de hostigamiento o acoso sexual laboral
                para que las personas respondan 4 preguntas que nos permitirán
                identificar dos aspectos relevantes: 1) Si identifican
                situaciones de hostigamiento o acoso sexual laboral; 2) El
                impacto de sesgos inconscientes a la hora de resolver casos.
              </Text>
            </ResultadosCard>
          </Flex>
          <Flex>
            <ResultadosCard title="¿Quiénes llegaron a esta sección?">
              <Text>
                Esta sección le aparece a líderes/as de la organización (Niveles
                1,2 y 3), al personal de Recursos Humanos/Gestión del Talento, y
                a las personas integrantes del Comité de Intervención, de
                Convivencia, de Ética, o afín. Al ser personas que se encuentran
                vinculadas con los casos que puedan ocurrir dentro de la
                organización, es importante medir si están preparadas para
                abordarlos, bien sea desde el liderazgo, o desde las instancias
                que reciben y resuelven los casos de hostigamiento o acoso
                sexual.
              </Text>
            </ResultadosCard>
          </Flex>
          <Flex>
            <ResultadosCard title="¿Cuál fue la metodología utilizada?">
              <Text>
                La sección muestra 4 casos típicos de hostigamiento o acoso
                sexual laboral. Para poder medir el impacto de sesgos
                inconscientes en la resolución de estos casos se trabajaron
                casos base (sin activador de sesgos inconscientes) y casos
                gemelos (con activadores de sesgos inconscientes). ¿Qué son los
                activadores de sesgos inconscientes? Son frases o palabras que
                refuerzan ideas o estereotipos de género y que al ser
                introducidas en un caso de hostigamiento o acoso sexual laboral
                activan los sesgos inconscientes que tenemos.
              </Text>
            </ResultadosCard>
          </Flex>
        </Flex>
        <ResultadosCard title="Casos">
          <Tabs defaultValue="hostigamiento-homofobico">
            <Tabs.List grow>
              <Tabs.Tab value="hostigamiento-homofobico">
                Hostigamiento Homofóbico
              </Tabs.Tab>
              <Tabs.Tab value="ambiente-hostil">Ambiente Hostil</Tabs.Tab>
              <Tabs.Tab value="chantaje-sexual">Chantaje Sexual</Tabs.Tab>
              <Tabs.Tab value="hostigamiento-horizontal">
                Hostigamiento Horizontal
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="hostigamiento-homofobico" pt="xs">
              <TarjetaCaso
                description="Este caso presenta comentarios y “bromas” que tienen como finalidad burlarse de la orientación sexual asumida de la persona denunciante. Estas son manifestaciones de hostigamiento o acoso sexual homofóbico por sexismo."
                caseResolution={
                  leadership.case_resolution.case_001_case_resolution
                }
                regionalBenchmark={regionalBenchmark?.benchmarks.RB_0035}
                countryBenchmark={countryBenchmark?.benchmarks.CB_0035}
                naturalHeterosexuality={
                  leadership.natural_heterosexuality.case_001
                }
                exacerbatedIntent={leadership.exacerbated_intent.case_001}
                victimResponsibility={leadership.victim_responsibility.case_001}
                overreaction={leadership.overreaction.case_001}
                ulteriorMotives={leadership.ulterior_motives.case_001}
              />
            </Tabs.Panel>
            <Tabs.Panel value="ambiente-hostil" pt="xs">
              <TarjetaCaso
                description="Este caso presenta comentarios a viva voz sobre encuentros sexuales con colegas y otras mujeres y la sintonización de programas de entretenimiento sexualmente explícitos que generan un ambiente hostil."
                caseResolution={
                  leadership.case_resolution.case_002_case_resolution
                }
                regionalBenchmark={regionalBenchmark?.benchmarks.RB_0036}
                countryBenchmark={countryBenchmark?.benchmarks.CB_0036}
                naturalHeterosexuality={
                  leadership.natural_heterosexuality.case_002
                }
                exacerbatedIntent={leadership.exacerbated_intent.case_002}
                victimResponsibility={leadership.victim_responsibility.case_002}
                overreaction={leadership.overreaction.case_002}
                ulteriorMotives={leadership.ulterior_motives.case_002}
              />
            </Tabs.Panel>
            <Tabs.Panel value="chantaje-sexual" pt="xs">
              <TarjetaCaso
                description="Este caso presenta chantaje sexual a causa de que un jefe invita a salir, de manera reiterada, a su subordinada. El cese de la relación sexual resulta en el despido de la persona subordinada."
                caseResolution={
                  leadership.case_resolution.case_003_case_resolution
                }
                regionalBenchmark={regionalBenchmark?.benchmarks.RB_0037}
                countryBenchmark={countryBenchmark?.benchmarks.CB_0037}
                naturalHeterosexuality={
                  leadership.natural_heterosexuality.case_003
                }
                exacerbatedIntent={leadership.exacerbated_intent.case_003}
                victimResponsibility={leadership.victim_responsibility.case_003}
                overreaction={leadership.overreaction.case_003}
                ulteriorMotives={leadership.ulterior_motives.case_003}
              />
            </Tabs.Panel>
            <Tabs.Panel value="hostigamiento-horizontal" pt="xs">
              <TarjetaCaso
                description="Este caso presenta reiteradas invitaciones a salir que buscan establecer una relación más allá de lo laboral y profesional a pesar del rechazo de la persona denunciante."
                caseResolution={
                  leadership.case_resolution.case_004_case_resolution
                }
                regionalBenchmark={regionalBenchmark?.benchmarks.RB_0038}
                countryBenchmark={countryBenchmark?.benchmarks.CB_0038}
                naturalHeterosexuality={
                  leadership.natural_heterosexuality.case_004
                }
                exacerbatedIntent={leadership.exacerbated_intent.case_004}
                victimResponsibility={leadership.victim_responsibility.case_004}
                overreaction={leadership.overreaction.case_004}
                ulteriorMotives={leadership.ulterior_motives.case_004}
              />
            </Tabs.Panel>
          </Tabs>
          <Grid px="sm" pt="sm">
            <Grid.Col span={12} md={4}>
              <Text fw={600} color="dimmed" fz="xs">
                Responsabilidad de la víctima
              </Text>
              <Text color="dimmed" fz="xs">
                Tendencia a poner excesiva atención en la reacción de la persona
                a la que se dirigen las manifestaciones. Se tiende a buscar un
                rechazo expreso y fuerte para validar la existencia del acoso
                sexual.
              </Text>
            </Grid.Col>
            <Grid.Col span={12} md={4}>
              <Text fw={600} color="dimmed" fz="xs">
                Heterosexualidad naturalizada
              </Text>
              <Text color="dimmed" fz="xs">
                Tendencia a considerar normales o aceptables ciertas conductas
                bajo el argumento 'los hombres son así' o al dar por sentado
                estereotipos de género como que a las mujeres les gusta recibir
                atención de los hombres.
              </Text>
            </Grid.Col>
            <Grid.Col span={12} md={4}>
              <Text fw={600} color="dimmed" fz="xs">
                Exageración
              </Text>
              <Text color="dimmed" fz="xs">
                Tendencia a pensar que las personas que denuncian el acoso están
                inventando o exagerando las situaciones denunciadas. Esto se
                debe a que se tiende a identificar lo sucedido sólo con las
                manifestaciones más graves.
              </Text>
            </Grid.Col>
            <Grid.Col span={12} md={6}>
              <Text fw={600} color="dimmed" fz="xs">
                Motivos ulteriores
              </Text>
              <Text color="dimmed" fz="xs">
                Tendencia a pensar que las personas que denuncian conductas lo
                hacen con algún motivo adicional, sea obtener beneficios
                laborales o conseguir la renovación de contrato.
              </Text>
            </Grid.Col>
            <Grid.Col span={12} md={6}>
              <Text fw={600} color="dimmed" fz="xs">
                Intencionalidad exacerbada
              </Text>
              <Text color="dimmed" fz="xs">
                Tendencia a exculpar a la persona que realiza el hostigamiento o
                acoso sexual laboral por la ausencia de intencionalidad en su
                comportamiento.
              </Text>
            </Grid.Col>
          </Grid>
        </ResultadosCard>
      </Flex>
    );
  }
};
