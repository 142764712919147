import { Flex, Tabs, Text } from "@mantine/core";
import { useEffect, useState } from "react";

import {
  ClaseCard,
  CursoCarousel,
  EducacionTabs,
  GenderLabAppShell,
  GenderLabBreadcrumbs,
  GenderLabLoader,
} from "../../components";
import { TCourse } from "../../types";
import { aulaService } from "../../api/";
import { useAuth } from "../../context";

export const EducacionPage = () => {
  const [availableCourses, setAvailableCourses] = useState<TCourse[]>(null!);
  const [activeCourses, setActiveCourses] = useState<TCourse[]>(null!);

  const { user } = useAuth();

  useEffect(() => {
    const fetchAulaCourses = async () => {
      if (user) {
        try {
          const availableAndActiveCoursesResponse =
            await aulaService.getActiveAndAvailableCourses();
          setAvailableCourses(
            availableAndActiveCoursesResponse.data.available_courses
          );

          if (user.hasAULA || user.hasExpiredAULA) {
            setActiveCourses(
              availableAndActiveCoursesResponse.data.active_courses
            );
          } else {
            setActiveCourses([]);
          }
        } catch {
          setAvailableCourses([]);
          setActiveCourses([]);
        }
      }
    };

    fetchAulaCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const extractYear = (date: string) => {
    return date.split("-")[0];
  };

  return (
    <GenderLabAppShell>
      <GenderLabBreadcrumbs
        breadcrumbs={[{ link: "", title: "Aula Virtual" }]}
      />
      <EducacionTabs defaultValue="disponibles">
        <Tabs.List>
          <Tabs.Tab value="disponibles">Disponibles</Tabs.Tab>
          <Tabs.Tab value="completados">Completados</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="disponibles" pt="sm">
          {availableCourses && activeCourses && user ? (
            <>
              {activeCourses.map((course, idx) => (
                <Flex direction="column" gap="lg" mt="md" key={idx}>
                  <ClaseCard
                    aulaVirtual
                    title={course.name}
                    year={extractYear(course.release_date)}
                    description={course.description}
                    img={course.img_url}
                    id={course.id}
                    type="purchased"
                    syllabus={course.syllabus}
                    courseId={course.id}
                  />
                </Flex>
              ))}
              {availableCourses.map((course, idx) => (
                <Flex direction="column" gap="lg" mt="md" key={idx}>
                  <ClaseCard
                    aulaVirtual
                    title={course.name}
                    year={extractYear(course.release_date)}
                    description={course.description}
                    img={course.img_url}
                    type="available"
                    syllabus={course.syllabus}
                  />
                </Flex>
              ))}
              {activeCourses.length === 0 && availableCourses.length === 0 && (
                <Text color="dimmed">
                  No cuentas con cursos en esta sección.
                </Text>
              )}
            </>
          ) : (
            <GenderLabLoader />
          )}
        </Tabs.Panel>

        <Tabs.Panel value="completados" pt="sm">
          <CursoCarousel courses={[]} type="inactive" />
        </Tabs.Panel>
      </EducacionTabs>
    </GenderLabAppShell>
  );
};
