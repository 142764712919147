import { Center, Container, Tabs, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { webinarService } from "../../api";
import { CertificateData } from "../../types";
import { CertificadoShell, GenderLabLoader } from "../../components";

export const CertificadoWebinarPage = () => {
  const { code } = useParams();

  const [certificateData, setCertificateData] =
    useState<CertificateData | null>(null);
  const [attendeesPdf, setAttendeesPdf] = useState<string | undefined>(
    undefined
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getCertificateData = async () => {
      if (code) {
        try {
          const certificateResponse = await webinarService.getCertificate(code);
          setCertificateData(certificateResponse.data);
        } catch (error: any) {
          setErrorMessage(error.message);
        }
      }
    };

    const getAttendeesPdf = async () => {
      if (code) {
        try {
          const attendeesPdfResponse = await webinarService.getAttendeesPDF(
            code
          );
          const blob = new Blob([attendeesPdfResponse.data], {
            type: "application/pdf",
          });

          setAttendeesPdf(URL.createObjectURL(blob));
        } catch (error: any) {}
      }
    };

    const getData = async () => {
      setLoading(true);
      await getCertificateData();
      await getAttendeesPdf();
      setLoading(false);
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  if (loading) {
    return <GenderLabLoader />;
  } else if (errorMessage) {
    return <Container w={900}>{errorMessage}</Container>;
  }

  return (
    <Tabs defaultValue="certificado">
      <Tabs.List>
        <Tabs.Tab value="certificado">Certificado</Tabs.Tab>
        <Tabs.Tab value="asistentes" disabled={!certificateData?.attendees}>
          Asistentes
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="certificado">
        {certificateData && (
          <CertificadoShell
            nombreEmpresa={certificateData.client_trade_name}
            nombreCurso={certificateData.webinar_title}
            fecha={certificateData.latest_session_date}
          >
            Por haber realizado el entrenamiento{" "}
            <b>"{certificateData.webinar_title}"</b>, con la participación de su
            personal el día {certificateData.latest_session_date}, con una
            duración de {certificateData.webinar_duration} hora(s).
          </CertificadoShell>
        )}
      </Tabs.Panel>

      <Tabs.Panel value="asistentes">
        {attendeesPdf ? (
          <Center my="md">
            <iframe
              title="asistentes-webinar"
              src={attendeesPdf}
              width="100%"
              height="700px"
            />
          </Center>
        ) : (
          <Text my="md">No hay asistentes</Text>
        )}
      </Tabs.Panel>
    </Tabs>
  );
};
