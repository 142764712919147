import { createContext, useContext } from "react";
import { useCookies } from "react-cookie";
import { TMedicion } from "../types";

type MedicionActualContextProps = {
  medicionActualCookie: TMedicion | null;
  setMedicionActualCookie: (medicionActual: TMedicion) => void;
};

const MedicionActualContext = createContext<MedicionActualContextProps>(null!);

type Props = {
  children: React.ReactNode;
};

const MedicionActualProvider = ({ children }: Props) => {
  const [cookies, setCookie] = useCookies(["medicionActual"]);

  const medicionActualCookie: TMedicion | null = cookies?.medicionActual;

  const setMedicionActualCookie = (medicionActual: TMedicion) => {
    setCookie("medicionActual", medicionActual, { path: "/" });
  };

  return (
    <MedicionActualContext.Provider
      value={{ medicionActualCookie, setMedicionActualCookie }}
    >
      {children}
    </MedicionActualContext.Provider>
  );
};

export const useMedicionActual = () => useContext(MedicionActualContext);
export { MedicionActualContext, MedicionActualProvider };
