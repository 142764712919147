export const indexColors = {
  inicial: "#e69138",
  intermedio: "#f6b26b",
  intermedioAlto: "#f1c232",
  alto: "#afe057",
  muyAlto: "#50D737",
};

export const determineIndexColor = (score: number) => {
  if (score <= 60) {
    return {
      text: "Inicial",
      color: indexColors.inicial,
    };
  } else if (score <= 70) {
    return {
      text: "Intermedio",
      color: indexColors.intermedio,
    };
  } else if (score <= 80) {
    return {
      text: "Intermedio Alto",
      color: indexColors.intermedioAlto,
    };
  } else if (score <= 90) {
    return {
      text: "Alto",
      color: indexColors.alto,
    };
  } else {
    return {
      text: "Muy Alto",
      color: indexColors.muyAlto,
    };
  }
};
