import { Flex, Sx, Text, Tooltip, createStyles } from "@mantine/core";
import {
  colorAmarillo1,
  colorRojoClaro1,
  colorVerdeAgua1,
  parsePercentage,
} from "../../utils";
import { useMetrics } from "../../context";

type Props = {
  percentage: number;
  description: string;
  sx?: Sx | (Sx | undefined)[] | undefined;
};

const useStyles = createStyles((theme) => ({
  cardContainer: {
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: "10px",
  },
  cardPorcentaje: {
    minWidth: 80,
    width: 65,
    fontSize: 20,
    fontWeight: 600,
  },
  cardTusResultados: {
    backgroundColor: theme.colors.gray[1],
    color: theme.colors.gray[7],
  },
  cardPorcentajeBenchmarkRegional: {
    color: "#3b0808",
    backgroundColor: colorRojoClaro1,
  },
  cardPorcentajeBenchmarkPais: {
    color: "#224f4d",
    backgroundColor: colorVerdeAgua1,
  },
  cardMedicionAnterior: {
    color: "#543f18",
    backgroundColor: colorAmarillo1,
  },
  cardBordeRedondo: {
    ":last-child": {
      borderRadius: "0 10px 10px 0",
    },
  },
}));

export const PorcentajeCardLargeBenchmark = ({
  percentage,
  description,
  sx,
}: Props) => {
  const { classes } = useStyles();

  const textToRegionalBenchmarkCode: {
    [key: string]:
      | "RB_0021"
      | "RB_0022"
      | "RB_0023"
      | "RB_0024"
      | "RB_0025"
      | "RB_0026"
      | "RB_0027"
      | "RB_0028";
  } = {
    "Generó una distracción (por ejemplo, cambió el tema de conversación).":
      "RB_0021",
    "No intervino porque no supo qué hacer en ese momento.": "RB_0022",
    "Confrontó a la persona que estaba haciendo estas conductas.": "RB_0023",
    "En ese momento no intervino pero luego se acercó a quien incurrió en acoso u hostigamiento.":
      "RB_0024",
    "En ese momento no intervino pero luego se acercó a la persona para ver cómo estaba.":
      "RB_0025",
    "Solo guardó un registro de lo sucedido (por ejemplo, guardó los chats).":
      "RB_0026",
    "No le pareció necesario intervenir.": "RB_0027",
    "Informó de lo sucedido a recursos humanos o a su jefe/a.": "RB_0028",
  };

  const textToCountryBenchmarkCode: {
    [key: string]:
      | "CB_0021"
      | "CB_0022"
      | "CB_0023"
      | "CB_0024"
      | "CB_0025"
      | "CB_0026"
      | "CB_0027"
      | "CB_0028";
  } = {
    "Generó una distracción (por ejemplo, cambió el tema de conversación).":
      "CB_0021",
    "No intervino porque no supo qué hacer en ese momento.": "CB_0022",
    "Confrontó a la persona que estaba haciendo estas conductas.": "CB_0023",
    "En ese momento no intervino pero luego se acercó a quien incurrió en acoso u hostigamiento.":
      "CB_0024",
    "En ese momento no intervino pero luego se acercó a la persona para ver cómo estaba.":
      "CB_0025",
    "Solo guardó un registro de lo sucedido (por ejemplo, guardó los chats).":
      "CB_0026",
    "No le pareció necesario intervenir.": "CB_0027",
    "Informó de lo sucedido a recursos humanos o a su jefe/a.": "CB_0028",
  };

  const textToPreviousMetricsCode: {
    [key: string]:
      | "TEM_0001"
      | "TEM_0002"
      | "TEM_0003"
      | "TEM_0004"
      | "TEM_0005"
      | "TEM_0006"
      | "TEM_0007"
      | "TEM_0008";
  } = {
    "Generó una distracción (por ejemplo, cambió el tema de conversación).":
      "TEM_0002",
    "No intervino porque no supo qué hacer en ese momento.": "TEM_0001",
    "Confrontó a la persona que estaba haciendo estas conductas.": "TEM_0007",
    "En ese momento no intervino pero luego se acercó a quien incurrió en acoso u hostigamiento.":
      "TEM_0004",
    "En ese momento no intervino pero luego se acercó a la persona para ver cómo estaba.":
      "TEM_0003",
    "Solo guardó un registro de lo sucedido (por ejemplo, guardó los chats).":
      "TEM_0006",
    "No le pareció necesario intervenir.": "TEM_0008",
    "Informó de lo sucedido a recursos humanos o a su jefe/a.": "TEM_0005",
  };

  const { regionalBenchmark, countryBenchmark, previousMetrics } = useMetrics();

  return (
    <Flex gap="xs" className={classes.cardContainer}>
      <Text px="xs" size="sm" py="xs" sx={{ flexGrow: 1 }}>
        {description}
      </Text>
      <Flex
        className={`${classes.cardPorcentaje} ${classes.cardTusResultados} ${classes.cardBordeRedondo}`}
        justify="center"
        align="center"
        sx={sx}
      >
        {parsePercentage(percentage)}%
      </Flex>
      {regionalBenchmark && (
        <Flex
          className={`${classes.cardPorcentaje} ${classes.cardPorcentajeBenchmarkRegional} ${classes.cardBordeRedondo}`}
          justify="center"
          align="center"
        >
          <>
            {parsePercentage(
              regionalBenchmark.benchmarks[
                textToRegionalBenchmarkCode[description]
              ]
            )}
            %
          </>
        </Flex>
      )}
      {countryBenchmark && (
        <Flex
          className={`${classes.cardPorcentaje} ${classes.cardPorcentajeBenchmarkPais} ${classes.cardBordeRedondo}`}
          justify="center"
          align="center"
        >
          <>
            {parsePercentage(
              countryBenchmark.benchmarks[
                textToCountryBenchmarkCode[description]
              ]
            )}
            %
          </>
        </Flex>
      )}
      {previousMetrics && (
        <Flex
          className={`${classes.cardPorcentaje} ${classes.cardMedicionAnterior} ${classes.cardBordeRedondo}`}
          justify="center"
          align="center"
        >
          <>
            {previousMetrics.metrics[textToPreviousMetricsCode[description]] ? (
              `${parsePercentage(
                previousMetrics.metrics[
                  textToPreviousMetricsCode[description]
                ] || 0
              )}%`
            ) : (
              <Tooltip
                label="No disponible. Esta situación no fue priorizada en el 2022. No te preocupes, ¡Tendrás disponible esta comparación en tu siguiente medición!"
                w={300}
                multiline
              >
                <Text>N.D.</Text>
              </Tooltip>
            )}
          </>
        </Flex>
      )}
    </Flex>
  );
};
