import { ApiWithToken } from "../axios-instance";

export const abstractPersonalization = (personalizationAspect: string) => {
  return {
    uri: (code: string | undefined) =>
      `/api/measurement_process/${code}/staff_survey/${personalizationAspect}/`,
    get: async (code: string | undefined) =>
      ApiWithToken.get(
        abstractPersonalization(personalizationAspect).uri(code)
      ),
    post: async (code: string | undefined, data: any) =>
      ApiWithToken.post(
        abstractPersonalization(personalizationAspect).uri(code),
        data
      ),
    put: async (code: string | undefined, id: number, data: any) =>
      ApiWithToken.put(
        abstractPersonalization(personalizationAspect).uri(code),
        data,
        {
          params: {
            id: id,
          },
        }
      ),
    delete: async (code: string | undefined, id: number) =>
      ApiWithToken.delete(
        abstractPersonalization(personalizationAspect).uri(code),
        {
          params: {
            id: id,
          },
        }
      ),
  };
};

export const abstractOrganizationalSurvey = (section: string) => {
  return {
    uri: (code: string | undefined) =>
      `/api/measurement_process/${code}/organizational_survey/${section}/`,
    get: async (code: string | undefined) =>
      ApiWithToken.get(abstractOrganizationalSurvey(section).uri(code)),
    put: async (code: string | undefined, data: any) =>
      ApiWithToken.put(abstractOrganizationalSurvey(section).uri(code), data),
  };
};
