import {
  Text,
  Title,
  Grid,
  Card,
  Image,
  Space,
  Center,
  Paper,
  Stack,
  Flex,
} from "@mantine/core";
import ContentApi from "@tryghost/content-api";
import { useEffect, useState } from "react";

import { useAuth } from "../../context/AuthContext";
import "./InicioPage.css";
import { tokenService } from "../../api";
import {
  ClientSelector,
  CustomLink,
  GenderLabAppShell,
  GenderLabBreadcrumbs,
  GenderLabLoader,
} from "../../components";

type BlogEntryProps = {
  post: any;
};

const BlogEntry = ({ post }: BlogEntryProps) => {
  const date = new Date(post.published_at);
  return (
    <Card radius="md" withBorder>
      <Card.Section>
        <Image src={post.feature_image} alt={post.title} height={150} />
      </Card.Section>
      <Space h="sm" />
      <Stack align="center" justify="center" h={140}>
        <CustomLink to={post.url} target="_blank" fontSize={16} ellipsis>
          {post.title}
        </CustomLink>
        <Text size="xs" color="dimmed" className="ellipsis">
          {post.excerpt}
        </Text>
        <Text size="xs" color="dimmed">
          {post.reading_time} min • {date.getDay()}/{date.getMonth()}/
          {date.getFullYear()}
        </Text>
      </Stack>
    </Card>
  );
};

export const InicioPage = () => {
  const [posts, setPosts] = useState<any>(null!);
  const { user } = useAuth();

  useEffect(() => {
    const api = new ContentApi({
      url: "https://blog.elsa.la",
      key: "1ac06945d39473b608fe76f670",
      version: "v3.0",
    });

    const getPosts = async () => {
      const posts = await api.posts.browse();
      posts.splice(3);
      setPosts(posts);
    };

    getPosts();
  }, []);

  return (
    <GenderLabAppShell>
      <Flex gap="md" align="center">
        <GenderLabBreadcrumbs breadcrumbs={[{ link: "", title: "Inicio" }]} />
        {tokenService.getUserFromToken()?.is_superuser && <ClientSelector />}
      </Flex>
      {!user ? (
        <GenderLabLoader />
      ) : (
        <Grid>
          <Grid.Col span={12}>
            <Paper radius="md" withBorder p="md">
              <Title order={3}>¡Hola, {user.tradeName}!</Title>
              <Text size="md" color="dimmed" mt="sm">
                Te damos la bienvenida a ELSA (Espacios Laborales Sin Acoso).
                Aquí te acompañaremos en el camino a prevenir y tomar acción
                frente al acoso sexual laboral en tu organización.
              </Text>
            </Paper>
          </Grid.Col>
          {posts && (
            <>
              <Grid.Col span={12} mt="xl">
                <Text>
                  ¿Ya leíste nuestro blog{" "}
                  <CustomLink
                    to="https://blog.elsa.la"
                    target="_blank"
                    fontSize="14"
                  >
                    #MultiplicaIgualdad
                  </CustomLink>
                  ? Aquí los últimos artículos:
                </Text>
                <Space h="md" />
                <Grid>
                  {posts.map((post: any) => (
                    <Grid.Col key={post.id} span={12} lg={4}>
                      <BlogEntry post={post} />
                    </Grid.Col>
                  ))}
                </Grid>
                <Center mt="md">
                  <CustomLink
                    to="https://blog.elsa.la"
                    target="_blank"
                    fontSize="14"
                  >
                    Ver más ➔
                  </CustomLink>
                </Center>
              </Grid.Col>
            </>
          )}
        </Grid>
      )}
    </GenderLabAppShell>
  );
};
