import { Button } from "@mantine/core";
import { BiTrash } from "react-icons/bi";
import { useAuth } from "../../../context";

type EliminarButtonProps = {
  onClick: () => void;
  disabled?: boolean;
};

export const EliminarButton = ({ onClick, disabled }: EliminarButtonProps) => {
  const { loading } = useAuth();
  return (
    <Button
      compact
      size="xs"
      color="red"
      onClick={onClick}
      variant="outline"
      disabled={loading || disabled}
    >
      <BiTrash size={20} />
    </Button>
  );
};
