import { ApiWithToken } from "../axios-instance";

export const passwordService = {
  forgotPassword: async (email: string) =>
    ApiWithToken.post("api/password-reset/", { email: email }),
  resetPassword: async (password: string, uid: string, token: string) =>
    ApiWithToken.post(`api/password-reset/confirm/${uid}/${token}/`, {
      password: password,
    }),
};
