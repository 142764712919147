import { useMantineTheme, MantineNumberSize } from "@mantine/core";

const useResponsiveColWidth = (
  breakpoint: MantineNumberSize,
  width: string
) => {
  const theme = useMantineTheme();

  return theme.fn.smallerThan(breakpoint) ? "100%" : width;
};

export default useResponsiveColWidth;
