import { forwardRef } from "react";
import { Group, Text, Image} from "@mantine/core";

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: string;
  label: string;
}

export const SelectItemWithIcon = forwardRef<HTMLDivElement, ItemProps>(
  ({ image, label, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <Image width={40} src={image} alt={label} />
        {/* <Avatar size="sm" radius="xs" src={image} alt={label} /> TODO: USE AVATAR AND STORE FLAGS IN S3(SERVER STORAGE)*/} 
        <div>
          <Text size="sm">{label}</Text>
        </div>
      </Group>
    </div>
  )
);
