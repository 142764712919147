import { Button, Popover, createStyles } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { AiOutlineInfoCircle } from "react-icons/ai";

const useStyles = createStyles((theme) => ({
  infoButton: {
    "&:hover": {
      backgroundColor: "white",
    },
    margin: 0,
    padding: 0,
  },
}));

type Props = {
  tooltipText: React.ReactNode;
  width?: number;
};

export const InfoTooltip = ({ tooltipText, width = 300 }: Props) => {
  const { classes } = useStyles();
  const [opened, { close, open }] = useDisclosure(false);

  return (
    <Popover width={width} position="bottom" shadow="md" opened={opened}>
      <Popover.Target>
        <Button
          onMouseEnter={open}
          onMouseLeave={close}
          compact
          variant="subtle"
          className={classes.infoButton}
        >
          <AiOutlineInfoCircle />
        </Button>
      </Popover.Target>
      <Popover.Dropdown
        sx={{
          pointerEvents: "none",
        }}
      >
        {tooltipText}
      </Popover.Dropdown>
    </Popover>
  );
};
