import {
  colorAmarillo1,
  colorCeleste1,
  colorGris1,
  colorAzul1,
  colorRojoClaro1,
  colorVerdeAgua1,
  colorMorado1,
  colorAzul2,
  colorAzul3,
  colorCeleste3,
  colorCeleste2,
  colorMorado2,
  colorMorado3,
  colorGris2,
  colorGris3,
  colorAmarillo3,
  colorAmarillo2,
  colorVerdeAgua2,
  colorVerdeAgua3,
  colorRojoClaro2,
  colorRojoClaro3,
} from "./colors";

export const pieChartColors = [
  colorMorado1,
  colorCeleste1,
  colorAzul1,
  colorVerdeAgua1,
  colorAmarillo1,
  colorGris1,
  colorRojoClaro1,

  colorRojoClaro1,
  colorRojoClaro2,
  colorRojoClaro3,

  colorVerdeAgua1,
  colorVerdeAgua2,
  colorVerdeAgua3,

  colorAmarillo1,
  colorAmarillo2,
  colorAmarillo3,

  colorGris1,
  colorGris2,
  colorGris3,

  colorCeleste1,
  colorCeleste2,
  colorCeleste3,

  colorAzul1,
  colorAzul2,
  colorAzul3,

  colorMorado1,
  colorMorado2,
  colorMorado3,

  colorAzul2,
  colorAzul3,
  colorVerdeAgua1,
  colorAzul1,
  colorGris1,
  colorRojoClaro1,
];
