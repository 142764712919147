import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "./ThemeProvider";
import { AuthProvider } from "./context/AuthContext";
import { MetricsProvider, MedicionActualProvider } from "./context";
import { BrowserScrollToTopWrapper } from "./components";

import * as Sentry from "@sentry/react";
import { BASE_URL } from "./api";
import { PageTitleUpdater } from "./pages";

Sentry.init({
  environment: process.env.REACT_APP_ENV,
  dsn: "https://599814a081ce112711c1cb8458f3ad20@o4506032333324288.ingest.sentry.io/4506040625659904",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  tracePropagationTargets: ["localhost", BASE_URL], // This is required for the performance monitoring to work properly. It tells Sentry which domains to trust and not create a new transaction for.
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <CookiesProvider>
    <ThemeProvider>
      <BrowserRouter>
        <BrowserScrollToTopWrapper>
          <AuthProvider>
            <MedicionActualProvider>
              <MetricsProvider>
                <PageTitleUpdater />
                <App />
              </MetricsProvider>
            </MedicionActualProvider>
          </AuthProvider>
        </BrowserScrollToTopWrapper>
      </BrowserRouter>
    </ThemeProvider>
  </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
