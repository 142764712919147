import { Radio, Table } from "@mantine/core";
import _ from "lodash";
import { FormErrorText } from "../FormErrorText";
import { LabelText } from "../LabelText";

type Props = {
  title: string;
  questions: {
    text: string;
    formValue: string;
  }[];
  form: any;
  disabled?: boolean;
};

export const RadioYesNoTable = ({
  title,
  questions,
  form,
  disabled,
}: Props) => {
  const checkErrors = () => {
    let flag = false;
    questions.forEach((question) => {
      if (_.get(form.errors, question.formValue)) flag = true;
    });
    return flag;
  };

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th scope="col" style={{ width: "90%", paddingLeft: "0" }}>
              <LabelText>{title}</LabelText>
            </th>
            <th scope="col" style={{ width: "5%" }}>
              <LabelText>Sí.</LabelText>
            </th>
            <th scope="col" style={{ width: "5%" }}>
              <LabelText>No.</LabelText>
            </th>
          </tr>
        </thead>
        <tbody>
          {questions.map((question, idx) => (
            <tr key={idx}>
              <td>{question.text}</td>
              <td>
                <Radio
                  disabled={disabled}
                  checked={_.get(form.values, question.formValue) === "Sí."}
                  onChange={() => form.setFieldValue(question.formValue, "Sí.")}
                />
              </td>
              <td>
                <Radio
                  disabled={disabled}
                  checked={_.get(form.values, question.formValue) === "No."}
                  onChange={() => form.setFieldValue(question.formValue, "No.")}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {checkErrors() && <FormErrorText>Campos requeridos</FormErrorText>}
    </>
  );
};
