import {
  Paper,
  Text,
  Grid,
  Title,
  Radio,
  Divider,
  Checkbox,
  Button,
  Flex,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import _ from "lodash";
import { useEffect, useState } from "react";
import { AiFillCaretDown, AiFillCaretRight } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";

import {
  GenderLabAppShell,
  GenderLabBreadcrumbs,
  LabelText,
} from "../../components";
import { onChangeCleanFields, notificacionReviseLosCampos } from "../../utils";
import { useMedicionActual } from "../../context/MedicionActualContext";
import { useAuth } from "../../context";
import { clientService } from "../../api";
import { encuestaOrganizacionalIsDisabled } from "./_encuestaOrganizacionalIsDisabled";

type EncuestaPublicoProps = {
  title: string;
  nameId: string;
  form: any;
};

const EncuestaSeccionPublico = ({
  title,
  nameId,
  form,
}: EncuestaPublicoProps) => {
  const [isHidden, setIsHidden] = useState(false);

  const { medicionActualCookie } = useMedicionActual();
  const disabled = encuestaOrganizacionalIsDisabled(medicionActualCookie);

  return (
    <>
      <Grid.Col span={12}>
        <Title
          order={4}
          onClick={() => setIsHidden(!isHidden)}
          style={{ cursor: "pointer" }}
        >
          <Flex align="center" gap="3px">
            {isHidden ? <AiFillCaretRight /> : <AiFillCaretDown />}
            {title}
          </Flex>
        </Title>
      </Grid.Col>
      {!isHidden && (
        <>
          <Grid.Col span={12}>
            <Radio.Group
              label={`¿A qué porcentaje de ${title} han logrado capacitar en prevención del acoso u hostigamiento sexual laboral en los últimos 12 meses?`}
              orientation="vertical"
              {...form.getInputProps(`${nameId}_001`)}
              onChange={(value) =>
                onChangeCleanFields(
                  value,
                  `${nameId}_001`,
                  [
                    `${nameId}_002`,
                    `${nameId}_003_008`,
                    `${nameId}_009_012`,
                    `${nameId}_013_017`,
                  ],
                  "No han recibido capacitación.",
                  form
                )
              }
            >
              <Radio
                disabled={disabled}
                value="No han recibido capacitación."
                label="No han recibido capacitación."
              />
              <Radio
                disabled={disabled}
                value="Menos del 50%."
                label="Menos del 50%."
              />
              <Radio
                disabled={disabled}
                value="Entre el 50% y 80%."
                label="Entre el 50% y 80%."
              />
              <Radio
                disabled={disabled}
                value="Más del 80%."
                label="Más del 80%."
              />
            </Radio.Group>
          </Grid.Col>
          {form.values[`${nameId}_001`] !== "No han recibido capacitación." &&
            form.values[`${nameId}_001`] !== "" &&
            nameId !== "cct" && (
              <>
                <Grid.Col span={12}>
                  <Radio.Group
                    label="¿Cada cuánto tiempo reciben esta capacitación?"
                    orientation="vertical"
                    {...form.getInputProps(`${nameId}_002`)}
                  >
                    <Radio
                      disabled={disabled}
                      value="Se hacen cuando asumen el cargo."
                      label="Se hacen cuando asumen el cargo."
                    />
                    <Radio
                      disabled={disabled}
                      value="1 vez al año."
                      label="1 vez al año."
                    />
                    <Radio
                      disabled={disabled}
                      value="Más de 1 vez al año."
                      label="Más de 1 vez al año."
                    />
                    <Radio
                      disabled={disabled}
                      value="Cuando hay nuevos casos."
                      label="Cuando hay nuevos casos."
                    />
                  </Radio.Group>
                </Grid.Col>
                <Grid.Col span={12}>
                  <Checkbox.Group
                    orientation="vertical"
                    label="¿Qué contenidos abordan?"
                    {...form.getInputProps(`${nameId}_003_008`)}
                  >
                    <Checkbox
                      disabled={disabled}
                      value={`${nameId}_003`}
                      label="Concepto y elementos del acoso u hostigamiento sexual laboral."
                    />
                    <Checkbox
                      disabled={disabled}
                      value={`${nameId}_004`}
                      label="Ejemplos y casos prácticos de situaciones de acoso u hostigamiento sexual laboral."
                    />
                    <Checkbox
                      disabled={disabled}
                      value={`${nameId}_005`}
                      label="Efectos y consecuencias del acoso u hostigamiento sexual laboral."
                    />
                    <Checkbox
                      disabled={disabled}
                      value={`${nameId}_006`}
                      label="Sistema de prevención en la organización (política, canal de denuncia, procedimiento, etc.)."
                    />
                    <Checkbox
                      disabled={disabled}
                      value={`${nameId}_007`}
                      label="Estereotipos y sesgos inconscientes de género."
                    />
                    <Checkbox
                      disabled={disabled}
                      value={`${nameId}_008`}
                      label="Métodos de investigación y resolución de casos (principios, valoración de prueba, tratamiento de personas denunciantes, elaboración de informes, etc.)."
                    />
                  </Checkbox.Group>
                </Grid.Col>
                <Grid.Col span={12}>
                  <Checkbox.Group
                    orientation="vertical"
                    label="¿En qué modalidad se dictan?"
                    {...form.getInputProps(`${nameId}_009_012`)}
                  >
                    <Checkbox
                      disabled={disabled}
                      value={`${nameId}_009`}
                      label="Presencial."
                    />
                    <Checkbox
                      disabled={disabled}
                      value={`${nameId}_010`}
                      label="Semi-presencial."
                    />
                    <Checkbox
                      disabled={disabled}
                      value={`${nameId}_011`}
                      label="Virtual asíncrono (ej. curso online)."
                    />
                    <Checkbox
                      disabled={disabled}
                      value={`${nameId}_012`}
                      label="Virtual sincrónico (ej. webinar)."
                    />
                  </Checkbox.Group>
                </Grid.Col>
                <Grid.Col span={12}>
                  <Checkbox.Group
                    orientation="vertical"
                    label="¿Qué recursos metodológicos se utilizan?"
                    {...form.getInputProps(`${nameId}_013_017`)}
                  >
                    <Checkbox
                      disabled={disabled}
                      value={`${nameId}_013`}
                      label="Exposición."
                    />
                    <Checkbox
                      disabled={disabled}
                      value={`${nameId}_014`}
                      label="Resolución de casos (aprendizaje basado en problemas)."
                    />
                    <Checkbox
                      disabled={disabled}
                      value={`${nameId}_015`}
                      label="Gamificación."
                    />
                    <Checkbox
                      disabled={disabled}
                      value={`${nameId}_016`}
                      label="Dramatizaciones, sociodramas, juegos de roles."
                    />
                    <Checkbox
                      disabled={disabled}
                      value={`${nameId}_017`}
                      label="Otras metodologías."
                    />
                  </Checkbox.Group>
                </Grid.Col>
              </>
            )}
          <Grid.Col span={12}>
            <>
              <LabelText>
                ¿A qué porcentaje de {title} han logrado capacitar en estos
                otros temas en los últimos 12 meses?
              </LabelText>
              <Flex px="md" mt="sm">
                <Grid>
                  <Grid.Col span={12}>
                    <Radio.Group
                      orientation="vertical"
                      label="Igualdad, equidad de género, diversidad e inclusión"
                      {...form.getInputProps(
                        nameId === "cct" ? "cct_002" : `${nameId}_018`
                      )}
                    >
                      <Radio
                        disabled={disabled}
                        value="No han recibido capacitación."
                        label="No han recibido capacitación."
                      />
                      <Radio
                        disabled={disabled}
                        value="Menos del 50%."
                        label="Menos del 50%."
                      />
                      <Radio
                        disabled={disabled}
                        value="Entre el 50% y 80%."
                        label="Entre el 50% y 80%."
                      />
                      <Radio
                        disabled={disabled}
                        value="Más del 80%."
                        label="Más del 80%."
                      />
                    </Radio.Group>
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <Radio.Group
                      orientation="vertical"
                      label="Sesgos inconscientes"
                      {...form.getInputProps(
                        nameId === "cct" ? "cct_003" : `${nameId}_019`
                      )}
                    >
                      <Radio
                        disabled={disabled}
                        value="No han recibido capacitación."
                        label="No han recibido capacitación."
                      />
                      <Radio
                        disabled={disabled}
                        value="Menos del 50%."
                        label="Menos del 50%."
                      />
                      <Radio
                        disabled={disabled}
                        value="Entre el 50% y 80%."
                        label="Entre el 50% y 80%."
                      />
                      <Radio
                        disabled={disabled}
                        value="Más del 80%."
                        label="Más del 80%."
                      />
                    </Radio.Group>
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <Radio.Group
                      orientation="vertical"
                      label="Violencia de género"
                      {...form.getInputProps(
                        nameId === "cct" ? "cct_004" : `${nameId}_020`
                      )}
                    >
                      <Radio
                        disabled={disabled}
                        value="No han recibido capacitación."
                        label="No han recibido capacitación."
                      />
                      <Radio
                        disabled={disabled}
                        value="Menos del 50%."
                        label="Menos del 50%."
                      />
                      <Radio
                        disabled={disabled}
                        value="Entre el 50% y 80%."
                        label="Entre el 50% y 80%."
                      />
                      <Radio
                        disabled={disabled}
                        value="Más del 80%."
                        label="Más del 80%."
                      />
                    </Radio.Group>
                  </Grid.Col>
                </Grid>
              </Flex>
            </>
          </Grid.Col>
        </>
      )}
      <Grid.Col span={12}>
        <Divider />
      </Grid.Col>
    </>
  );
};

export const EncuestaCapacitaciones = () => {
  const { code } = useParams();
  const navigate = useNavigate();
  const { loading, setLoading } = useAuth();

  const { medicionActualCookie } = useMedicionActual();
  const disabled = encuestaOrganizacionalIsDisabled(medicionActualCookie);

  useEffect(() => {
    const getData = async () => {
      const response = await clientService.organizationalSurvey.training.get(
        code
      );
      const data = response.data;
      for (const key in data) {
        if (data[key] === null) {
          data[key] = "";
        } else if (data[key] === true) {
          data[key] = key;
        }
      }

      const filterCheckboxes = (nameId: string, codes: string[]) => {
        const result: string[] = [];
        codes.forEach((item) => {
          if (data[`${nameId}_${item}`]) result.push(`${nameId}_${item}`);
        });
        return result;
      };

      const contenidos = (nameId: string) =>
        filterCheckboxes(nameId, ["003", "004", "005", "006", "007", "008"]);

      const modalidades = (nameId: string) =>
        filterCheckboxes(nameId, ["009", "010", "011", "012"]);

      const recursos = (nameId: string) =>
        filterCheckboxes(nameId, ["013", "014", "015", "016", "017"]);

      form.setValues({
        cli_001: data?.cli_001,
        cli_002: data?.cli_002,
        cli_003_008: contenidos("cli"),
        cli_009_012: modalidades("cli"),
        cli_013_017: recursos("cli"),
        cli_018: data?.cli_018,
        cli_019: data?.cli_019,
        cli_020: data?.cli_020,

        crh_001: data?.crh_001,
        crh_002: data?.crh_002,
        crh_003_008: contenidos("crh"),
        crh_009_012: modalidades("crh"),
        crh_013_017: recursos("crh"),
        crh_018: data?.crh_018,
        crh_019: data?.crh_019,
        crh_020: data?.crh_020,

        cco_001: data?.cco_001,
        cco_002: data?.cco_002,
        cco_003_008: contenidos("cco"),
        cco_009_012: modalidades("cco"),
        cco_013_017: recursos("cco"),
        cco_018: data?.cco_018,
        cco_019: data?.cco_019,
        cco_020: data?.cco_020,

        ctg_001: data?.ctg_001,
        ctg_002: data?.ctg_002,
        ctg_003_008: contenidos("ctg"),
        ctg_009_012: modalidades("ctg"),
        ctg_013_017: recursos("ctg"),
        ctg_018: data?.ctg_018,
        ctg_019: data?.ctg_019,
        ctg_020: data?.ctg_020,

        cct_001: data?.cct_001,
        cct_002: data?.cct_002,
        cct_003: data?.cct_003,
        cct_004: data?.cct_004,
      });
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const form = useForm({
    initialValues: {
      cli_001: "",
      cli_002: "",
      cli_003_008: [""],
      cli_009_012: [""],
      cli_013_017: [""],
      cli_018: "",
      cli_019: "",
      cli_020: "",

      crh_001: "",
      crh_002: "",
      crh_003_008: [""],
      crh_009_012: [""],
      crh_013_017: [""],
      crh_018: "",
      crh_019: "",
      crh_020: "",

      cco_001: "",
      cco_002: "",
      cco_003_008: [""],
      cco_009_012: [""],
      cco_013_017: [""],
      cco_018: "",
      cco_019: "",
      cco_020: "",

      ctg_001: "",
      ctg_002: "",
      ctg_003_008: [""],
      ctg_009_012: [""],
      ctg_013_017: [""],
      ctg_018: "",
      ctg_019: "",
      ctg_020: "",

      cct_001: "",
      cct_002: "",
      cct_003: "",
      cct_004: "",
    },

    validate: {
      cli_001: (value) => !value && "Campo requerido",
      cli_002: () =>
        validateFormIfDifferent(
          "cli_001",
          "cli_002",
          "No han recibido capacitación."
        ),
      cli_003_008: () =>
        validateFormIfDifferent(
          "cli_001",
          "cli_003_008",
          "No han recibido capacitación."
        ),
      cli_009_012: () =>
        validateFormIfDifferent(
          "cli_001",
          "cli_009_012",
          "No han recibido capacitación."
        ),
      cli_013_017: () =>
        validateFormIfDifferent(
          "cli_001",
          "cli_013_017",
          "No han recibido capacitación."
        ),
      cli_018: (value) => !value && "Campo requerido",
      cli_019: (value) => !value && "Campo requerido",
      cli_020: (value) => !value && "Campo requerido",

      crh_001: (value) => !value && "Campo requerido",
      crh_002: () =>
        validateFormIfDifferent(
          "crh_001",
          "crh_002",
          "No han recibido capacitación."
        ),
      crh_003_008: () =>
        validateFormIfDifferent(
          "crh_001",
          "crh_003_008",
          "No han recibido capacitación."
        ),
      crh_009_012: () =>
        validateFormIfDifferent(
          "crh_001",
          "crh_009_012",
          "No han recibido capacitación."
        ),
      crh_013_017: () =>
        validateFormIfDifferent(
          "crh_001",
          "crh_013_017",
          "No han recibido capacitación."
        ),
      crh_018: (value) => !value && "Campo requerido",
      crh_019: (value) => !value && "Campo requerido",
      crh_020: (value) => !value && "Campo requerido",

      cco_001: (value) => !value && "Campo requerido",
      cco_002: () =>
        validateFormIfDifferent(
          "cco_001",
          "cco_002",
          "No han recibido capacitación."
        ),
      cco_003_008: () =>
        validateFormIfDifferent(
          "cco_001",
          "cco_003_008",
          "No han recibido capacitación."
        ),
      cco_009_012: () =>
        validateFormIfDifferent(
          "cco_001",
          "cco_009_012",
          "No han recibido capacitación."
        ),
      cco_013_017: () =>
        validateFormIfDifferent(
          "cco_001",
          "cco_013_017",
          "No han recibido capacitación."
        ),
      cco_018: (value) => !value && "Campo requerido",
      cco_019: (value) => !value && "Campo requerido",
      cco_020: (value) => !value && "Campo requerido",

      ctg_001: (value) => !value && "Campo requerido",
      ctg_002: () =>
        validateFormIfDifferent(
          "ctg_001",
          "ctg_002",
          "No han recibido capacitación."
        ),
      ctg_003_008: () =>
        validateFormIfDifferent(
          "ctg_001",
          "ctg_003_008",
          "No han recibido capacitación."
        ),
      ctg_009_012: () =>
        validateFormIfDifferent(
          "ctg_001",
          "ctg_009_012",
          "No han recibido capacitación."
        ),
      ctg_013_017: () =>
        validateFormIfDifferent(
          "ctg_001",
          "ctg_013_017",
          "No han recibido capacitación."
        ),
      ctg_018: (value) => !value && "Campo requerido",
      ctg_019: (value) => !value && "Campo requerido",
      ctg_020: (value) => !value && "Campo requerido",

      cct_001: (value) => !value && "Campo requerido",
      cct_002: (value) => !value && "Campo requerido",
      cct_003: (value) => !value && "Campo requerido",
      cct_004: (value) => !value && "Campo requerido",
    },
  });

  const validateFormIfDifferent = (
    formValueCondition: string,
    formValueToCheck: string,
    condition: string
  ): string | null => {
    if (_.get(form.values, formValueCondition) !== condition) {
      const value = _.get(form.values, formValueToCheck);
      return value === "" ||
        value.length === 0 ||
        (Array.isArray(value) && value.every((el: string) => el === ""))
        ? "Campo requerido"
        : null;
    } else return null;
  };

  const saveValues = async () => {
    if (!form.validate().hasErrors) {
      try {
        const toSubmit: any = {};

        const fillToSubmitByNameId = (
          nameId: "cli" | "crh" | "cco" | "ctg"
        ) => {
          toSubmit[`${nameId}_001`] = form.values[`${nameId}_001`];
          toSubmit[`${nameId}_002`] = form.values[`${nameId}_002`] || null;

          if (
            form.values[`${nameId}_001`] !== "No han recibido capacitación."
          ) {
            const contenidos: string[] = form.values[`${nameId}_003_008`];
            ["003", "004", "005", "006", "007", "008"].forEach((el) => {
              toSubmit[`${nameId}_${el}`] = contenidos.includes(
                `${nameId}_${el}`
              );
            });

            const modalidades = form.values[`${nameId}_009_012`];
            ["009", "010", "011", "012"].forEach((el) => {
              toSubmit[`${nameId}_${el}`] = modalidades.includes(
                `${nameId}_${el}`
              );
            });

            const recursos = form.values[`${nameId}_013_017`];
            ["013", "014", "015", "016", "017"].forEach((el) => {
              toSubmit[`${nameId}_${el}`] = recursos.includes(
                `${nameId}_${el}`
              );
            });
          } else {
            [
              "003",
              "004",
              "005",
              "006",
              "007",
              "008",
              "009",
              "010",
              "011",
              "012",
              "013",
              "014",
              "015",
              "016",
              "017",
            ].forEach((el) => {
              toSubmit[`${nameId}_${el}`] = null;
            });
          }

          toSubmit[`${nameId}_018`] = form.values[`${nameId}_018`];
          toSubmit[`${nameId}_019`] = form.values[`${nameId}_019`];
          toSubmit[`${nameId}_020`] = form.values[`${nameId}_020`];
        };

        fillToSubmitByNameId("cli");
        fillToSubmitByNameId("crh");
        fillToSubmitByNameId("cco");
        fillToSubmitByNameId("ctg");

        toSubmit.cct_001 = form.values.cct_001;
        toSubmit.cct_002 = form.values.cct_002;
        toSubmit.cct_003 = form.values.cct_003;
        toSubmit.cct_004 = form.values.cct_004;

        setLoading(true);
        await clientService.organizationalSurvey.training.put(code, toSubmit);
        setLoading(false);
        navigate(`/medicion/${code}`, { state: { from: "eo" } });
      } catch (error) {
        console.error(error);
      }
    } else {
      notificacionReviseLosCampos();
    }
  };

  return (
    <GenderLabAppShell>
      <GenderLabBreadcrumbs
        breadcrumbs={[
          { link: "/mediciones", title: "Mediciones" },
          {
            link: "/medicion/" + medicionActualCookie?.code,
            title: medicionActualCookie?.title || "",
          },
          { link: "", title: "Capacitaciones" },
        ]}
      />
      <Paper shadow="xs" p="md" mt="lg">
        <Text size={14}>
          Esta sección contiene preguntas relacionadas a la capacitación de los
          distintos públicos objetivos en tu organización.
        </Text>
      </Paper>
      <form>
        <Grid mt="xs" gutter="md">
          <EncuestaSeccionPublico
            title="Líderes de la organización (Niveles 1, 2 y 3)"
            nameId="cli"
            form={form}
          />
          <EncuestaSeccionPublico
            title="Área de Recursos Humanos o Gestión de Talento"
            nameId="crh"
            form={form}
          />
          <EncuestaSeccionPublico
            title="Comités de Intervención, Ética, Convivencia, etc."
            nameId="cco"
            form={form}
          />
          <EncuestaSeccionPublico
            title="Trabajadores/as en general"
            nameId="ctg"
            form={form}
          />
          <EncuestaSeccionPublico
            title="Empresas contratistas"
            nameId="cct"
            form={form}
          />
          <Grid.Col span={12}>
            <Button onClick={saveValues} disabled={loading || disabled}>
              Guardar
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </GenderLabAppShell>
  );
};
