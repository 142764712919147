import { Flex, Sx, Text, createStyles } from "@mantine/core";

type Props = {
  percentage: number;
  description: string;
  sx?: Sx | (Sx | undefined)[] | undefined;
};

const useStyles = createStyles((theme) => ({
  cardContainer: {
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: "10px",
  },
  cardPorcentaje: {
    minWidth: 55,
    width: 55,
    backgroundColor: theme.colors.gray[1],
    color: theme.colors.gray[7],
    fontSize: 20,
    fontWeight: 600,
    borderRadius: "10px 0 0 10px",
  },
}));

export const PorcentajeCardLarge = ({ percentage, description, sx }: Props) => {
  const { classes } = useStyles();

  return (
    <Flex align="" gap="xs" className={classes.cardContainer}>
      <Flex
        className={classes.cardPorcentaje}
        justify="center"
        align="center"
        sx={sx}
      >
        {/* <div className={classes.cardPorcentaje}> */}
        {Math.round(percentage * 100)}%{/* </div> */}
      </Flex>
      <Text size="sm" py="xs">
        {description}
      </Text>
    </Flex>
  );
};
