import { Select } from "@mantine/core";

type Props = {
  onSelectChange: (value: string | null) => void;
  genderDisabled: boolean;
  ageDisabled: boolean;
  sexualOrientationDisabled: boolean;
  ethnicityDisabled: boolean;
  jobDisabled: boolean;
  areaDisabled: boolean;
  workplaceDisabled: boolean;
  workShiftDisabled: boolean;
};

export const SelectCortesData = ({
  onSelectChange,
  genderDisabled,
  ageDisabled,
  sexualOrientationDisabled,
  ethnicityDisabled,
  jobDisabled,
  areaDisabled,
  workplaceDisabled,
  workShiftDisabled,
}: Props) => (
  <Select
    labelProps={{ style: { fontSize: 12 } }}
    label="Elige un criterio:"
    defaultValue="genero"
    data={[
      {
        value: "genero",
        label: "Género",
        group: "Perfil personal",
        disabled: genderDisabled,
      },
      {
        value: "edad",
        label: "Edad",
        group: "Perfil personal",
        disabled: ageDisabled,
      },
      {
        value: "orientacion-sexual",
        label: "Comunidad LGBTIQ+",
        group: "Perfil personal",
        disabled: sexualOrientationDisabled,
      },
      {
        value: "grupo-etnico-racial",
        label: "Grupo étnico racial",
        group: "Perfil personal",
        disabled: ethnicityDisabled,
      },
      {
        value: "puesto-de-trabajo",
        label: "Puesto de trabajo",
        group: "Perfil laboral",
        disabled: jobDisabled,
      },
      {
        value: "area",
        label: "Área",
        group: "Perfil laboral",
        disabled: areaDisabled,
      },
      {
        value: "centro-de-trabajo",
        label: "Centro de trabajo",
        group: "Perfil laboral",
        disabled: workplaceDisabled,
      },
      {
        value: "turno",
        label: "Turno",
        group: "Perfil laboral",
        disabled: workShiftDisabled,
      },
    ]}
    maw={200}
    onChange={onSelectChange}
  />
);
