import { Group, Select, Text } from "@mantine/core";
import { forwardRef, useEffect, useState } from "react";

import { TClientAdmin } from "../../types";
import { adminService } from "../../api";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context";

type ItemData = {
  image: string;
  label: string;
  value: string;
};

const SelectItem = forwardRef<HTMLDivElement, ItemData>(
  ({ image, label, value, ...others }: ItemData, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        {/* <Avatar src={image} /> */}
        <Text size="sm">{label}</Text>
      </Group>
    </div>
  )
);

export const ClientSelector = () => {
  const navigate = useNavigate();

  const [clients, setClients] = useState<ItemData[]>([]);
  const [selectedClient, setSelectedClient] = useState<string | undefined>(
    undefined
  );

  const { user } = useAuth();

  useEffect(() => {
    const getClientList = async () => {
      const response = await adminService.getClientList();
      const rawClients: TClientAdmin[] = response.data;
      rawClients.sort((a, b) => {
        if (a.trade_name < b.trade_name) return -1;
        if (a.trade_name > b.trade_name) return 1;
        return 0;
      });

      const _clients = rawClients.map((client) => ({
        image: client.logo,
        label: client.trade_name,
        value: client.id.toString(),
      }));
      setClients(_clients);
    };

    getClientList();
  }, []);

  useEffect(() => {
    setSelectedClient(user?.clientId.toString());
  }, [user]);

  const onChange = async (value: string) => {
    adminService
      .updateAssignedClient(value)
      .then(() => setSelectedClient(value));
    navigate(0);
  };

  return (
    <Select
      disabled={clients.length === 0}
      itemComponent={SelectItem}
      data={clients}
      placeholder="Selecciona un cliente"
      searchable
      maxDropdownHeight={300}
      nothingFound="No se encontraron clientes"
      filter={(value, item) =>
        item?.label?.toLowerCase().includes(value.toLowerCase()) || false
      }
      value={selectedClient}
      onChange={onChange}
    />
  );
};

export default ClientSelector;
