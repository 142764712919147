import React, { useState, useRef, useEffect } from "react";

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import { canvasPreview } from "./_canvasPreview";
import { useDebounceEffect } from "./_useDebounceEffect";

import "react-image-crop/dist/ReactCrop.css";
import { Button, Center, FileInput, Grid, Slider } from "@mantine/core";
import { LabelText } from "../LabelText";

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

type Props = {
  callback: (dataUrl: string) => Promise<void>;
};

export function ImageCropper({ callback }: Props) {
  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState(1.5);
  const [rotate, setRotate] = useState(0);
  const aspect = 1;
  const [image, setImage] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  previewCanvasRef.current?.toDataURL();

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  useEffect(() => {
    if (image && image.size > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(image);
    }
  }, [image]);

  return (
    <div className="App">
      <Grid>
        <Grid.Col span={12}>
          <FileInput
            accept="image/*"
            placeholder="Seleccione una imagen"
            label="Imagen"
            onChange={setImage}
          />
        </Grid.Col>
        {!!imgSrc && (
          <Grid.Col span={12}>
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                style={{
                  transform: `scale(${scale}) rotate(${rotate}deg)`,
                }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          </Grid.Col>
        )}
        {!!imgSrc && (
          <Grid.Col span={12}>
            <LabelText>Escala</LabelText>
            <Slider
              label={null}
              value={scale}
              onChange={(e) => setScale(e)}
              min={0.1}
              max={3}
              step={0.1}
            />
          </Grid.Col>
        )}
        {!!imgSrc && (
          <Grid.Col span={12}>
            <LabelText>Rotación</LabelText>
            <Slider
              label={null}
              value={rotate}
              onChange={(e) => setRotate(e)}
              min={-180}
              max={180}
              step={1}
            />
          </Grid.Col>
        )}
        {!!completedCrop && (
          <Grid.Col span={12}>
            <>
              <LabelText>Preview</LabelText>
              <Center>
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    border: "1px solid black",
                    objectFit: "contain",
                    width: completedCrop.width,
                    height: completedCrop.height,
                  }}
                />
              </Center>
            </>
          </Grid.Col>
        )}
        {!!completedCrop && (
          <Grid.Col span={12}>
            <Button
              fullWidth
              onClick={async () => {
                setLoading(true);
                await callback(previewCanvasRef.current?.toDataURL() || "");
                setLoading(false);
              }}
              disabled={loading}
            >
              Seleccionar
            </Button>
          </Grid.Col>
        )}
      </Grid>
    </div>
  );
}
