import { useLocation } from "react-router-dom";
import { useLayoutEffect } from "react";

type Props = {
  children: JSX.Element;
};

export const BrowserScrollToTopWrapper = ({ children }: Props) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};
