import { ApiWithToken } from "../axios-instance";

export const aulaService = {
  uri: "/api/aula/",
  getActiveCourses: async () =>
    ApiWithToken.get(`${aulaService.uri}active_courses/`),
  getAvailableCourses: async () =>
    ApiWithToken.get(`${aulaService.uri}available_courses/`),
  getActiveAndAvailableCourses: async () =>
    ApiWithToken.get(`${aulaService.uri}active_and_available_courses/`),
  getActiveCourseById: async (id: number) =>
    ApiWithToken.get(`${aulaService.uri}progress/?course=${id}`),
  getStudentCsvByCourseId: async (id: number) =>
    ApiWithToken.get(`${aulaService.uri}enrollments/download/?course=${id}`),
  getEnrollmentsByCourseId: async (
    id: number,
    page: number,
    pageSize: number,
    query?: string,
    sortField?: string,
    sortOrder?: string
  ) => {
    return ApiWithToken.get(aulaService.uri + "enrollments/", {
      params: {
        course: id,
        page: page,
        page_size: pageSize,
        search: query || "",
        sort_field: sortField || "student__name",
        sort_direction: sortOrder || "asc",
      },
    });
  },
  getAvailableCourseById: async (id: number) =>
    ApiWithToken.get(`${aulaService.uri}available/?course=${id}`),
  unenroll: async (studentId: number, courseId: number) =>
    ApiWithToken.delete(`${aulaService.uri}unenroll/`, {
      params: { student: studentId, course: courseId },
    }),
  enroll: async (
    name: string,
    email: string,
    documentId: string,
    job: string,
    area: string,
    workplace: string,
    courseId: string
  ) =>
    ApiWithToken.post(`${aulaService.uri}enroll/`, {
      name: name,
      email: email,
      document_id: documentId,
      job: job,
      area: area,
      workplace: workplace,
      course: courseId,
    }),
  getAulaStudentsPdf: async (courseId: string) =>
    ApiWithToken.get(`${aulaService.uri}course_students/${courseId}/`, {
      responseType: "blob",
    }),
  getCertificateData: async (courseId: string) =>
    ApiWithToken.get(`${aulaService.uri}course_certificate/${courseId}/`),
  getStudentCertificateData: async (courseId: string, studentId: string) =>
    ApiWithToken.get(
      `${aulaService.uri}student_certificate/?course_id=${courseId}&student_id=${studentId}`
    ),
};
