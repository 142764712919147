import { useAuth, useMetrics } from "../../context";
import { TSexualHarassmentKnowledge, TToleranceCategory } from "../../types";
import { parsePercentage } from "../../utils";
import { Heatmap } from "../Charts";

type Props = {
  data: { [key: string]: TToleranceCategory };
  total: TSexualHarassmentKnowledge;
};

export const ConceptoHeatmap = ({ data, total }: Props) => {
  const { regionalBenchmark, countryBenchmark, previousMetrics } = useMetrics();
  const { user } = useAuth();

  return (
    <Heatmap
      categories={[
        "Sí debe mostrar rechazo expreso",
        "Sí debe suceder más de una vez",
        "Sí debe tener intención de acosar",
        "Sí debe venir de un/a jefe/a",
      ]}
      data={[
        ...(user?.hasRegionalBenchmark && regionalBenchmark
          ? [
              {
                name: "Benchmark Regional",
                data: [
                  parsePercentage(regionalBenchmark.benchmarks.RB_0006),
                  parsePercentage(regionalBenchmark.benchmarks.RB_0005),
                  parsePercentage(regionalBenchmark.benchmarks.RB_0007),
                  parsePercentage(regionalBenchmark.benchmarks.RB_0008),
                ],
              },
            ]
          : []),
        ...(user?.hasCountryBenchmark && countryBenchmark
          ? [
              {
                name: "Benchmark País",
                data: [
                  parsePercentage(countryBenchmark.benchmarks.CB_0006),
                  parsePercentage(countryBenchmark.benchmarks.CB_0005),
                  parsePercentage(countryBenchmark.benchmarks.CB_0007),
                  parsePercentage(countryBenchmark.benchmarks.CB_0008),
                ],
              },
            ]
          : []),
        ...(user?.hasEvolutionaryResults && previousMetrics
          ? [
              {
                name: "Medición anterior",
                data: [
                  parsePercentage(previousMetrics.metrics.CPM_0001 || -1),
                  parsePercentage(previousMetrics.metrics.CPM_0002 || -1),
                  parsePercentage(previousMetrics.metrics.CPM_0003 || -1),
                  parsePercentage(previousMetrics.metrics.CPM_0004 || -1),
                ],
              },
            ]
          : []),
        {
          name: "Total",
          data: [
            parsePercentage(total.cpt_001),
            parsePercentage(total.cpt_002),
            parsePercentage(total.cpt_003),
            parsePercentage(total.cpt_004),
          ],
        },
        ...Object.keys(data).map((key) => ({
          name: key,
          data: [
            parsePercentage(data[key].sexual_harassment_knowledge.cpt_001),
            parsePercentage(data[key].sexual_harassment_knowledge.cpt_002),
            parsePercentage(data[key].sexual_harassment_knowledge.cpt_003),
            parsePercentage(data[key].sexual_harassment_knowledge.cpt_004),
          ],
        })),
      ]}
    />
  );
};
