import { createContext, useContext, useState } from "react";
import { TRegionalBenchmark, TCountryBenchmark } from "../types";
import { TPreviousMetrics } from "../types/Resultados";

type MetricsContextProps = {
  regionalBenchmark: TRegionalBenchmark | null;
  setRegionalBenchmark: (medicionActual: TRegionalBenchmark | null) => void;
  countryBenchmark: TCountryBenchmark | null;
  setCountryBenchmark: (medicionActual: TCountryBenchmark | null) => void;
  previousMetrics: TPreviousMetrics | null;
  setPreviousMetrics: (medicionActual: TPreviousMetrics | null) => void;
};

const MetricsContext = createContext<MetricsContextProps>(null!);

type Props = {
  children: React.ReactNode;
};

const MetricsProvider = ({ children }: Props) => {
  const [regionalBenchmark, setRegionalBenchmark] =
    useState<TRegionalBenchmark | null>(null!);
  const [countryBenchmark, setCountryBenchmark] =
    useState<TCountryBenchmark | null>(null!);
  const [previousMetrics, setPreviousMetrics] =
    useState<TPreviousMetrics | null>(null!);

  return (
    <MetricsContext.Provider
      value={{
        regionalBenchmark,
        setRegionalBenchmark,
        countryBenchmark,
        setCountryBenchmark,
        previousMetrics,
        setPreviousMetrics,
      }}
    >
      {children}
    </MetricsContext.Provider>
  );
};

export const useMetrics = () => useContext(MetricsContext);
export { MetricsContext, MetricsProvider };
