import {
  Button,
  Checkbox,
  Flex,
  Grid,
  Modal,
  Paper,
  Radio,
  Select,
  Table,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { RiAddFill } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";

import { clientService } from "../../api";
import {
  FormErrorText,
  GenderLabAppShell,
  GenderLabBreadcrumbs,
  LabelText,
} from "../../components";
import { useAuth, useMedicionActual } from "../../context";
import { notificacionErrorEncuesta } from "./_notificacionError";
import { onChangeCleanFields } from "../../utils";
import { TGoodPractice, TTag } from "../../types";
import { encuestaOrganizacionalIsDisabled } from "./_encuestaOrganizacionalIsDisabled";
import { EditarEliminarButtonsCombo } from "../MedicionActualPages/PersonalizacionPage/__editarEliminarButtonsCombo";

const buenaPracticaBase: TGoodPractice = {
  id: 0,
  title: "",
  description: "",
  tag: 0,
  organizational_survey: 0,
};

export const EncuestaBuenasPracticas = () => {
  const { medicionActualCookie } = useMedicionActual();
  const disabled = encuestaOrganizacionalIsDisabled(medicionActualCookie);
  const { code } = useParams();
  const { loading, setLoading } = useAuth();
  const [openedAddModal, { open: openAddModal, close: closeAddModal }] =
    useDisclosure(false);
  const [idToEdit, setIdToEdit] = useState<number | undefined>(undefined);
  const [tags, setTags] = useState<TTag[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      const response =
        await clientService.organizationalSurvey.good_practices.get(code);

      const survey = response.data.survey;

      form.setValues({
        bp_001: survey.bp_001,
        bp_002: survey.bp_002,
        bp_003_011: [
          "003",
          "004",
          "005",
          "006",
          "007",
          "008",
          "009",
          "010",
          "011",
        ]
          .map((code) =>
            survey[`bp_${code}`] ? (code === "011" ? "otro" : `bp_${code}`) : ""
          )
          .filter((code) => code !== ""),
        bp_011: survey.bp_011 || "",
        bp_012: survey.bp_012,
      });

      const goodPractices = response.data.good_practices;
      form.setValues({ buenasPracticas: goodPractices });
      setTags(response.data.tags);
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const form = useForm({
    initialValues: {
      buenasPracticas: [buenaPracticaBase],
      bp_001: "",
      bp_002: "",
      bp_003_011: [""],
      bp_011: "",
      bp_012: "",
      nombreTemp: "",
      descripcionTemp: "",
      categoriaTemp: "",
    },

    validate: {
      bp_001: (value) => !value && "Campo requerido",
      bp_002: (value) => !value && "Campo requerido",
      bp_003_011: (value) =>
        organizacionTieneMediciones() &&
        value.length === 0 &&
        "Campo requerido",
      bp_011: (value) => {
        return medicionesIncludesOtro() && !value && "Campo requerido";
      },
      bp_012: (value) => !value && "Campo requerido",
      nombreTemp: (value) => openedAddModal && !value && "Campo requerido",
      descripcionTemp: (value) => openedAddModal && !value && "Campo requerido",
      categoriaTemp: (value) => openedAddModal && !value && "Campo requerido",
    },
  });

  const organizacionTieneBuenasPracticas: () => boolean = () =>
    form.values.bp_001 === "Sí.";

  const organizacionTieneMediciones: () => boolean = () =>
    form.values.bp_002 === "Sí.";

  const medicionesIncludesOtro = () => {
    const mediciones: string[] = form.values.bp_003_011;
    if (mediciones) return mediciones.includes("otro");
  };

  const listaTieneElementos = () => {
    const lista = form.values.buenasPracticas;
    if (lista) return lista.length >= 1;
  };

  const saveValues = async () => {
    if (
      form.validate().hasErrors ||
      (organizacionTieneBuenasPracticas() && !listaTieneElementos())
    ) {
      notificacionErrorEncuesta();
    } else {
      try {
        const toSubmit: any = {};
        toSubmit.bp_001 = form.values.bp_001;

        if (form.values.bp_001 === "No." && listaTieneElementos()) {
          const buenasPracticas = form.values.buenasPracticas;
          buenasPracticas.forEach((buenaPractica) => {
            clientService.organizationalSurvey.good_practices.delete(
              code,
              buenaPractica.id
            );
          });
        }

        toSubmit.bp_002 = form.values.bp_002;
        if (organizacionTieneMediciones()) {
          const mediciones: string[] = form.values.bp_003_011;
          ["003", "004", "005", "006", "007", "008", "009", "010"].forEach(
            (medicion) => {
              if (mediciones.includes(`bp_${medicion}`)) {
                toSubmit[`bp_${medicion}`] = true;
              } else {
                toSubmit[`bp_${medicion}`] = false;
              }
            }
          );
        } else {
          ["003", "004", "005", "006", "007", "008", "009", "010"].forEach(
            (medicion) => {
              toSubmit[`bp_${medicion}`] = null;
            }
          );
          toSubmit.bp_011 = null;
        }
        if (medicionesIncludesOtro()) {
          toSubmit.bp_011 = form.values.bp_011;
        }
        toSubmit.bp_012 = form.values.bp_012;

        setLoading(true);
        await clientService.organizationalSurvey.good_practices.put(
          code,
          toSubmit
        );
        setLoading(false);
        navigate(`/medicion/${code}`, {
          state: {
            from: "eo",
          },
        });
      } catch (error) {
        notificacionErrorEncuesta();
      }
    }
  };

  const selectTags = () =>
    tags.map((tag) => {
      return { value: tag.id.toString(), label: tag.description };
    });

  const addBuenaPracticaButton = (
    <Flex justify="center">
      <Button
        disabled={disabled}
        variant="light"
        color="green"
        onClick={openAddModal}
      >
        <RiAddFill color="green" size={14} />
      </Button>
    </Flex>
  );

  const onCloseAddModal = () => {
    form.setValues({
      nombreTemp: "",
      descripcionTemp: "",
      categoriaTemp: "",
    });
    closeAddModal();
  };

  const modalAddBuenaPractica = (
    <Modal
      opened={openedAddModal}
      onClose={onCloseAddModal}
      title={`${idToEdit ? "Editar" : "Agregar"} buena práctica`}
    >
      <TextInput label="Nombre" {...form.getInputProps("nombreTemp")} />
      <Textarea
        label="Descripción (si quieres que veamos algún video, web u otro, coloca aquí el link)"
        mt="sm"
        {...form.getInputProps("descripcionTemp")}
      />
      <Select
        data={selectTags()}
        label="Categoría"
        mt="sm"
        {...form.getInputProps("categoriaTemp")}
      />
      <Button
        fullWidth
        mt="sm"
        onClick={async () => {
          const nombreTempValidation = form.validateField("nombreTemp");
          const descripcionTempValidation =
            form.validateField("descripcionTemp");
          const categoriaTempValidation = form.validateField("categoriaTemp");
          if (
            !nombreTempValidation.hasError &&
            !descripcionTempValidation.hasError &&
            !categoriaTempValidation.hasError
          ) {
            const goodPractice = {
              title: form.values.nombreTemp,
              description: form.values.descripcionTemp,
              tag: parseInt(form.values.categoriaTemp),
            };
            if (idToEdit) {
              const response =
                await clientService.organizationalSurvey.good_practices.put(
                  code,
                  goodPractice,
                  idToEdit
                );
              form.setValues({
                ...form.values,
                buenasPracticas: form.values.buenasPracticas.map(
                  (buenaPractica) => {
                    if (buenaPractica.id === idToEdit) {
                      return response.data;
                    }
                    return buenaPractica;
                  }
                ),
              });
              // form.setFieldValue("buenasPracticas", [
              //   ...form.values.buenasPracticas.filter(
              //     (buenaPractica) => buenaPractica.id !== idToEdit
              //   ),
              //   response.data,
              // ]);
            } else {
              const response =
                await clientService.organizationalSurvey.good_practices.post(
                  code,
                  goodPractice
                );
              const newGoodPractice: TGoodPractice = response.data;
              form.setFieldValue("buenasPracticas", [
                ...form.values.buenasPracticas,
                newGoodPractice,
              ]);
            }
            onCloseAddModal();
          }
        }}
      >
        Guardar
      </Button>
    </Modal>
  );

  const removeBuenaPractica = async (
    buenaPractica: TGoodPractice,
    idx: number
  ) => {
    openConfirmModal({
      title: "¿Estás seguro de eliminar esta buena práctica?",
      labels: {
        confirm: "Eliminar",
        cancel: "Cancelar",
      },
      onConfirm: async () => {
        try {
          await clientService.organizationalSurvey.good_practices.delete(
            code,
            buenaPractica.id
          );
          form.removeListItem("buenasPracticas", idx);
        } catch (error) {
          console.error(error);
        }
      },
    });
  };

  return (
    <GenderLabAppShell>
      {modalAddBuenaPractica}
      <GenderLabBreadcrumbs
        breadcrumbs={[
          { link: "/mediciones", title: "Mediciones" },
          {
            link: "/medicion/" + medicionActualCookie?.code,
            title: medicionActualCookie?.title || "",
          },
          { link: "", title: "Buenas prácticas" },
        ]}
      />
      <Paper shadow="xs" p="md" mt="lg">
        <Text size={14}>
          Creemos en el aprendizaje colaborativo para la prevención del
          hostigamiento sexual laboral. En la siguiente sección puedes contarnos
          más sobre alguna buena práctica en tu organización para abordar este
          tema.
        </Text>
      </Paper>
      <form>
        <Grid mt="xs" gutter="md">
          <Grid.Col span={12}>
            <Radio.Group
              label="¿Tu organización tiene alguna buena práctica de prevención y/o atención del acoso u hostigamiento sexual laboral que quieras compartir?"
              {...form.getInputProps("bp_001")}
            >
              <Radio disabled={disabled} value="Sí." label="Sí."></Radio>
              <Radio disabled={disabled} value="No." label="No."></Radio>
            </Radio.Group>
          </Grid.Col>
          {form.values.bp_001 === "Sí." && (
            <>
              <Grid.Col span={12}>
                <LabelText>
                  Especifica el nombre y describe esta buena práctica.
                </LabelText>
              </Grid.Col>
              <Grid.Col span={12}>
                {!listaTieneElementos() && (
                  <Flex align="center" gap="xs">
                    <FormErrorText>
                      Debe haber al menos una buena práctica. Haz click en el
                      botón + para agregar una.
                    </FormErrorText>
                    {addBuenaPracticaButton}
                  </Flex>
                )}
                {listaTieneElementos() && (
                  <Table>
                    <thead>
                      <tr>
                        <th scope="col" style={{ width: "20%" }}>
                          Nombre
                        </th>
                        <th scope="col" style={{ width: "45%" }}>
                          Descripción
                          <Text size="xs" fw={400}>
                            (si quieres que veamos algún video, web u otro,
                            coloca aquí el link)
                          </Text>
                        </th>
                        <th scope="col" style={{ width: "25%" }}>
                          Categoría
                        </th>
                        <th scope="col" style={{ width: "10%" }} />
                      </tr>
                    </thead>
                    <tbody>
                      {form.values.buenasPracticas.map((buenaPractica, idx) => (
                        <tr key={idx}>
                          <td>
                            <Text>
                              {form.values.buenasPracticas[idx].title}
                            </Text>
                          </td>
                          <td>
                            <Text>
                              {form.values.buenasPracticas[idx].description}
                            </Text>
                          </td>
                          <td>
                            <Text>
                              {
                                tags.find((tag) => tag.id === buenaPractica.tag)
                                  ?.description
                              }
                            </Text>
                          </td>
                          <td>
                            <EditarEliminarButtonsCombo
                              disabled={disabled}
                              onEditarClick={() => {
                                openAddModal();
                                form.setFieldValue(
                                  "nombreTemp",
                                  buenaPractica.title
                                );
                                form.setFieldValue(
                                  "descripcionTemp",
                                  buenaPractica.description
                                );
                                form.setFieldValue(
                                  "categoriaTemp",
                                  buenaPractica.tag.toString()
                                );
                                setIdToEdit(buenaPractica.id);
                              }}
                              onBorrarClick={() =>
                                removeBuenaPractica(buenaPractica, idx)
                              }
                            />
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={4}>{addBuenaPracticaButton}</td>
                      </tr>
                    </tbody>
                  </Table>
                )}
              </Grid.Col>
            </>
          )}

          <Grid.Col>
            <Radio.Group
              label="¿Tu organización participa de alguna medición, ranking o certificación en igualdad de género y/o reputación empresarial?"
              {...form.getInputProps("bp_002")}
              onChange={(value) =>
                onChangeCleanFields(
                  value,
                  "bp_002",
                  ["bp_003_011"],
                  "No.",
                  form
                )
              }
            >
              <Radio disabled={disabled} value="Sí." label="Sí." />
              <Radio disabled={disabled} value="No." label="No." />
            </Radio.Group>
          </Grid.Col>
          {form.values.bp_002 === "Sí." && (
            <Grid.Col>
              <Checkbox.Group
                orientation="vertical"
                label="¿Cuáles?"
                {...form.getInputProps("bp_003_011")}
              >
                <Checkbox
                  disabled={disabled}
                  value="bp_003"
                  label="Marca o Sello de Certificación “Empresa Segura, Libre de Violencia y Discriminación contra la Mujer”"
                />
                <Checkbox
                  disabled={disabled}
                  value="bp_004"
                  label="Ranking Par (Perú y Colombia)"
                />
                <Checkbox
                  disabled={disabled}
                  value="bp_005"
                  label="Equipares (Colombia)"
                />
                <Checkbox
                  disabled={disabled}
                  value="bp_006"
                  label="Great Place To Work"
                />
                <Checkbox
                  disabled={disabled}
                  value="bp_007"
                  label="Great Place To Work For Women"
                />
                <Checkbox
                  disabled={disabled}
                  value="bp_008"
                  label="Iniciativa de Paridad de Género (IPG)"
                />
                <Checkbox disabled={disabled} value="bp_009" label="Merco" />
                <Checkbox
                  disabled={disabled}
                  value="bp_010"
                  label="Certificación Presente"
                />
                <Checkbox
                  disabled={disabled}
                  value="otro"
                  label="Otro (especificar)"
                />
              </Checkbox.Group>
              {medicionesIncludesOtro() && (
                <TextInput
                  disabled={disabled}
                  mt="xs"
                  {...form.getInputProps("bp_011")}
                />
              )}
            </Grid.Col>
          )}
          <Grid.Col>
            <Radio.Group
              label="¿Cuál es el género de la máxima autoridad de la organización? (CEO, Gerente General, Director/a Nacional, etc.)"
              orientation="vertical"
              {...form.getInputProps("bp_012")}
            >
              <Radio disabled={disabled} value="Hombre." label="Hombre." />
              <Radio disabled={disabled} value="Mujer." label="Mujer." />
              <Radio
                disabled={disabled}
                value="No Binario (se refiere a una persona que no se identifica como hombre ni como mujer)."
                label="No Binario (se refiere a una persona que no se identifica como hombre ni como mujer)."
              />
            </Radio.Group>
          </Grid.Col>
          <Grid.Col>
            <Button onClick={saveValues} disabled={loading || disabled}>
              Guardar
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </GenderLabAppShell>
  );
};
