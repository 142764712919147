import {
  createStyles,
  Card,
  Text,
  Badge,
  Flex,
  Divider,
  Progress,
  Container,
  Button,
  Center,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { TMedicion } from "../../types";
import { useMedicionActual } from "../../context";

type Props = {
  medicion: TMedicion;
};

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
  },
  titulo: {
    fontWeight: 700,
    lineHeight: 1,
  },
  lead: {
    fontWeight: 500,
    lineHeight: 1,
  },
  subtitulo: {
    fontWeight: 500,
    fontSize: 16,
  },
  titleSection: {
    backgroundColor: theme.white,
  },
}));

type MedicionesProgressProps = {
  value: number;
};

const MedicionesProgress = ({ value }: MedicionesProgressProps) => {
  const minShownPercentage = 15;
  return (
    <Progress
      value={
        value < minShownPercentage && value > 0 ? minShownPercentage : value
      }
      label={
        value < minShownPercentage && value > 0
          ? `<${minShownPercentage}%`
          : value.toString() + "%"
      }
      size="xl"
      radius="xl"
      mt="xl"
      color={value < 100 ? "orange" : "green"}
    />
  );
};

export const MedicionesCard = ({ medicion }: Props) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { setMedicionActualCookie } = useMedicionActual();

  const onClick = () => {
    setMedicionActualCookie(medicion);
    navigate(`/medicion/${medicion.code}`);
  };

  interface ConditionColors {
    [key: string]: string;
  }
  
  const colorConditions: ConditionColors = {
    "En preparación": "yellow",
    "En curso": "blue",
    "Completada": "green",
    "Pasada": "gray",
  };
  
  function getColorForCondition(condition: keyof ConditionColors): string {
    return colorConditions[condition] || "black"; // Default to black for unknown conditions
  }

  return (
    <Card withBorder p="xl" radius="md" className={classes.card}>
      <Card.Section className={classes.titleSection}>
        <Container px="xl" py="lg">
          <Flex
            justify="space-between"
            align="center"
            style={{ height: "55px" }}
          >
            <Text size="xl" className={classes.titulo}>
              {medicion.title}
            </Text>
            <Flex style={{ display: "inline-block" }} justify="flex-end">
              <Badge
                color={
                  getColorForCondition(medicion.condition as keyof ConditionColors)
                }
              >
                {medicion.condition}
              </Badge>
            </Flex>
          </Flex>
        </Container>
      </Card.Section>

      <Card.Section>
        <Divider />
      </Card.Section>

      <Card.Section>
        <Container px="xl" py="lg">
          <Flex
            gap="sm"
            align="center"
            justify="space-between"
            style={{ height: "50px" }}
          >
            <Text className={classes.subtitulo}>Encuesta organizacional</Text>
            <Badge
              color={
                medicion.organizational_survey.is_completed ? "green" : "red"
              }
            >
              {medicion.organizational_survey.is_completed
                ? "completada"
                : "no completada"}
            </Badge>
          </Flex>

          <Divider mt="lg" />

          <Text className={classes.subtitulo} mt="lg">
            Encuesta al personal
          </Text>

          <Flex gap="sm" mt="lg" justify="space-evenly">
            <Flex direction="column">
              <Text className={classes.lead}>
                {medicion.staff_survey.launched_at || "no establecida"}
              </Text>
              <Text size="xs" color="dimmed">
                fecha de inicio
              </Text>
            </Flex>
            <Flex direction="column">
              <Text className={classes.lead}>
                {medicion.staff_survey.ended_at || "no establecida"}
              </Text>
              <Text size="xs" color="dimmed">
                fecha final
              </Text>
            </Flex>
          </Flex>

          <MedicionesProgress
            value={medicion.staff_survey.minimum_sample_progress * 100}
          />
          <Text size="xs" color="dimmed">
            Progreso para llegar a la muestra mínima
          </Text>

          {/* <MedicionesProgress
            value={medicion.staff_survey.total_population_progress}
          />
          <Text size="xs" color="dimmed">
            trabajadores que han llenado la encuesta
          </Text> */}

          <Center mt="lg">
            <Button color="teal" variant="outline" onClick={onClick}>
              Ver detalle
            </Button>
          </Center>
        </Container>
      </Card.Section>
    </Card>
  );
};
