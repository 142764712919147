import { createStyles } from "@mantine/core";
import { CSSProperties } from "react";

interface MyComponentStyles extends CSSProperties {
  backgroundUrl: string;
  color: string;
}

// ADAPTACION DEL ELSA ANTIGUO 2022
const useStyles = createStyles(
  (theme, { backgroundUrl, color }: MyComponentStyles) => ({
    costosItem: {
      backgroundImage: `url(${backgroundUrl})`,
      display: "flex",
      flexDirection: "column",
      width: "188px",
      height: "178px",
      margin: "10px 20px 10px 20px",
    },
    costoText: {
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      marginTop: "10px",
      padding: "0px 20px 0px 30px",
      fontSize: "17px",
      lineHeight: "20px",
      color: color,
    },
    numberPorcentaje: {
      display: "flex",
      justifyContent: "center",
      marginTop: "21px",
      fontSize: "40px",
      color: "#fff",
      fontWeight: "bold",
    },
  })
);

type Props = {
  backgroundUrl: string;
  color: string;
  percentage: number;
  text: string;
};

export const CostosCard = ({
  backgroundUrl,
  color,
  percentage,
  text,
}: Props) => {
  const { classes } = useStyles({ backgroundUrl: backgroundUrl, color: color });

  return (
    <div className={classes.costosItem}>
      <div className={classes.numberPorcentaje}>{percentage}%</div>
      <div className={classes.costoText}>{text}</div>
    </div>
  );
};
