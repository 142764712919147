import { Checkbox, Grid, Radio, Paper, Text, Button } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  GenderLabAppShell,
  GenderLabBreadcrumbs,
  RadioYesNoTable,
} from "../../components";
import { useMedicionActual } from "../../context";
import { onChangeCleanFields, notificacionReviseLosCampos } from "../../utils";
import { clientService } from "../../api/services/";
import { useAuth } from "../../context/";
import { notificacionErrorEncuesta } from "./_notificacionError";
import { encuestaOrganizacionalIsDisabled } from "./_encuestaOrganizacionalIsDisabled";

export const EncuestaPoliticas = () => {
  const { medicionActualCookie } = useMedicionActual();
  const { code } = useParams();
  const navigate = useNavigate();
  const { loading, setLoading } = useAuth();
  const disabled = encuestaOrganizacionalIsDisabled(medicionActualCookie);

  useEffect(() => {
    const getData = async () => {
      const response = await clientService.organizationalSurvey.policy.get(
        code
      );
      const data = response.data;
      for (const key in data) {
        if (data[key] === null) {
          data[key] = "";
        } else if (data[key] === true) {
          data[key] = key;
        }
      }

      const temp: string[] = [];
      if (data?.pol_003) temp.push("pol_003");
      if (data?.pol_004) temp.push("pol_004");
      if (data?.pol_005) temp.push("pol_005");
      if (data?.pol_006) temp.push("pol_006");
      if (data?.pol_007) temp.push("pol_007");
      if (data?.pol_008) temp.push("pol_008");
      if (data?.pol_009) temp.push("pol_009");
      form.setValues({
        pol_001: data?.pol_001,
        pol_002: data?.pol_002,
        pol_003_009: temp,
        pol_010: data?.pol_010,
        pol_011: data?.pol_011,
        pol_012: data?.pol_012,
      });
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const form = useForm({
    initialValues: {
      pol_001: "",
      pol_002: "",
      pol_003_009: [""],
      pol_010: "",
      pol_011: "",
      pol_012: "",
    },

    validate: {
      pol_001: (value) => !value && "Campo requerido",
      pol_002: (value) => {
        if (form.values.pol_001 === "Sí.") {
          return !value && "Campo requerido";
        } else return null;
      },
      pol_003_009: (value) => {
        if (form.values.pol_002 === "Sí.") {
          return !value || value.length === 0 || value.every((el) => el === "")
            ? "Campo requerido"
            : null;
        } else return null;
      },
      pol_010: (value) => {
        if (form.values.pol_001 === "Sí.") {
          return !value || value.length === 0 ? "Campo requerido" : null;
        } else return null;
      },
      pol_011: (value) => !value && "Campo requerido",
      pol_012: (value) => !value && "Campo requerido",
    },
  });

  const saveValues = async () => {
    if (!form.validate().hasErrors) {
      try {
        const toSubmit: any = {};
        toSubmit.pol_001 = form.values.pol_001;
        toSubmit.pol_002 = form.values.pol_002 || null;

        if (form.values.pol_002 === "Sí.") {
          const formasDeDistribucion: string[] = form.values.pol_003_009;
          toSubmit.pol_003 = formasDeDistribucion.includes("pol_003");
          toSubmit.pol_004 = formasDeDistribucion.includes("pol_004");
          toSubmit.pol_005 = formasDeDistribucion.includes("pol_005");
          toSubmit.pol_006 = formasDeDistribucion.includes("pol_006");
          toSubmit.pol_007 = formasDeDistribucion.includes("pol_007");
          toSubmit.pol_008 = formasDeDistribucion.includes("pol_008");
          toSubmit.pol_009 = formasDeDistribucion.includes("pol_009");
        } else {
          toSubmit.pol_003 = null;
          toSubmit.pol_004 = null;
          toSubmit.pol_005 = null;
          toSubmit.pol_006 = null;
          toSubmit.pol_007 = null;
          toSubmit.pol_008 = null;
          toSubmit.pol_009 = null;
        }

        toSubmit.pol_010 = form.values.pol_010 || null;
        toSubmit.pol_011 = form.values.pol_011;
        toSubmit.pol_012 = form.values.pol_012;

        setLoading(true);
        await clientService.organizationalSurvey.policy.put(code, toSubmit);
        setLoading(false);
        navigate(`/medicion/${code}`, {
          state: {
            from: "eo",
          },
        });
      } catch (error) {
        console.error(error);
        notificacionErrorEncuesta();
      }
    } else {
      notificacionReviseLosCampos();
    }
  };

  return (
    <GenderLabAppShell>
      <GenderLabBreadcrumbs
        breadcrumbs={[
          { link: "/mediciones", title: "Mediciones" },
          {
            link: "/medicion/" + medicionActualCookie?.code,
            title: medicionActualCookie?.title || "",
          },
          { link: "", title: "Políticas" },
        ]}
      />
      <Paper shadow="xs" p="md" mt="lg">
        <Text size={14}>
          Esta sección contiene preguntas relacionadas a las políticas,
          reglamentos o lineamientos que tiene la organización. Selecciona una
          respuesta o llena cada una de las casillas, según corresponda.
        </Text>
      </Paper>
      <form>
        <Grid mt="xs" gutter="md">
          <Grid.Col span={12}>
            <Radio.Group
              label="¿Tu organización tiene una política de prevención del acoso u hostigamiento sexual laboral?"
              {...form.getInputProps("pol_001")}
              onChange={(value) =>
                onChangeCleanFields(
                  value,
                  "pol_001",
                  ["pol_002", "pol_003_009", "pol_010"],
                  "No.",
                  form
                )
              }
            >
              <Radio disabled={disabled} value="Sí." label="Sí." />
              <Radio disabled={disabled} value="No." label="No." />
            </Radio.Group>
          </Grid.Col>
          {form.values.pol_001 === "Sí." && (
            <>
              <Grid.Col span={12}>
                <Radio.Group
                  label="La política de acoso u hostigamiento sexual en el trabajo, ¿ha sido distribuida al personal?"
                  {...form.getInputProps("pol_002")}
                  onChange={(value) =>
                    onChangeCleanFields(
                      value,
                      "pol_002",
                      ["pol_003_009"],
                      "No.",
                      form
                    )
                  }
                >
                  <Radio disabled={disabled} value="Sí." label="Sí." />
                  <Radio disabled={disabled} value="No." label="No." />
                </Radio.Group>
              </Grid.Col>
              {form.values.pol_002 === "Sí." && (
                <Grid.Col span={12}>
                  <Checkbox.Group
                    orientation="vertical"
                    label="¿Cómo se ha distribuido la política al personal?"
                    {...form.getInputProps("pol_003_009")}
                  >
                    <Checkbox
                      disabled={disabled}
                      value="pol_003"
                      label="Se le ha entregado en físico."
                    />
                    <Checkbox
                      disabled={disabled}
                      value="pol_004"
                      label="Se le ha enviado por correo electrónico."
                    />
                    <Checkbox
                      disabled={disabled}
                      value="pol_005"
                      label="Se ha colgado en la intranet o una plataforma web de la organización."
                    />
                    <Checkbox
                      disabled={disabled}
                      value="pol_006"
                      label="Se ha incluido la información en el proceso de inducción de personal."
                    />
                    <Checkbox
                      disabled={disabled}
                      value="pol_007"
                      label="Se le recuerda periódicamente al personal."
                    />
                    <Checkbox
                      disabled={disabled}
                      value="pol_008"
                      label="Se elaboran y distribuyen contenidos amigables para un mejor entendimiento de la política."
                    />
                    <Checkbox
                      disabled={disabled}
                      value="pol_009"
                      label="Se realizan campañas de comunicación interna."
                    />
                  </Checkbox.Group>
                </Grid.Col>
              )}
              <Grid.Col span={12}>
                <Radio.Group
                  label="En caso esté enmarcada en una normativa nacional, ¿está actualizada?"
                  {...form.getInputProps("pol_010")}
                >
                  <Radio disabled={disabled} value="Sí." label="Sí." />
                  <Radio disabled={disabled} value="No." label="No." />
                  <Radio
                    disabled={disabled}
                    value="No aplica."
                    label="No aplica."
                  />
                </Radio.Group>
              </Grid.Col>
            </>
          )}
          <Grid.Col span={12}>
            <RadioYesNoTable
              disabled={disabled}
              title="¿Tu organización tiene alguna política que incluya alguno de estos temas?"
              questions={[
                {
                  text: "Igualdad, equidad de género, diversidad e inclusión.",
                  formValue: "pol_011",
                },
                {
                  text: "Violencia de género.",
                  formValue: "pol_012",
                },
              ]}
              form={form}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Button disabled={loading || disabled} onClick={saveValues}>
              Guardar
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </GenderLabAppShell>
  );
};
