import { Breadcrumbs, createStyles } from "@mantine/core";
import { Link } from "react-router-dom";

type Props = {
  breadcrumbs: {
    link: string;
    title: string;
  }[];
};

const useStyles = createStyles((theme) => ({
  link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
    fontSize: "32px",
    fontWeight: 550,
    color: "#000000",

    "@media (max-width: 800px)": {
      fontSize: "16px",
    },
  },
  separator: {
    fontSize: "26px",
    fontWeight: 550,
    color: "#000000",

    "@media (max-width: 800px)": {
      fontSize: "16px",
    },
  },
}));

// use separator="→" to change separator but with fontsize of useStyle

export const GenderLabBreadcrumbs = ({ breadcrumbs }: Props) => {
  const { classes } = useStyles();
  return (
    <Breadcrumbs py="xl" separator={<span className={classes.separator}>/</span>}>
      {breadcrumbs.map((breadcrumb, index) => {
        return (
          <Link to={breadcrumb.link} className={classes.link} key={index}>
            {breadcrumb.title}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};
