import { Flex, Tabs, Text } from "@mantine/core";
import { useEffect, useState } from "react";

import {
  ClaseCard,
  EducacionTabs,
  GenderLabAppShell,
  GenderLabBreadcrumbs,
} from "../../../components";
import { webinarService } from "../../../api";
import {
  ActiveWebinar,
  AvailableWebinar,
  CompletedWebinar,
} from "../../../types";

export const ClasesEnVivoPage = () => {
  const [activeWebinars, setActiveWebinars] = useState<ActiveWebinar[]>(null!);
  const [availableWebinars, setAvailableWebinars] = useState<
    AvailableWebinar[]
  >(null!);
  const [pastWebinars, setPastWebinars] = useState<CompletedWebinar[]>(null!);

  useEffect(() => {
    const getWebinars = async () => {
      let response = await webinarService.getActiveWebinars();
      setActiveWebinars(response.data.data);
      response = await webinarService.getAvailableWebinars();
      setAvailableWebinars(response.data.data);
      response = await webinarService.getPastWebinars();
      setPastWebinars(response.data.data);
    };

    getWebinars();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GenderLabAppShell>
      <GenderLabBreadcrumbs
        breadcrumbs={[{ link: "", title: "Clases en vivo" }]}
      />
      <Flex direction="column" gap="md">
        <EducacionTabs defaultValue="disponibles">
          <Tabs.List>
            <Tabs.Tab value="disponibles">Disponibles</Tabs.Tab>
            <Tabs.Tab value="completados">Completados</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="disponibles" pt="md">
            <Flex direction="column" gap="lg" mt="md">
              {activeWebinars?.map((webinar, idx) => (
                <div key={idx}>
                  <ClaseCard
                    title={webinar.webinar.title}
                    year={webinar.webinar.release_date}
                    description={webinar.webinar.description}
                    img={webinar.webinar.image}
                    syllabus={webinar.webinar.syllabus}
                    sessions={webinar.sessions}
                    type="purchased"
                    duration={webinar.webinar.total_duration}
                  />
                </div>
              ))}
              {availableWebinars?.map((webinar, idx) => (
                <div key={idx}>
                  <ClaseCard
                    title={webinar.title}
                    year={webinar.release_date}
                    description={webinar.description}
                    img={webinar.image}
                    syllabus={webinar.syllabus}
                    type="available"
                    duration={webinar.total_duration}
                  />
                </div>
              ))}
            </Flex>
          </Tabs.Panel>
          <Tabs.Panel value="completados" pt="md">
            <Flex direction="column" gap="lg">
              {pastWebinars && pastWebinars.length > 0 ? (
                pastWebinars.map((webinar, idx) => (
                  <div key={idx}>
                    <ClaseCard
                      title={webinar.webinar.title}
                      year={webinar.webinar.release_date}
                      description={webinar.webinar.description}
                      img={webinar.webinar.image}
                      syllabus={webinar.webinar.syllabus}
                      type="inactive"
                      duration={webinar.webinar.total_duration}
                      material={webinar.material}
                      enrollmentId={webinar.id}
                    />
                  </div>
                ))
              ) : (
                <Text color="dimmed">
                  No cuentas con cursos en esta sección.
                </Text>
              )}
            </Flex>
          </Tabs.Panel>
        </EducacionTabs>
      </Flex>
    </GenderLabAppShell>
  );
};
