import { useRef } from "react";
import Plyr, { APITypes } from "plyr-react";
import "plyr-react/plyr.css";

type Props = {
  videoId: string;
  provider: "youtube" | "vimeo";
  videoOptions?: any;
};

export const PlyrComponent = ({ videoId, provider, videoOptions }: Props) => {
  const ref = useRef<APITypes>(null);

  const plyrVideo =
    videoId && provider ? (
      <Plyr
        ref={ref}
        source={{
          type: "video",
          sources: [
            {
              src: videoId,
              provider: provider,
            },
          ],
        }}
        options={videoOptions}
      />
    ) : null;

  return <div>{plyrVideo}</div>;
};
